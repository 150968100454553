/* eslint-disable no-else-return */
/* eslint-disable no-unreachable */
/* eslint-disable func-names */
// Modules
const { object, string, number, array, addMethod, boolean } = require("yup");
const moment = require("moment");
const { supabase } = require("../../../supabase");

// Helpers
const formatPhoneNumber = require("../../shared/functions/formatPhoneNumber");
const getBankDetails = require("../../shared/functions/getBankDetails");
const verifyIdNumber = require("../../shared/functions/verifyIdNumber");
const getProvinceDetails = require("../../shared/functions/getProvinceDetails");

// Constants
const { countryCodes, provinces, banks } = require("../../shared/constants");

const API = require("../../../api");

function formatUnixDateForPostgres(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp === "") {
    return null; // return null if the unixTimestamp is null or undefined
  }

  const date = moment.unix(unixTimestamp);
  if (!date.isValid()) {
    throw new Error(`Invalid Unix timestamp: ${unixTimestamp}`);
  }

  return date.format("YYYY-MM-DD");
}

addMethod(string, "requiredIf", function (bool, message) {
  if (!bool) {
    return this.nullable();
  }

  return this.required(message || "Required");
});

addMethod(number, "requiredIf", function (bool, message) {
  if (!bool) {
    return this.nullable();
  }

  return this.required(message || "Required");
});

addMethod(string, "foundInObject", function (objArray, message) {
  return this.test("isfoundInObject", function (value) {
    const { path, createError } = this;

    // Check if the provided objArray is a valid non-empty array
    if (!Array.isArray(objArray) || objArray.length === 0) {
      return createError({
        path,
        message: `Invalid array provided to test "foundInObject" for ${path}`,
      });
    }

    // Check if the value exists in the objArray
    const found = objArray.some(obj => obj.value === value);

    if (value && !found) {
      return createError({
        path,
        message: message || `Invalid ${path}`,
      });
    }

    return true;
  });
});

addMethod(string, "foundInArray", function (arr, message) {
  return this.test("isFoundInArray", function (value) {
    const { path, createError } = this;

    if (!arr || !Array.isArray(arr) || !arr.length) {
      createError({
        path,
        message: `Invalid array provided to test "foundInArray" for ${path}`,
      });
    }

    if (value) {
      const index = arr.findIndex(v => v === value);

      if (index === -1) {
        return createError({
          path,
          message: message || `Invalid ${path}`,
        });
      }
    }

    return true;
  });
});

addMethod(array, "foundInArray", function (arr, message) {
  return this.test("isFoundInArray", function (value) {
    const { path, createError } = this;

    if (!arr || !Array.isArray(arr) || !arr.length) {
      createError({
        path,
        message: `Invalid array provided to test "foundInArray" for ${path}`,
      });
    }
    if (!value || !Array.isArray(value) || !value.length) {
      createError({
        path,
        message: message || `Invalid ${path} provided`,
      });
    }

    const match = value.every(v => arr.includes(v));

    if (!match) {
      return createError({
        path,
        message: message || `Invalid ${path}`,
      });
    }

    return true;
  });
});

addMethod(string, "name", function (validMessage, shortMessage) {
  return this.min(2, shortMessage || validMessage || "Too short").matches(
    /^[a-z ,.'-]+$/i,
    validMessage || "Invalid"
  );
});

addMethod(string, "phone", function (message) {
  return this.matches(/^\+27[0-9]{9}$/g, message || "Invalid");
});

// id types
const ID = "RSAID";
const PASSPORT = "PASSPT";
// genders
const MALE = "M";
const FEMALE = "F";
// payment options
const BANK = "bank";
const E_WALLET = "eWallet";
// bank types
const CHEQUE = "1";
const SAVINGS = "2";
// bank
const BRANCH_CODES = banks.map(b => b.branchCode);
const BANK_NAMES = banks.map(b => b.value);
// address
const PROVINCE_ARRAY = provinces.map(p => p.value);

const isString = val => typeof val === "string";

const generatePassword = () => {
  const length = 8;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const createDealerFunction = async (dealerData, editing) => {
  const provinceDetails = getProvinceDetails(String(dealerData.province));

  const bankDetails = getBankDetails(
    dealerData.bank
      ? String(dealerData.bank)
      : dealerData.branchCode
      ? String(dealerData.branchCode)
      : null
  );

  const data = {
    city: String(dealerData.city),
    contact_id_no: String(dealerData.contactIdNo),
    contact_name: String(dealerData.contactName),
    contact_surname: String(dealerData.contactSurname),
    country: String(dealerData.country),
    dealer_company_name: String(dealerData.dealerName),
    email: dealerData.email,
    gender: String(dealerData.gender),
    payment_type: String(dealerData.paymentType),
    bank_account_type: dealerData.bankAccountType
      ? String(dealerData.bankAccountType)
      : null,
    branch_code: bankDetails.branchCode,
    e_wallet_link: String(dealerData.eWalletLink),
    bank_account_number: dealerData.bankAccountNumber
      ? String(dealerData.bankAccountNumber)
      : null,
    bank: bankDetails.value,
    id_photo: String(dealerData.idPhoto),
    id_type: String(dealerData.idType),
    nationality: String(dealerData.nationality),
    operator_id: dealerData.operatorId ? String(dealerData.operatorId) : null,
    date_of_birth: dealerData.dateOfBirth
      ? Number(dealerData.dateOfBirth)
      : dealerData.dateOfBirth,
    parent_dealer:
      dealerData.parentDealer && typeof dealerData.parentDealer === "object"
        ? dealerData.parentDealer.id
        : dealerData.parentDealer,
    passport_expiry_date: dealerData.passportExpiryDate
      ? Number(dealerData.passportExpiryDate)
        ? Number(dealerData.passportExpiryDate)
        : dealerData.passportExpiryDate
      : null,
    passport_issue_date: dealerData.passportIssueDate
      ? Number(dealerData.passportIssueDate)
        ? Number(dealerData.passportIssueDate)
        : dealerData.passportIssueDate
      : null,
    person_photo: String(dealerData.personPhoto),
    phone:
      dealerData.phone && isString(dealerData.phone) && dealerData.phone.length
        ? formatPhoneNumber(String(dealerData.phone))
        : dealerData.phone,
    alt_phone:
      dealerData.altPhone &&
      isString(dealerData.altPhone) &&
      dealerData.altPhone.length
        ? formatPhoneNumber(String(dealerData.altPhone))
        : null,
    postal_code: String(dealerData.postalCode),
    province: provinceDetails.value,
    rank: Number(dealerData.rank) ? Number(dealerData.rank) : dealerData.rank,
    selected_product_types: dealerData.selectedProductTypes,
    selected_suppliers: dealerData.selectedSuppliers,
    street: String(dealerData.street),
    suburb: String(dealerData.suburb),
    submitted_by: dealerData.submittedBy || {
      uid: dealerData.submitterId,
      name: dealerData.submitterId,
    }, // REMOVE EVENTUALLY
    agreed_to_rica_terms: dealerData.TandCs,
    firebase_uid: !editing ? generatePassword() : dealerData.firebase_uid, // Remove once migrated.
    temp_pw: !editing ? generatePassword() : dealerData.temp_pw,
    status: !editing ? 1 : dealerData.status,
  };

  if (data.idType === ID) {
    verifyIdNumber(data.contactIdNo, data.dateOfBirth, data.gender).then(
      result => {
        if (!result.valid) {
          return {
            status: "error",
            message: result.message,
          };
        }
      }
    );
  }

  const paymentIsBank = data.paymentType === BANK;
  const passportIsIdType = data.idType === PASSPORT;

  const dealerSchema = object({
    city: string().min(2, "Invalid city (2+ letters)").required(),
    contact_id_no: string().required(),
    contact_name: string().name("Invalid name (2+ letters only)").required(),
    contact_surname: string()
      .name("Invalid Surname (2+ letters only)")
      .required(),
    country: string().foundInObject(countryCodes, "Invalid Country").required(),
    dealer_company_name: string().nullable(),
    email: string().email(),
    gender: string().foundInArray([MALE, FEMALE], "Invalid gender").required(),
    payment_type: string()
      .foundInArray([BANK, E_WALLET], "Invalid payment type")
      .required(),
    bank_account_type: string()
      .foundInArray([CHEQUE, SAVINGS], "Invalid bank account type")
      .requiredIf(paymentIsBank, "Please select a bank account type"),
    branch_code: string()
      .foundInArray(BRANCH_CODES, "Invalid branch code")
      .requiredIf(paymentIsBank, "Bank branch code required"),
    bank_account_number: string()
      .matches(/^[0-9]+$/g, "Invalid Bank Account Number")
      .requiredIf(paymentIsBank, "Please add a bank account number"),
    bank: string()
      .foundInArray(BANK_NAMES, "Invalid bank")
      .requiredIf(paymentIsBank, "Please select a bank"),
    id_photo: string().url("Invalid ID Photo").required(),
    id_type: string()
      .foundInArray([ID, PASSPORT], "Invalid ID type")
      .required(),
    nationality: string()
      .foundInObject(countryCodes, "Invalid nationality")
      .required(),
    operator_id: string().nullable(),
    date_of_birth: number().required("Date of birth is required"),
    parent_dealer: string().min(2, "Parent dealer id is required").required(),
    passport_expiry_date: number().requiredIf(
      passportIsIdType,
      "Passport expiry date required"
    ),
    passport_issue_date: number().requiredIf(
      passportIsIdType,
      "Passport issue date required"
    ),
    person_photo: string().url("Invalid person photo").required(),
    phone: string()
      .phone("Invalid phone number")
      .required("Phone number is required"),
    postal_code: string()
      .matches(/^[0-9]{4}$/g, "Invalid Postal Code")
      .required(),
    province: string().foundInArray(PROVINCE_ARRAY).required(),
    rank: number().min(1).max(8).required(),
    selected_product_types: array()
      .min(1)
      .required("Please select at least one product type for the user."),
    selected_suppliers: array()
      .min(1)
      .required("Please select at least one supplier for the user."),
    street: string().min(2, "Street too short").required(),
    suburb: string().name("Invalid suburb (2+ letters)").required(),
    submitted_by: object().nullable(),
    agreed_to_rica_terms: boolean().required(true),
  });

  return new Promise((resolve, reject) => {
    dealerSchema
      .validate(data)
      .then(() => {
        // Format dates to postgres. Needs to be changed in the form at some point (TODO).
        data.date_of_birth = formatUnixDateForPostgres(data.date_of_birth);
        data.passport_expiry_date = formatUnixDateForPostgres(
          data.passport_expiry_date
        );
        data.passport_issue_date = formatUnixDateForPostgres(
          data.passport_issue_date
        );

        if (editing) {
          supabase
            .schema(process.env.REACT_APP_SB_SCHEMA)
            .from("dealers")
            .update(data)
            .eq("id", dealerData.id)
            .then(response => {
              if (response.error) {
                reject(response.error);
              } else {
                resolve(response);
              }
            })
            .catch(error => {
              reject(error);
            });
        } else {
          // Create the user
          // Can't use hooks in this file.
          API.createUser({
            email: data.email,
            phone: data.phone,
            password: data.temp_pw,
          })
            .then(result => {
              if (result.data.code !== 200) {
                reject({
                  message: result.data.message,
                });
              } else {
                data.auth_id = result.data.data.user.id;
                supabase
                  .schema(process.env.REACT_APP_SB_SCHEMA)
                  .from("dealers")
                  .insert(data)
                  .then(response => {
                    if (response.error) {
                      reject(response.error);
                    } else {
                      resolve(response);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              }
            })
            .catch(err => {
              reject({
                message: err.message,
              });
            });
        }
      })
      .catch(err3 => {
        reject({
          status: "error",
          message: err3.message,
        });
      });
  });
};

module.exports = createDealerFunction;
