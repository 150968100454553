import axios from "axios";

const url = "https://zawadi-staging-a60377845df5.herokuapp.com/";

// const url = "http://127.0.0.1:4000/";

const apiInstance = axios.create({
  baseURL: url,
  timeout: 60000,
});

const getDownstreamWithParams = (params = {}) => {
  return apiInstance.get("/get-downstream-with-params", {
    params: {
      ...params,
    },
  });
};

const getNewDealerCode = (params = {}) => {
  return apiInstance.get("/get-new-dealer-code", {
    params: {
      ...params,
    },
  });
};

const createUser = (params = {}) => {
  return apiInstance.get("/create-new-user", {
    params: {
      ...params,
    },
  });
};

const updateDealerPassword = (params = {}) => {
  return apiInstance.get("/update-dealer-password", {
    params: {
      ...params,
    },
  });
};

const getAgentReport = (params = {}) => {
  return apiInstance.get("/get-agent-report", {
    params: {
      ...params,
    },
  });
};

const getAllAlerts = (params = {}) => {
  return apiInstance.get("/getAlerts", {
    params: {
      ...params,
    },
  });
};

export {
  getDownstreamWithParams,
  createUser,
  getNewDealerCode,
  updateDealerPassword,
  getAgentReport,
  getAllAlerts,
};
