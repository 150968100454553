import { faUsers, faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { pageTitleStyles } from "../../mui/styles";

export default function Settings() {
  const listItemStyles = {
    icon: {
      backgroundColor: "#ddd",
      height: 50,
      width: 50,
      borderRadius: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
    },
  };
  const history = useHistory();

  return (
    <div>
      <Button
        startIcon={<ArrowBack />}
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>Settings</h1>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Dealers
        </Typography>
        <Paper>
          <List>
            <Link to="/settings/levels">
              <ListItem
                style={{
                  cursor: "pointer",
                }}
              >
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faUsers} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#000" }}
                  primary="Levels"
                  secondary=""
                />
              </ListItem>
            </Link>
          </List>
        </Paper>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Suppliers & Services
        </Typography>
        <Paper>
          <List>
            <Link to="/settings/product-types">
              <ListItem
                style={{
                  cursor: "pointer",
                  borderBottom: "solid",
                  borderBottomColor: "#666666",
                  borderBottomWidth: 0.5,
                }}
              >
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faClone} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#000" }}
                  primary="Product Types"
                  secondary=""
                />
              </ListItem>
            </Link>
            <Link to="/settings/suppliers">
              <ListItem
                style={{
                  cursor: "pointer",
                  borderBottom: "solid",
                  borderBottomColor: "#666666",
                  borderBottomWidth: 0.5,
                }}
              >
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faClone} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#000" }}
                  primary="Suppliers"
                  secondary=""
                />
              </ListItem>
            </Link>
          </List>
        </Paper>
      </Grid>
    </div>
  );
}
