import React, { useContext } from "react";
import { supabase } from "../supabase";

const SupabaseContext = React.createContext();

export function useSB() {
  return useContext(SupabaseContext);
}

export default function SupabaseProvider({ children }) {
  function GetWholeTable(table, orderBy) {
    return new Promise((resolve, reject) => {
      supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select()
        .order(orderBy)
        .then(data => {
          if (data.error) {
            reject(data.error);
          } else {
            resolve(data.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async function GetTableWhere(table, ...conditions) {
    try {
      const query = supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select();

      if (conditions) {
        conditions.forEach(([column, operator, value]) => {
          switch (operator) {
            case "eq": // Equal to
              query.eq(column, value);
              break;
            case "gt": // Greater than
              query.gt(column, value);
              break;
            case "lt": // Less than
              query.lt(column, value);
              break;
            case "gte": // Greater than or equal to
              query.gte(column, value);
              break;
            case "lte": // Less than or equal to
              query.lte(column, value);
              break;
            case "like": // LIKE operator for case-sensitive comparison
              query.like(column, value);
              break;
            case "ilike": // ILIKE operator for case-insensitive comparison
              query.ilike(column, value);
              break;
            case "is": // IS NULL operator
              query.is(column, value);
              break;
            case "in": // IN operator
              query.in(column, value);
              break;
            case "neq": // Not equal to
              query.neq(column, value);
              break;
            case "contains": // Array contains
              query.contains(column, value);
              break;
            case "containedBy": // Array contained by
              query.containedBy(column, value);
              break;
            default:
              throw new Error(`Unsupported operator: ${operator}`);
          }
        });
      }
      const { data, error } = await query;
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      console.error(`Error fetching ${table}:`, error);
    }
  }

  async function insertRow(table, data) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .insert(data)
      .select();
  }

  async function updateRow(table, rowId, updateData) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .update(updateData)
      .eq("id", rowId);
  }

  async function deleteRow(table, rowId) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .delete()
      .eq("id", rowId);
  }
  async function getRowById(table, rowId) {
    return (
      await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select()
        .eq("id", rowId)
    ).data[0];
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values

  // eslint-disable-next-line
  const value = {
    GetTableWhere,
    GetWholeTable,
    updateRow,
    insertRow,
    deleteRow,
    getRowById,
  };

  return (
    <SupabaseContext.Provider value={value}>
      {children}
    </SupabaseContext.Provider>
  );
}
