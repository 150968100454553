import React, { useState } from "react";

import { Box, Button, Typography, Input, Grid } from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { supabase } from "../../supabase";

import useSupabaseFileUpload from "../../hooks/useSupabaseFileUpload";

function SBFileUploader({
  bucketName,
  folderName = "",
  label = "Upload File",
  onClose = undefined,
  withSubmit = () => {},
  info = () => {},
  singleFile = false,
}) {
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [fileCount, setFileCount] = useState(0);

  const handleFileChange = event => {
    setUploadError(null);
    setUploadedData(null);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFilePath(selectedFile ? `${folderName}/${selectedFile.name}` : "");
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadError("Please select a file to upload.");
      return;
    }

    setUploading(true);
    const result = await useSupabaseFileUpload(filePath, file, bucketName);
    if (result.error) {
      setUploadError(
        result.error.message || "An error occurred during upload."
      );
    } else {
      setUploadedData(result);
      setFileCount(fileCount + 1);
      const { data } = supabase.storage
        .from(bucketName)
        .getPublicUrl(`${folderName}/${file.name}`);
      info(file, data.publicUrl);
    }

    setUploading(false);
    setFile(null);
    setFilePath(null);
    withSubmit();
    if (singleFile && onClose) onClose();
  };

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h5">{label}</Typography>
        </Grid>
        {fileCount !== 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">{`Files uploaded: ${fileCount}`}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Input type="file" onChange={handleFileChange} />
        </Grid>
        <Grid item xs={12}>
          {uploadError && <Alert severity="error">Error: {uploadError}</Alert>}
          {uploadedData && <Alert>File uploaded successfully</Alert>}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleUpload}
            disabled={uploading}
            variant="contained"
            color="primary"
            fullWidth
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </Grid>
        {onClose && (
          <Grid item xs={12}>
            <Button
              onClick={onClose}
              disabled={uploading}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {fileCount > 0 ? "Done" : "Cancel"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
export default SBFileUploader;
