import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSB } from "../../contexts/SupabaseContext";

export default function SelectSuppliers(props) {
  const {
    products,
    suppliers,
    productTypes,
    dealerState,
    onChangeProductTypes,
    onChangeSuppliers,
    editing,
    currentUser,
  } = props;
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const { GetTableWhere } = useSB();

  // filteredProductTypes is the product types that are available to the dealer based on their parent.
  const [filteredProductTypes, setFilteredProductTypes] =
    useState(productTypes);

  const getDealerDetails = dealerId => {
    return new Promise(resolve => {
      if (dealerId.length > 10) {
        GetTableWhere("dealers", ["id", "eq", dealerId]).then(data => {
          resolve(data[0]);
        });
      } else {
        GetTableWhere("dealers", ["dealer_code", "eq", dealerId]).then(data => {
          resolve(data[0]);
        });
      }
    });
  };

  const handleProductTypeChange = event => {
    const { value } = event.target;
    // eslint-disable-next-line no-confusing-arrow
    setSelectedProductTypes(prevSelected =>
      prevSelected?.includes(value)
        ? prevSelected?.filter(item => item !== value)
        : [...prevSelected, value]
    );
  };

  useEffect(() => {
    if (!dealerState.parentDealer) {
      const filteredProductTypesForDealer = productTypes.filter(type =>
        currentUser.selected_product_types?.includes(type.id)
      );

      setFilteredProductTypes(filteredProductTypesForDealer);
      return;
    }

    if (typeof dealerState.parentDealer === "object") {
      if (dealerState.parentDealer.rank === 1) {
        setFilteredProductTypes(productTypes);
        return;
      }

      const filteredProductsObject = [];

      dealerState.parentDealer.value?.selected_product_types?.forEach(type => {
        filteredProductsObject.push(
          productTypes.find(productType => productType.id === type)
        );
      });

      setFilteredProductTypes(filteredProductsObject);
    } else {
      getDealerDetails(dealerState.parentDealer).then(dealerResult => {
        if (dealerResult?.rank === 1) {
          setFilteredProductTypes(productTypes);
        } else {
          const filteredProductTypesForDealer = productTypes.filter(type =>
            dealerResult?.selected_product_types.includes(type.id)
          );

          setFilteredProductTypes(filteredProductTypesForDealer || []);
        }
      });
    }
  }, [dealerState.parentDealer]);

  const handleSupplierToggle = supplier => () => {
    const currentIndex = selectedSuppliers.indexOf(supplier.id);
    const newSelectedSuppliers = [...selectedSuppliers];

    if (currentIndex === -1) {
      newSelectedSuppliers.push(supplier.id);
    } else {
      newSelectedSuppliers.splice(currentIndex, 1);
    }

    setSelectedSuppliers(newSelectedSuppliers);
  };

  useEffect(() => {
    if (editing) {
      setSelectedProductTypes(dealerState.selectedProductTypes);
      setSelectedSuppliers(dealerState.selectedSuppliers);
    }
  }, []);

  // This will trigger the change in the main dealer object.

  useEffect(() => {
    onChangeProductTypes(selectedProductTypes);
    onChangeSuppliers(selectedSuppliers);
  }, [selectedProductTypes, selectedSuppliers]);

  useEffect(() => {
    setUpSupplierList();
  }, [selectedProductTypes, dealerState.parentDealer]);

  const setUpSupplierList = () => {
    // Get the suppliers for the product:

    // find the products that have any of the selectedProductTypes as the  productType

    const filteredProducts = products.filter(product =>
      selectedProductTypes?.includes(product.product_type)
    );

    const supplierNumbers = filteredProducts.map(
      product => product.supplier_id
    );

    // get suppliers that have a supplier number that is in the supplierNumbers array
    const filteredSuppliers = suppliers.filter(supplier =>
      supplierNumbers.includes(supplier.id)
    );

    if (!dealerState.parentDealer) {
      const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
        currentUser.selected_suppliers?.includes(supplier.id)
      );

      setAvailableSuppliers(filteredSuppliersForDealer);
      return;
    }

    if (typeof dealerState.parentDealer === "object") {
      const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
        dealerState.parentDealer.value.selected_suppliers?.includes(supplier.id)
      );

      setAvailableSuppliers(filteredSuppliersForDealer);
    } else {
      getDealerDetails(dealerState.parentDealer).then(dealerResult => {
        if (dealerResult?.rank === 1) {
          setAvailableSuppliers(filteredSuppliers);
          return;
        }

        const filteredSuppliersForDealer = filteredSuppliers.filter(supplier =>
          dealerResult?.selected_suppliers.includes(supplier.id)
        );

        setAvailableSuppliers(filteredSuppliersForDealer);
      });
    }
  };

  return (
    <div>
      <div style={styles.inputContainer}>
        <Typography>Select Product Types:</Typography>
        <br />
        {filteredProductTypes.length === 0 && (
          <div>
            {!dealerState.parentDealer ? (
              <Typography>
                Please select a product type before selecting a supplier.
              </Typography>
            ) : (
              <Typography>
                Parent Dealer does not have any product types selected.
              </Typography>
            )}
          </div>
        )}
        {filteredProductTypes.map(type => (
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <FormControlLabel
              key={type.id}
              control={
                <Checkbox
                  checked={selectedProductTypes?.includes(type.id)}
                  onChange={handleProductTypeChange}
                  value={type.id}
                />
              }
              label={type.name}
            />
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <Typography>Select Suppliers:</Typography>
        <br />
        {availableSuppliers.length === 0 && (
          <Typography>
            Please select a product type before selecting a supplier.
          </Typography>
        )}
        {availableSuppliers &&
          availableSuppliers.map(supplier => (
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <FormControlLabel
                key={supplier.id}
                control={
                  <Checkbox
                    checked={selectedSuppliers.indexOf(supplier.id) !== -1}
                    onChange={handleSupplierToggle(supplier)}
                    color="primary"
                  />
                }
                label={supplier.supplier}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

const styles = {
  inputContainer: {
    marginTop: 30,
  },
};
