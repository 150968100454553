/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
import React, { useEffect, useState } from "react";
import BackLink from "../shared/BackLink";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "firebase/database";
import {
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  DialogActions,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../contexts/AuthContext";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSB } from "../../contexts/SupabaseContext";

function TrainingContentViewer() {
  const { GetWholeTable, GetTableWhere } = useSB();
  const [loadingPage, setLoadingPage] = useState(true);
  const { currentUser } = useAuth();
  const [trainingModules, setTrainingModules] = useState([]);
  const [displayModules, setDisplayModules] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [showAttempts, setShowAttempts] = useState(false);
  const [passedFilter, setPassedFilter] = useState({
    label: "All",
    value: "All",
  });

  // Sort modules by category, placing items with a category before those without
  const sortedArr = displayModules.sort((a, b) => {
    if (a.moduleCategory && b.moduleCategory) {
      return a.moduleCategory.localeCompare(b.moduleCategory);
    }
    if (a.moduleCategory) {
      return -1; // Place items with a category before those without
    }
    if (b.moduleCategory) {
      return 1; // Place items with a category after those without
    }
    return 0; // Leave the order unchanged if both items lack a category
  });

  useEffect(() => {
    const { rank, selected_product_types, selected_suppliers } = currentUser;

    // Fetch all training modules
    GetWholeTable("training_modules", "module_name")
      .then(data => {
        const modules = [];

        data.forEach(doc => {
          if (rank === 1) {
            // Admin can see all modules
            const moduleInstance = doc;
            moduleInstance.id = doc.id;

            modules.push(moduleInstance);
          } else {
            // Regular users see only relevant modules
            const { product_types, suppliers, selected_levels, active } = doc;

            if (
              active &&
              (selected_levels?.includes(rank) ||
                selected_levels === undefined ||
                selected_levels.length === 0)
            ) {
              if (
                product_types === undefined ||
                product_types.length === 0 ||
                selected_product_types?.some(type =>
                  product_types.includes(type)
                )
              ) {
                if (
                  suppliers === undefined ||
                  suppliers.length === 0 ||
                  selected_suppliers?.some(supplier =>
                    suppliers?.includes(supplier)
                  )
                ) {
                  const moduleInstance = doc;
                  moduleInstance.id = doc.id;

                  modules.push(moduleInstance);
                }
              }
            }
          }
        });

        modules.sort((a, b) => {
          if (a.module_category && b.module_category) {
            return a.module_category.localeCompare(b.module_category);
          }
          if (a.module_category) {
            return -1; // Place items with a category before those without
          }
          if (b.module_category) {
            return 1; // Place items with a category after those without
          }
          return 0; // Leave the order unchanged if both items lack a category
        });

        if (modules.length === 0) {
          setTrainingModules([]);
          setLoadingPage(false);
        } else {
          const attempt_list = [];
          // Fetch training attempts for the current user
          GetTableWhere("training_attempts", [
            "dealer_id",
            "eq",
            currentUser.id,
          ])
            .then(docs => {
              if (docs.length === 0) {
                setTrainingModules(modules);
                setLoadingPage(false);
              } else {
                docs.forEach((doc, index) => {
                  attempt_list.push(doc);
                  if (index === docs.length - 1) {
                    modules.forEach((moduleInstance, index2) => {
                      // Filter attempts relevant to the current module
                      const attemptsForThisModule = attempt_list.filter(
                        attempt => attempt.module_id === moduleInstance.id
                      );
                      const moduleAttempts = attemptsForThisModule.length;
                      let modulePassed = false;
                      let moduleBestAttempt = 0;
                      if (moduleAttempts > 0) {
                        modulePassed = attemptsForThisModule.filter(
                          docLocal => docLocal.pass === true
                        ).length;
                        moduleBestAttempt = attemptsForThisModule.reduce(
                          // Get the best attempt based on percentage
                          (prev, current) =>
                            prev.percentage > current.percentage
                              ? prev
                              : current
                        );
                      }
                      // Add attempt details to the module instance
                      moduleInstance.attempts = moduleAttempts;
                      moduleInstance.passed = modulePassed;
                      moduleInstance.best_attempt = moduleBestAttempt
                        ? moduleBestAttempt.percentage
                        : 0;
                      moduleInstance.attempt_list = attemptsForThisModule;
                      modules[index2] = moduleInstance;
                      if (index2 === modules.length - 1) {
                        setTrainingModules(modules);
                        setLoadingPage(false);
                      }
                    });
                  }
                });
              }
            })
            .catch(error => {
              console.error("Error getting documents: ", error);
            });
        }
      })
      .catch(error => {
        console.error("Error getting documents: ", error);
      });
  }, []);

  useEffect(() => {
    // Update display modules when training modules change
    setDisplayModules(trainingModules);
  }, [trainingModules]);

  useEffect(() => {
    // Filter display modules based on pass filter value
    if (passedFilter.value !== "All") {
      const tempModules = trainingModules.filter(trainingModule => {
        const passed = () => {
          if (trainingModule.passed === false || trainingModule.passed === 0) {
            return false;
          }
          return true;
        };
        return passed() === passedFilter.value;
      });

      setDisplayModules(tempModules);
    } else {
      setDisplayModules(trainingModules);
    }
  }, [passedFilter]);

  return (
    <div>
      <BackLink />
      {loadingPage ? (
        <h1>Loading....</h1>
      ) : (
        <div style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            {trainingModules.length === 0 ? (
              <Grid item xs={12} sm={7}>
                <h3>You have no modules assigned to you.</h3>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={8} alignContent="center">
                  <h1>Zawadi University:</h1>
                </Grid>
                <Grid item xs={12} md={4} alignContent="center">
                  <Select
                    fullWidth
                    placeholder="Status"
                    name="passedFilter"
                    options={[
                      { label: "All", value: "All" },
                      { label: "Passed", value: true },
                      { label: "Not Passed", value: false },
                    ]}
                    defaultValue={passedFilter}
                    onChange={e => {
                      setPassedFilter(e);
                    }}
                  />
                </Grid>
              </>
            )}
            {sortedArr.map(moduleItem => {
              return (
                <Grid item xs={12} sm={6} key={moduleItem.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {moduleItem.module_name}
                      </Typography>
                      <Typography variant="overline" color="text.secondary">
                        {moduleItem.module_category}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {moduleItem.module_description}
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={9} md={10}>
                          <div style={{ marginBottom: 10 }}>
                            Attempts: {moduleItem.attempts || 0}
                          </div>
                          {moduleItem.attempts > 0 && (
                            <>
                              <div style={{ marginBottom: 10 }}>
                                Result:{" "}
                                {moduleItem.passed ? "Passed" : "Not Passed"}
                              </div>
                              <div style={{ marginBottom: 10 }}>
                                Needed to pass: {moduleItem.pass_threshold}%
                              </div>
                              <div style={{ marginBottom: 10 }}>
                                Current Best Attempt:{" "}
                                {parseFloat(moduleItem.best_attempt).toFixed(2)}
                                %
                              </div>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          md={2}
                          alignContent="flex-end"
                          //  alignItems="flex-end"
                        >
                          {moduleItem.passed ? (
                            <FontAwesomeIcon
                              icon={faCertificate}
                              size="4x"
                              color="#FEBA0F"
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Link to={`/training-attempt/${moduleItem.id}`}>
                        <Button variant="contained" color="primary">
                          Attempt
                        </Button>
                      </Link>
                      {moduleItem.attempts > 0 && (
                        <Button
                          onClick={() => {
                            setAttempts(moduleItem.attempt_list);
                            setShowAttempts(true);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          View Attempts
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      <Dialog
        open={showAttempts}
        width="md"
        fullWidth
        onClose={() => {
          setAttempts([]);
          setShowAttempts(false);
        }}
      >
        {attempts[0] ? (
          <>
            <DialogTitle>
              Attempts For {attempts[0]?.module_content_snapshot.module_name}
            </DialogTitle>
            <DialogContent>
              <List>
                {attempts.map(item => {
                  return (
                    <ListItem key={item.uid}>
                      <ListItemText
                        style={{ color: "#000" }}
                        primary={`${
                          item.pass ? "Passed" : "Not Passed"
                        } on ${moment(item.created_at).format("YYYY-MM-DD")}`}
                        secondary={`Attempt Mark: ${item.percentage}%`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContent>
          </>
        ) : (
          <DialogTitle>No Attempts for this module.</DialogTitle>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setAttempts([]);
              setShowAttempts(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TrainingContentViewer;
