// #useAuthUpdated

import React, { forwardRef } from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import _ from "lodash";
import Notification from "./Notification";

function DealerNotifications({ showAlertFunction, allAlerts, closeMenu }) {
  const notifications = [];

  let allNotifications = _.orderBy(
    notifications.concat(allAlerts),
    "timestamp",
    "desc"
  );

  let unreadNotifications = [];

  if (allAlerts) {
    unreadNotifications = allNotifications.filter(n => n.read === false);
  }

  if (allNotifications[0] === undefined) {
    allNotifications = [];
  }

  return (
    <Card style={{ minWidth: 300 }} elevation={0}>
      <CardContent>
        <div style={{ marginBottom: 20 }}>
          <Typography style={{ fontWeight: "bold" }}>Alerts</Typography>
        </div>
        <div style={{ marginBottom: 20 }}>Unread Messages:</div>

        <Divider />
        {
          // Map through notifications
          unreadNotifications &&
            // eslint-disable-next-line array-callback-return
            unreadNotifications.map(n => {
              return (
                <Notification
                  viewAlert={() => {
                    showAlertFunction(n);
                  }}
                  notif={n}
                />
              );
            })
        }
        <div style={{ marginBottom: 20, marginTop: 20 }}>All Messages:</div>
        <Divider />
        <br />
        {
          // Map through notifications
          allNotifications &&
            allNotifications.map(n => (
              <Notification
                viewAlert={() => {
                  showAlertFunction(n);
                  closeMenu();
                }}
                notif={n}
                closeMenu={closeMenu}
              />
            ))
        }
      </CardContent>
    </Card>
  );
}

export default forwardRef(DealerNotifications);
