import React from "react";
import { Container, Paper } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCog,
  faMoneyBill,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function AdminSection() {
  const listItemStyles = {
    icon: {
      backgroundColor: "#ddd",
      height: 50,
      width: 50,
      borderRadius: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
    },
  };
  return (
    <Container>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Imports
        </Typography>
        <Paper>
          <Link to="/import-fibre-activity">
            <ListItem
              style={{
                cursor: "pointer",
                borderBottom: "solid",
                borderBottomColor: "#666666",
                borderBottomWidth: 0.5,
              }}
            >
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faUpload} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Import Fibre Activity"
                secondary="Agent Sales, Order Progress, etc."
              />
            </ListItem>
          </Link>
        </Paper>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Settings
        </Typography>
        <Paper>
          <Link to="/settings">
            <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faCog} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Organisation Settings"
                secondary="Configure Dealer, Rica and Services settings"
              />
            </ListItem>
          </Link>
        </Paper>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Agent Actions
        </Typography>
        <Paper>
          <Link to="/order-override">
            <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Manual Order Override"
                secondary="Capture the correct Agent to be associated with an order"
              />
            </ListItem>
          </Link>
          <Link to="/edit-sales-note">
            <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Edit Sales Note"
                secondary="Change the captured sales note for an order"
              />
            </ListItem>
          </Link>{" "}
          <Link to="/sales-leads-admin-view">
            <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Sales Assist"
                secondary="Admin view for the sales assist"
              />
            </ListItem>
          </Link>
        </Paper>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Zawadi University
        </Typography>
        <Paper>
          <Link to="/training-modules">
            <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
              <ListItemIcon style={listItemStyles.icon}>
                <FontAwesomeIcon icon={faGraduationCap} color="#666666" />
              </ListItemIcon>
              <ListItemText
                style={{ color: "#000" }}
                primary="Training Modules"
                secondary="Add, edit and remove training modules"
              />
            </ListItem>
          </Link>
        </Paper>
      </Grid>
    </Container>
  );
}

export default AdminSection;
