// #useAuthUpdated

import {
  Button,
  Grid,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { pageTitleStyles } from "../../../../mui/styles";
import MobileLeaderBoard from "./MobileLeaderBoard";
import OverallDesk from "./OverallDesk";
import StructureDesk from "./StructureDesk";
import { useAuth } from "../../../../contexts/AuthContext";
import { useCompany } from "../../../../contexts/CompanyContext";
import { useSB } from "../../../../contexts/SupabaseContext";
import { supabase } from "../../../../supabase";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#F0BD44",
  },
  card1: {
    backgroundColor: "#267353",
    color: "#FFFFFF",
  },

  TextColor: {
    color: "#FFFFFF",
  },

  button1: {
    color: "#267353",
  },
  button2: {
    backgroundColor: "#FFFFFF",
  },
  buttonSize: {
    width: 120,
  },
  buttonSize2: {
    width: 120,
  },
  selected: {
    backgroundColor: "#267353",
    color: "white",
    width: 120,
  },
  notSelected: {
    color: "white",
    width: 120,
  },
});

export default function LeaderBoard() {
  const [selectedButton, setSelectedButton] = useState("Overall");
  const { GetTableWhere } = useSB();
  const { suppliers, productTypes } = useCompany();
  const [Leaders, setLeaders] = useState([]);
  const [StructureData, setStructureData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const classes = useStyles();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYM =
    `${currentYear}-${currentMonth}`.length === 9
      ? `${currentYear}-${currentMonth}`
      : `${currentYear}-0${currentMonth}`;
  const [selectedSuppliers, setSelectedSuppliers] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");
  const [noDataForTheMonth, setNoDataForTheMonth] = useState(false);
  const [downstream, setDownstream] = useState([{}]);
  const [leaderboardDate, setLeaderboardDate] = useState(currentYM);
  const start_d = `${leaderboardDate}-01`;
  const end_d = moment(`${leaderboardDate}-01`)
    .endOf("month")
    .format("YYYY-MM-DD");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetTableWhere("dealer_downstream", [
          "dealer_id",
          "eq",
          currentUser.id,
        ]);
        setDownstream(data[0].downstream_uids);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [selectedOption, leaderboardDate, selectedSuppliers]);

  useEffect(async () => {
    setLoading(true);
    if (leaderboardDate === currentYM) {
      // Ethan,for some reason this still gets called even if there is data,
    } else if (noDataForTheMonth) {
      setNoDataForTheMonth(false);
    }
    if (selectedSuppliers === "all") {
      const data = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("leaderboard_v2")
        .select(
          `
        *,
        dealers(contact_name, contact_surname),
        suppliers(supplier)
        `
        )
        .gte("start_d", start_d)
        .lte("start_d", end_d)
        .order("sales", { ascending: false });

      const mergedData = Array.isArray(data.data)
        ? data.data
            .filter(item => item.dealer_id !== null) // Exclude items where dealer_id is null
            .reduce((result, current) => {
              const existing = result.find(
                item => item.dealer_id === current.dealer_id
              );
              if (existing) {
                existing.sales = (existing.sales || 0) + (current.sales || 0);
              } else {
                result.push(current);
              }
              return result;
            }, [])
        : []; // Return an empty array if data.data is not an array

      // Sort the mergedData array by Sales in descending order
      mergedData.sort((a, b) => b.sales - a.sales);

      // Update the rank based on the sorted order
      const updateRank = mergedData.map((item, index) => ({
        ...item,
        rank: index,
      }));

      const updatePercentage = updateRank.map(Leader => {
        const newLeader = { ...Leader };
        newLeader.p = ((newLeader.sales / updateRank[0].sales) * 100).toFixed(
          2
        );
        return newLeader;
      });

      setLeaders(updatePercentage);
      setLoading(false);
    } else {
      const { data, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("leaderboard_v2")
        .select(
          `
        *,
        dealers(contact_name, contact_surname),
        suppliers(supplier)
        `
        )
        .gte("start_d", start_d)
        .lte("start_d", end_d)
        .eq("supplier", selectedSuppliers)
        .order("sales", { ascending: false });

      if (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        return;
      }

      // Filter out items where dealer_id is null
      const leaderData = data.filter(item => item.dealer_id !== null);
      leaderData.sort((a, b) => b.sales - a.sales);

      // Update the rank based on the sorted order
      const updateRank = leaderData.map((item, index) => ({
        ...item,
        rank: index,
      }));

      const updatePercentage = updateRank.map(Leader => {
        const newLeader = { ...Leader };
        newLeader.p = ((newLeader.sales / updateRank[0].sales) * 100).toFixed(
          2
        );
        return newLeader;
      });
      setLeaders(updatePercentage);
      setLoading(false);
    }
  }, [leaderboardDate, selectedSuppliers]);

  useEffect(async () => {
    const filteredLeaders = Leaders.filter(leader =>
      downstream.toString().includes(leader.dealer_id)
    );
    setStructureData(filteredLeaders);
    setLoading(false);
  }, [downstream, Leaders, selectedSuppliers]);

  const handleDateButtons = id => {
    setLoading(true);
    const LeaderboardYear = leaderboardDate.slice(0, 4);
    const LeaderboardMonth = leaderboardDate.slice(5, 7);

    if (id === ">" && LeaderboardMonth !== "12") {
      setLeaderboardDate(
        `${LeaderboardYear}-${Number(LeaderboardMonth) + 1 < 10 ? "0" : ""}${
          Number(LeaderboardMonth) + 1
        }`
      );
      setLoading(false);
    } else if (id === ">" && LeaderboardMonth === "12") {
      setLeaderboardDate(`${Number(LeaderboardYear) + 1}-01`);
      setLoading(false);
    }

    if (id === "<" && LeaderboardMonth !== "01") {
      setLeaderboardDate(
        `${LeaderboardYear}-${Number(LeaderboardMonth) - 1 < 10 ? "0" : ""}${
          Number(LeaderboardMonth) - 1
        }`
      );
      setLoading(false);
    } else if (id === "<" && LeaderboardMonth === "01") {
      setLeaderboardDate(`${Number(LeaderboardYear) - 1}-12`);
      setLoading(false);
    }
  };

  const handleChangeSuppliers = event => {
    setSelectedSuppliers(event.target.value); // Update the selected option when the user makes a choice
  };

  const handleChange = event => {
    setSelectedOption(event.target.value); // Update the selected option when the user makes a choice
  };

  const handleClick = id => {
    setSelectedButton(id);
  };
  return (
    <div>
      {!loading ? (
        selectedButton === "Overall" ? (
          <>
            <h1 style={pageTitleStyles}>
              <Grid container direction="row">
                <Grid item style={{ textAlign: "left", flex: 1 }}>
                  Leaderboard
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid style={{ paddingRight: 10 }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: 160, marginTop: 5, marginRight: 10 }}
                        size="small"
                      >
                        <InputLabel
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Select a Supplier
                        </InputLabel>
                        <Select
                          label="Select a Supplier"
                          value={selectedSuppliers}
                          onChange={handleChangeSuppliers}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          <MenuItem value="all">All Suppliers</MenuItem>
                          {suppliers.map(supplier => {
                            return supplier.supplier_status === "Active" ? (
                              <MenuItem value={supplier.id} key={supplier.id}>
                                {supplier.supplier}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        disabled
                        variant="outlined"
                        size="small"
                        style={{ width: 160, marginTop: 5 }}
                      >
                        <InputLabel style={{ fontSize: "12px" }}>
                          Select a Product Type
                        </InputLabel>
                        <Select
                          label="Select a Product Type"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          <MenuItem value="">All</MenuItem>
                          {productTypes.map(productType => {
                            return (
                              <MenuItem
                                value={productType.id}
                                key={productType.id}
                              >
                                {productType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSize2}
                        onClick={() => handleDateButtons("<")}
                        style={{ marginRight: 10 }}
                      >
                        PREV
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSize2}
                        onClick={() => handleDateButtons(">")}
                        style={{ marginRight: 10 }}
                        disabled={
                          leaderboardDate === currentYM || noDataForTheMonth
                        }
                      >
                        NEXT
                      </Button>
                    </Grid>
                    {currentUser.rank !== 8 ? (
                      <Hidden only={["xs"]}>
                        <Grid item style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonSize2}
                            onClick={() => handleClick("Overall")}
                            style={{ marginRight: 10 }}
                          >
                            Overall
                          </Button>

                          {StructureData !== undefined ? (
                            <Button
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                            >
                              Structure
                            </Button>
                          ) : (
                            <Button
                              disabled
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                            >
                              Structure
                            </Button>
                          )}
                        </Grid>
                      </Hidden>
                    ) : (
                      <Hidden only={["xs"]}>
                        <Grid md={7} lg={9} />
                        <Grid item xs={2} lg={1} />
                        <Grid item xs={1} />
                      </Hidden>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </h1>
            <Alert severity="info" style={{ marginBottom: "20px" }}>
              {Leaders.length > 0
                ? //     ? leaderboardDate === currentYM
                  `Updated Until: 
              ${
                Leaders[0]?.end_d?.length > 10
                  ? Leaders[0].end_d.slice(0, 10)
                  : `${!loading ? "No Data" : "Loading..."}`
              }`
                : //     : `Leader board for: ${leaderboardDate}`
                  "The Leaderboard has not been updated for this month yet."}
            </Alert>
            {Leaders.length === 0 ? (
              <h2>
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </h2>
            ) : (
              <div>
                {Leaders.length > 0 ? (
                  <>
                    <Hidden only={["xs"]}>
                      <OverallDesk Leaders={Leaders} />
                    </Hidden>
                    <Hidden only={["xl", "lg", "md", "sm"]}>
                      <MobileLeaderBoard
                        Leaders={Leaders}
                        StructureData={StructureData}
                      />
                    </Hidden>
                  </>
                ) : (
                  <h1>
                    The Leaderboard has not been updated for this month yet.
                  </h1>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <h1 style={pageTitleStyles}>
              <Grid container direction="row" justify="space-between">
                <Grid item style={{ textAlign: "left" }}>
                  LeaderBoard
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid style={{ paddingRight: 10 }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: 160, marginTop: 5, marginRight: 10 }}
                        size="small"
                      >
                        <InputLabel
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Select a Supplier
                        </InputLabel>
                        <Select
                          label="Select a Supplier"
                          value={selectedSuppliers}
                          onChange={handleChangeSuppliers}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          <MenuItem value="all">All Suppliers</MenuItem>
                          {suppliers.map(supplier => {
                            return supplier.supplier_status === "Active" ? (
                              <MenuItem
                                value={supplier.id}
                                key={supplier.internal_identifier}
                              >
                                {supplier.supplier}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        disabled
                        variant="outlined"
                        size="small"
                        style={{ width: 160, marginTop: 5 }}
                      >
                        <InputLabel style={{ fontSize: "12px" }}>
                          Select a Product Type
                        </InputLabel>
                        <Select
                          label="Select a Product Type"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          <MenuItem value="">All</MenuItem>
                          {productTypes.map(productType => {
                            return (
                              <MenuItem
                                value={productType.id}
                                key={productType.id}
                              >
                                {productType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSize2}
                        onClick={() => handleDateButtons("<")}
                        style={{ marginRight: 10 }}
                      >
                        PREV
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSize2}
                        onClick={() => handleDateButtons(">")}
                        style={{ marginRight: 10 }}
                        disabled={leaderboardDate === currentYM}
                      >
                        NEXT
                      </Button>
                    </Grid>
                    <Hidden only={["xs"]}>
                      <Grid itme style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Overall")}
                          style={{ marginRight: 10 }}
                        >
                          Overall
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#F0BD10", color: "white" }}
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Structure")}
                        >
                          Structure
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </h1>
            <Alert severity="info" style={{ marginBottom: "20px" }}>
              {Leaders.length > 0
                ? //    ? leaderboardDate === currentYM
                  `Updated Until: 
              ${
                Leaders[0]?.end_d?.length > 10
                  ? Leaders[0].end_d.slice(0, 10)
                  : `${!loading ? "No Data" : "Loading..."}`
              }`
                : //   : `Leader board for: ${leaderboardDate}`
                  "The Leaderboard has not been updated for this month yet."}
            </Alert>
            {Leaders.length === 0 ? (
              <h2>
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </h2>
            ) : (
              <div>
                <Hidden only={["xs"]}>
                  <StructureDesk Leaders={StructureData} />
                </Hidden>
                <Hidden only={["xl", "lg", "md", "sm"]}>
                  <MobileLeaderBoard
                    Leaders={Leaders}
                    StructureData={StructureData}
                  />
                </Hidden>
              </div>
            )}
          </>
        )
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}
