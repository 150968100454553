import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import { supabase } from "../../../supabase";
import { useCompany } from "../../../contexts/CompanyContext";
import { useAuth } from "../../../contexts/AuthContext";
import { pageTitleStyles } from "../../../mui/styles";
import {
  salesLeadCategories,
  salesLeadStatuses,
  salesLeadPaymentTypes,
  salesLeadInProgressStatuses,
  salesLeadClosedStatuses,
} from "../../shared/constants";

import ConfirmationModal from "../../shared/ConfirmationModal";

function EditLeadModal({ leadId, onClose }) {
  // Default form values
  const defFormValues = {
    supplier: "",
    status: "",
    category: "",
    ref_number: "",
    customer_name: "",
    address: "",
    phone_number: "",
    alternative_number: "",
    note: "",
    gps_location: "",
    email_address: "",
    id_number: "",
    payment_type: "",
    last_updated_by_code: "",
    last_updated_by_name: "",
    last_updated_at: "",
    sales_lead_in_progress_status: "",
    sales_lead_closed_status: "",
    history: [],
  };

  // Custom hooks
  const { activeSuppliers } = useCompany();
  const { currentUser } = useAuth();

  // Hooks for managing state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [formValues, setFormValues] = useState(defFormValues);
  const [confirmModal, setConfirmModal] = useState(false);

  // Event handlers
  const handleChangeSuppliers = event => {
    setDisable(false);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      supplier: event.target.value,
    }));
  };

  // Other event handlers (handleCategorySelect, handleStatusSelect, etc.)
  const handleCategorySelect = event =>
    setFormValues(prev => ({ ...prev, category: event.target.value }));

  const handleStatusSelect = event =>
    setFormValues(prev => ({ ...prev, status: event.target.value }));

  const handleInProgressStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      sales_lead_in_progress_status: event.target.value,
    }));

  const handleClosedStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      sales_lead_closed_status: event.target.value,
    }));

  const handlePaymentTypeSelect = event =>
    setFormValues(prev => ({ ...prev, payment_type: event.target.value }));

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;

    if (name === "agreedToTerms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreedToTerms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  const handleEditLead = async () => {
    if (formValues.supplier !== "all") {
      try {
        setDisable(true);
        const updatedFormValues = {
          ...formValues,

          // Adding the history for the sales lead
          history: [
            ...formValues.history,
            {
              updated_at: moment().format("YYYY/MM/DD HH:mm:ss"),
              update_address: formValues.address,
              updated_by: currentUser.id,
              updated_by_code: currentUser.dealer_code,
              updated_by_code_name: `${currentUser.dealer_code}: ${currentUser.contact_name}`,
              update_status: formValues.status,
              update_in_progress_status:
                formValues.sales_lead_in_progress_status,
              update_closed_status: formValues.sales_lead_closed_status,
              update_feedback: formValues.note,
            },
          ],
        };

        const { error } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("sales_assist")
          .update(updatedFormValues)
          .eq("id", formValues.id)
          .select();

        if (error) console.error(error.message);
        onClose();
        setFormValues(defFormValues);
        setDisable(false);
      } catch (err) {
        setErrorMessage(err);
        setIsSnackbarOpen(true);
      }
    } else {
      setErrorMessage("All is not a Supplier please select one!");
      setIsSnackbarOpen(true);
      setDisable(true);
    }
  };

  const handleDeleteLead = async () => {
    try {
      setDisable(true);
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("sales_assist")
        .update({ is_active: false })
        .eq("id", formValues.id)
        .select();

      if (error) console.error(error.message);
      setFormValues(defFormValues);
      onClose();
    } catch (err) {
      setErrorMessage(err);
      setIsSnackbarOpen(true);
    }
  };

  // Fetch call to get data
  const getData = async () => {
    setLoading(true);

    const { data: salesAssistData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .select("*")
      .eq("id", leadId);

    if (error) console.error(error);

    const result = await salesAssistData[0];

    const updatedFormValues = {
      ...formValues,
      ...(await result),
      last_updated_by_code: currentUser.dealer_code,
      last_updated_by_name: currentUser.contact_name,
      last_updated_at: moment().format("YYYY-MM-DD"),
      history: result.history || [], // Initialize history as an empty array if it's undefined
    };
    setFormValues(updatedFormValues);

    setLoading(false);
  };

  // useEffect to fetch data
  useEffect(() => {
    getData(leadId);
  }, []);

  // Return JSX elements
  return (
    <div>
      <Typography variant="h5" style={pageTitleStyles}>
        Edit Lead
      </Typography>
      <Grid container xs={12} spacing={2}>
        {loading ? (
          <Grid item>
            <Typography>Loading...</Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ height: 60 }}
                required
              >
                <InputLabel
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Select a Supplier
                </InputLabel>
                <Select
                  label="Select a Supplier"
                  value={formValues.supplier}
                  onChange={handleChangeSuppliers}
                  style={{
                    height: 60,
                    marginTop: -2,
                    fontSize: "12px",
                  }}
                >
                  {activeSuppliers.map(supplier => {
                    return (
                      <MenuItem value={supplier.id} key={supplier.id}>
                        {supplier.supplier}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.category}
                onChange={handleCategorySelect}
                id="outlined-required"
                label="Category"
                variant="outlined"
                fullWidth
              >
                {salesLeadCategories[
                  salesLeadCategories[formValues.supplier]
                    ? formValues.supplier
                    : "def"
                ].map(category => (
                  <MenuItem key={category.key} value={category.value}>
                    {category.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.status}
                onChange={handleStatusSelect}
                id="outlined-required"
                label="Status"
                variant="outlined"
                fullWidth
              >
                {salesLeadStatuses.map(status => (
                  <MenuItem key={status.key} value={status.value}>
                    {status.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formValues.status === "In Progress" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.sales_lead_in_progress_status}
                  onChange={handleInProgressStatusSelect}
                  id="outlined-required"
                  label="In Progress Status"
                  variant="outlined"
                  fullWidth
                >
                  {salesLeadInProgressStatuses.map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {formValues.status === "Closed" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.sales_lead_closed_status}
                  onChange={handleClosedStatusSelect}
                  id="outlined-required"
                  label="Closed Status"
                  variant="outlined"
                  fullWidth
                >
                  {salesLeadClosedStatuses.map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Refference number"
                variant="outlined"
                required
                name="ref_number"
                value={formValues.ref_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Customer Name"
                variant="outlined"
                required
                name="customer_name"
                value={formValues.customer_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                required
                name="address"
                value={formValues.address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <TextField
                label="Phone number"
                variant="outlined"
                required
                name="phone_number"
                value={formValues.phone_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Alternative number"
                variant="outlined"
                required
                name="alternative_number"
                value={formValues.alternative_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Email Address"
                variant="outlined"
                name="email_address"
                value={formValues.email_address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Customer ID Number"
                variant="outlined"
                name="id_number"
                value={formValues.id_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            {salesLeadPaymentTypes[formValues.supplier] && (
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  value={formValues.payment_type}
                  onChange={handlePaymentTypeSelect}
                  id="outlined-required"
                  label="Payment Type"
                  variant="outlined"
                  helperText="Select the Payment type"
                  fullWidth
                >
                  {formValues.supplier &&
                    salesLeadPaymentTypes[formValues.supplier].map(type => (
                      <MenuItem key={type.key} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="GPS Location"
                variant="outlined"
                required
                name="gps_location"
                value={formValues.gps_location}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Feedback"
                variant="outlined"
                required
                name="note"
                value={formValues.note}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleEditLead}
                disabled={disable || loading}
              >
                Submit
              </Button>{" "}
            </Grid>{" "}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#c70000", color: "ButtonFace" }}
                onClick={() => setConfirmModal(true)}
                disabled={disable || loading}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
              >
                <Alert
                  severity="error"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </>
        )}
      </Grid>

      <ConfirmationModal
        onConfirm={handleDeleteLead}
        onClose={() => setConfirmModal(false)}
        open={confirmModal}
      />
    </div>
  );
}

export default EditLeadModal;
