export default function sqlToStandardDate(sqldate, showTime = false) {
  if (sqldate) {
    const originalDate = new Date(sqldate);
    const formattedDate = originalDate.toISOString().split("T");

    if (showTime) {
      const regex = /[0-9]{2}:[0-9]{2}:[0-9]{2}/i;

      if (formattedDate[1] && regex.exec(formattedDate[1])) {
        return `${formattedDate[0]} ${regex.exec(formattedDate[1])}`;
      }
    }

    return formattedDate[0];
  }
}
