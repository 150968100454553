import React from "react";

import { Grid, Modal, Backdrop, Fade, Paper, Button } from "@material-ui/core";

export function ModalCard({
  open = true,
  onClose = () => {},
  children,
  closeButtonText = undefined,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "90%",
            maxWidth: "70rem",
          }}
        >
          <Paper
            style={{
              padding: 30,
              paddingTop: 50,
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            {children}
            <br />
            {closeButtonText && (
              <Button
                required
                color="secondary"
                variant="contained"
                fullWidth
                onClick={onClose}
              >
                {closeButtonText}
              </Button>
            )}
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  );
}

export default ModalCard;
