// #useAuthUpdated

import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";
// import ReactPivot from "react-pivot";
import { useAuth } from "../../../../contexts/AuthContext";
import PeriodSelector from "../../../global/PeriodSelector";
import { getAgentStructureReport } from "./functions/getAgentSctructureReport";
import exportToCsv from "../../../shared/functions/generateCsv";

export default function StructureReport(props) {
  const { currentUser } = useAuth();
  const [dealerCode, setDealerCode] = useState(currentUser.dealerCode);
  const { supplierPrettyName, impersonation, impersonationName } = props;

  useEffect(() => {
    // Update dealer code based on impersonation or current user
    if (impersonation) {
      setDealerCode(impersonation);
    } else {
      setDealerCode(currentUser.dealer_code);
    }
  }, [impersonation]);

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [showLoading, setShowloading] = useState(false);
  const [data, setData] = useState([{}]);
  const [tree, setTree] = useState([]);
  const [createdOrders, setCreatedOrders] = useState(0);
  const [paidOrders, setPaidOrders] = useState(0);
  const [installedOrders, setInstalledOrders] = useState(0);
  const [activatedOrders, setActivatedOrders] = useState(0);

  const { id: supplierId } = useParams();

  useEffect(() => {
    // Fetch data when dealer code, start date, or end date changes
    if (dealerCode && supplierId) {
      getData();
    }
  }, [dealerCode, startDate, endDate]);

  /**
   * Fetches the data for the Structure Report.
   * @returns {Promise<void>} A promise that resolves when the data is fetched.
   */
  const getData = async () => {
    setShowloading(true);
    const structureReport = await getAgentStructureReport(
      dealerCode,
      supplierId,
      startDate,
      endDate
    );
    setData(structureReport);
    // Filter out items with null Agent_Code_And_Name
    const updatedStructureReport = structureReport.filter(
      item => item.Agent_Code_And_Name !== null
    );

    // sum all created orders
    const totalCreatedOrders = updatedStructureReport.reduce(
      (acc, item) => acc + item.created_orders,
      0
    );
    setCreatedOrders(totalCreatedOrders);

    // sum all paid orders
    const totalPaidOrders = updatedStructureReport.reduce(
      (acc, item) => acc + item.paid_orders,
      0
    );
    setPaidOrders(totalPaidOrders);

    // sum all installed orders
    const totalInstalledOrders = updatedStructureReport.reduce(
      (acc, item) => acc + item.installed_orders,
      0
    );
    setInstalledOrders(totalInstalledOrders);

    // sum all activated orders
    const totalActivatedOrders = updatedStructureReport.reduce(
      (acc, item) => acc + item.activated_orders,
      0
    );
    setActivatedOrders(totalActivatedOrders);
    // Create a nested tree structure from the data
    const tree2 = updatedStructureReport.reduce((acc, item) => {
      if (!acc[item.dealer]) {
        acc[item.dealer] = {};
      }
      if (!acc[item.dealer][item.tier_1_tl]) {
        acc[item.dealer][item.tier_1_tl] = {};
      }
      if (!acc[item.dealer][item.tier_1_tl][item.assistant_tl]) {
        acc[item.dealer][item.tier_1_tl][item.assistant_tl] = [];
      }

      let objAgent = {
        agent: item.Agent_Code_And_Name,
        createdOrders: item.created_orders,
        paidOrders: item.paid_orders,
        installedOrders: item.installed_orders,
        activatedOrders: item.activated_orders,
      };
      acc[item.dealer][item.tier_1_tl][item.assistant_tl].push(objAgent);
      objAgent = {};
      return acc;
    }, {});

    // Filter and update the tree structure
    const filteredTree = Object.entries(tree2).reduce(
      (acc2, [dealer, tier1]) => {
        const updatedTier1 = {};
        Object.entries(tier1).forEach(([tier1TL, assistantTLs]) => {
          const updatedAssistantTLs = {};
          Object.entries(assistantTLs).forEach(([assistantTL, agents]) => {
            const updatedAgents = agents.reduce((agentAcc, agent) => {
              const existingAgent = agentAcc.find(a => a.agent === agent.agent);
              if (existingAgent) {
                existingAgent.createdOrders += agent.createdOrders;
                existingAgent.paidOrders += agent.paidOrders;
                existingAgent.installedOrders += agent.installedOrders;
                existingAgent.activatedOrders += agent.activatedOrders;
              } else {
                agentAcc.push({ ...agent });
              }
              return agentAcc;
            }, []);
            updatedAssistantTLs[assistantTL] = updatedAgents;
          });
          updatedTier1[tier1TL] = updatedAssistantTLs;
        });
        // eslint-disable-next-line no-param-reassign
        acc2[dealer] = updatedTier1;
        return acc2;
      },
      {}
    );
    // Update the tree state with the filtered tree, change this to setTree(tree2) to see the unfiltered tree
    setTree(filteredTree);
    setShowloading(false);
  };

  return (
    <div>
      <Typography variant="h6">
        Structure Report for{" "}
        {impersonationName ? `: ${impersonationName}` : `: ${dealerCode} (You)`}
      </Typography>
      <Typography variant="h6">Service: {supplierPrettyName}</Typography>
      <div>
        <p>
          Current Period:{" "}
          <b>
            {startDate} - {endDate}
          </b>
        </p>
      </div>
      <PeriodSelector
        changeStartDate={date => {
          setStartDate(date);
        }}
        changeEndDate={date => {
          setEndDate(date);
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: 1300,
          }}
        >
          {!showLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {/* Render the order statistics */}
                  {[
                    {
                      label: "Orders created",
                      value: createdOrders,
                    },
                    {
                      label: "Orders Paid",
                      value: paidOrders,
                    },
                  ].map(item => (
                    <div
                      style={{
                        marginRight: "3%",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                      key={item.label}
                    >
                      <p>{item.label}</p>
                      <p style={{ fontSize: "40px", marginTop: 0 }}>
                        {item.value}
                      </p>
                    </div>
                  ))}
                  {[
                    {
                      label: "Orders Installed",
                      value: installedOrders,
                    },
                    {
                      label: "Orders Activated",
                      value: activatedOrders,
                    },
                  ].map(item => (
                    <div
                      style={{ marginRight: "3%", textAlign: "center" }}
                      key={item.label}
                    >
                      <p>{item.label}</p>
                      <p style={{ fontSize: "40px", marginTop: 0 }}>
                        {item.value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Dealer</th>
                    <th style={{ textAlign: "left" }}>Tier 1</th>
                    <th style={{ textAlign: "left" }}>Assistant TL</th>
                    <th style={{ textAlign: "left" }}>Agent code and name</th>
                    <th style={{ textAlign: "left" }}>Created Orders</th>
                    <th style={{ textAlign: "left" }}>Paid Orders</th>
                    <th style={{ textAlign: "left" }}>Installed Orders</th>
                    <th style={{ textAlign: "left" }}>Activated Orders</th>
                  </tr>
                </thead>
                <tbody>
                  {/* display of structure, maps through dealer. */}
                  {Object.entries(tree).map(([dealer, tier1]) => (
                    <React.Fragment key={dealer}>
                      {/* Maps through tier 1 TL */}
                      {Object.entries(tier1).map(([tier1TL, assistantTL]) => (
                        <React.Fragment key={tier1TL}>
                          {/* maps through assistant TL */}
                          {Object.entries(assistantTL).map(
                            ([assistant, agents]) => (
                              <React.Fragment key={assistant}>
                                {/* maps all the agents.  */}
                                {agents.map((agent, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <tr key={index}>
                                    <td
                                      style={{
                                        borderBottom:
                                          index === 0 ? "1px solid grey" : "",
                                      }}
                                    >
                                      {index === 0
                                        ? dealer === "null"
                                          ? " "
                                          : dealer
                                        : " "}{" "}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom:
                                          index === 0 ? "1px solid grey" : "",
                                      }}
                                    >
                                      {index === 0 ? tier1TL : " "}
                                    </td>
                                    <td
                                      style={{
                                        borderBottom:
                                          index === 0 ? "1px solid grey" : "",
                                      }}
                                    >
                                      {index === 0 ? assistant : null}
                                    </td>
                                    <td
                                      style={{ borderBottom: "1px solid grey" }}
                                    >
                                      {agent.agent}
                                    </td>
                                    <td
                                      style={{ borderBottom: "1px solid grey" }}
                                    >
                                      {agent.createdOrders}
                                    </td>
                                    <td
                                      style={{ borderBottom: "1px solid grey" }}
                                    >
                                      {agent.paidOrders}
                                    </td>
                                    <td
                                      style={{ borderBottom: "1px solid grey" }}
                                    >
                                      {agent.installedOrders}
                                    </td>
                                    <td
                                      style={{ borderBottom: "1px solid grey" }}
                                    >
                                      {agent.activatedOrders}
                                    </td>
                                  </tr>
                                ))}

                                <tr>
                                  <td>{null}</td>
                                  <td>{null}</td>
                                  <td>{null}</td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    Total
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    {agents.reduce(
                                      (acc, agent) => acc + agent.createdOrders,
                                      0
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    {agents.reduce(
                                      (acc, agent) => acc + agent.paidOrders,
                                      0
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    {agents.reduce(
                                      (acc, agent) =>
                                        acc + agent.installedOrders,
                                      0
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    {agents.reduce(
                                      (acc, agent) =>
                                        acc + agent.activatedOrders,
                                      0
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={
                  () =>
                    exportToCsv(
                      data,
                      `Structure Report ${startDate}-${endDate}`
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                Download Report
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
                fontSize: "24px", // Add this line to enlarge the loading text
              }}
            >
              Loading...
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
