// #useAuthUpdated

import React, { useState, useEffect } from "react";
import SupplierList from "./dash-components/SupplierList";
import { pageTitleStyles } from "../../../mui/styles";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import { useCompany } from "../../../contexts/CompanyContext";

export default function SelectDash() {
  const [loading, setLoading] = useState(true);
  const { suppliers } = useCompany();

  useEffect(() => {
    if (suppliers) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [suppliers]);

  return (
    <div>
      <h1 style={pageTitleStyles}>Choose a dashboard</h1>
      <div>{loading ? null : <SupplierList />}</div>
      <LeaderBoard />
    </div>
  );
}
