// #useAuthUpdated

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatabaseProvider from "../contexts/DatabaseContext";
import { useAuth } from "../contexts/AuthContext";
import PublicRoutes from "./routes/PublicRoutes";
import { companies } from "../mui/themes";
import DealerMenu from "./DealerMenu";
import { CompanyProvider } from "../contexts/CompanyContext";
import { StructureProvider } from "../contexts/StructureContext";
import SupabaseProvider from "../contexts/SupabaseContext";
import PendingNotice from "./authenticate/PendingNotice";
import MainMenu from "./MainMenu";

export default function Wrapper() {
  const { currentUser, userLevel } = useAuth();
  const company = companies.zawadi;

  return (
    <BrowserRouter>
      <ThemeProvider theme={company.theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {currentUser && currentUser.id ? (
            <SupabaseProvider>
              {currentUser.status !== 2 ? (
                <PendingNotice status={currentUser.status} />
              ) : (
                <DatabaseProvider companyId={currentUser.companyId}>
                  <CompanyProvider>
                    <StructureProvider>
                      {userLevel === 1 ? (
                        <MainMenu />
                      ) : (
                        <DealerMenu logo={company.logo} />
                      )}
                    </StructureProvider>
                  </CompanyProvider>
                </DatabaseProvider>
              )}
            </SupabaseProvider>
          ) : (
            <PublicRoutes logo={company.logo} />
          )}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
