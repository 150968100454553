import {
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import MobileUserCard from "./MobileUserCard";
import { useAuth } from "../../../../contexts/AuthContext";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#F0BD44",
  },
  card1: {
    backgroundColor: "#267353",
    color: "#FFFFFF",
  },
  TextColor: {
    color: "#FFFFFF",
  },
  button1: {
    color: "#267353",
  },
  button2: {
    backgroundColor: "#FFFFFF",
  },
  buttonSize: {
    width: 120,
  },
  buttonSize2: {
    width: 120,
  },
  selected: {
    backgroundColor: "#267353",
    color: "white",
    width: 120,
  },
  notSelected: {
    color: "white",
    width: 120,
  },
});

export default function MobileLeaderBoard({ Leaders, StructureData }) {
  const [selectedButton, setSelectedButton] = useState("Overall");
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [structureD, setStructureD] = useState();
  const classes = useStyles();

  const handleClick = id => {
    setSelectedButton(id);
  };

  useEffect(() => {
    if (Leaders !== undefined) {
      setLoading(false);
      setData(Leaders.sort((a, b) => b.id - a.id));
    }
  }, [Leaders]);

  useEffect(() => {
    if (StructureData !== undefined) {
      setLoading(false);
      setStructureD(StructureData.sort((a, b) => b.sales - a.sales));
    }
  }, [StructureData]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const cu = currentUser.dealer_code;

  return (
    <div>
      {loading || !data.length > 0 ? (
        <h1>loading...</h1>
      ) : (
        <Hidden only={["xl", "lg", "md", "sm"]}>
          {selectedButton === "Overall" ? (
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonSize}
                        onClick={() => handleClick("Overall")}
                      >
                        Overall
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center">
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.buttonSize}
                        onClick={() => handleClick("Structure")}
                      >
                        Structure
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <MobileUserCard
                          userImg="https://www.svgrepo.com/show/404692/1st-place-medal.svg"
                          name={`${
                            data[0]?.dealers?.contact_name ??
                            `${!loading ? "No Data" : "Loading..."}`
                          } ${data[0]?.dealers?.contact_surname ?? ""}`}
                          percentage={data[0]?.p ?? "0"}
                          fiber={data[0]?.sales ?? "0"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MobileUserCard
                          rank={2}
                          userImg="https://www.svgrepo.com/show/404693/2nd-place-medal.svg"
                          name={`${
                            data[1]?.dealers?.contact_name ??
                            `${!loading ? "No Data" : "Loading..."}`
                          } ${data[1]?.dealers?.contact_surname ?? ""}`}
                          percentage={data[1]?.p ?? "0"}
                          fiber={data[1]?.sales ?? "0"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MobileUserCard
                          rank={3}
                          userImg="https://www.svgrepo.com/show/404695/3rd-place-medal.svg"
                          name={`${
                            data[2]?.dealers?.contact_name ??
                            `${!loading ? "No Data" : "Loading..."}`
                          } ${data[2]?.dealers?.contact_surname ?? ""}`}
                          percentage={data[2]?.p ?? "0"}
                          fiber={data[2]?.sales ?? "0"}
                        />
                      </Grid>
                      {data.slice(3, 10).map((Leader, index) => (
                        <Grid item xs={12}>
                          <MobileUserCard
                            rank={index + 4}
                            name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                            percentage={Leader.p}
                            fiber={Leader.sales}
                            outlined={
                              Leader.dealer_code === cu ? "solid" : "none"
                            }
                            outlineColors="#267353"
                          />
                        </Grid>
                      ))}
                      {data.length > 10 && (
                        <Button
                          fullWidth
                          onClick={handleExpand}
                          style={{ paddingTop: 8, paddingBottom: 8 }}
                        >
                          <Grid item xs={12}>
                            <Card style={{ padding: 15, margin: -3 }}>
                              <Typography
                                align="center"
                                variant="h3"
                                color="primary"
                              >
                                Expand
                                {!expanded ? (
                                  <KeyboardArrowDown
                                    fontSize="inherit"
                                    style={{ marginBottom: -7.5 }}
                                  />
                                ) : (
                                  <KeyboardArrowUp
                                    fontSize="inherit"
                                    style={{ marginBottom: -7.5 }}
                                  />
                                )}
                              </Typography>
                            </Card>
                          </Grid>
                        </Button>
                      )}
                      {expanded &&
                        data.slice(10, 100).map((Leader, index) => (
                          <Grid item xs={12}>
                            <MobileUserCard
                              rank={index + 11}
                              name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                              percentage={Leader.p}
                              fiber={Leader.sales}
                              outlined={
                                Leader.dealer_code === cu ? "solid" : "none"
                              }
                              outlineColors="#267353"
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.card1}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center">
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.buttonSize}
                        onClick={() => handleClick("Overall")}
                      >
                        Overall
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#F0BD10", color: "white" }}
                        className={classes.buttonSize}
                        onClick={() => handleClick("Structure")}
                      >
                        Structure
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {structureD[0] && (
                        <Grid item xs={12}>
                          <MobileUserCard
                            userImg="https://www.svgrepo.com/show/404692/1st-place-medal.svg"
                            name={`${
                              structureD[0]?.dealers?.contact_name ??
                              `${!loading ? "No Data" : "Loading..."}`
                            } ${structureD[0]?.dealers?.contact_surname ?? ""}`}
                            percentage={structureD[0]?.p ?? "0"}
                            fiber={structureD[0]?.sales ?? "0"}
                          />
                        </Grid>
                      )}
                      {structureD[1] && (
                        <Grid item xs={12}>
                          <MobileUserCard
                            rank={2}
                            userImg="https://www.svgrepo.com/show/404693/2nd-place-medal.svg"
                            name={`${
                              structureD[1]?.dealers?.contact_name ??
                              `${!loading ? "No Data" : "Loading..."}`
                            } ${structureD[1]?.dealers?.contact_surname ?? ""}`}
                            percentage={structureD[1]?.p ?? "0"}
                            fiber={structureD[1]?.sales ?? "0"}
                          />
                        </Grid>
                      )}
                      {structureD[2] && (
                        <Grid item xs={12}>
                          <MobileUserCard
                            rank={3}
                            userImg="https://www.svgrepo.com/show/404695/3rd-place-medal.svg"
                            name={`${
                              structureD[2]?.dealers?.contact_name ??
                              `${!loading ? "No Data" : "Loading..."}`
                            } ${structureD[2]?.dealers?.contact_surname ?? ""}`}
                            percentage={structureD[2]?.p ?? "0"}
                            fiber={structureD[2]?.sales ?? "0"}
                          />
                        </Grid>
                      )}
                      {!structureD[0] && !structureD[1] && !structureD[2] && (
                        <Typography align="center" variant="h5">
                          Kindly revisit later as there are currently no sales
                          recorded in your structure at the moment.
                        </Typography>
                      )}
                      {structureD.slice(3, 10).map((Leader, index) => (
                        <Grid item xs={12}>
                          <MobileUserCard
                            rank={index + 4}
                            name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                            percentage={Leader.p}
                            fiber={Leader.sales}
                            outlined={
                              Leader.dealer_code === cu ? "solid" : "none"
                            }
                            outlineColors="#F0BD10"
                          />
                        </Grid>
                      ))}
                      {structureD.length > 10 && (
                        <Button
                          fullWidth
                          onClick={handleExpand}
                          style={{ paddingTop: 8, paddingBottom: 8 }}
                        >
                          <Grid item xs={12}>
                            <Card style={{ padding: 15, margin: -3 }}>
                              <Typography
                                align="center"
                                variant="h3"
                                color="primary"
                              >
                                Expand
                                {!expanded ? (
                                  <KeyboardArrowDown
                                    fontSize="inherit"
                                    style={{ marginBottom: -7.5 }}
                                  />
                                ) : (
                                  <KeyboardArrowUp
                                    fontSize="inherit"
                                    style={{ marginBottom: -7.5 }}
                                  />
                                )}
                              </Typography>
                            </Card>
                          </Grid>
                        </Button>
                      )}
                      {expanded &&
                        structureD.slice(10, 100).map((Leader, index) => (
                          <Grid item xs={12}>
                            <MobileUserCard
                              rank={index + 11}
                              name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                              percentage={Leader.p}
                              fiber={Leader.sales}
                              outlined={
                                Leader.dealer_code === cu ? "solid" : "none"
                              }
                              outlineColors="#F0BD10"
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Hidden>
      )}
    </div>
  );
}
