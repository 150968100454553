import { createTheme } from "@material-ui/core/styles";
import zawadiLogo from "../img/ZawadiHighRes.gif";

const chadezTheme = createTheme({
  palette: {
    primary: {
      main: "#126289",
      contrastText: "#fff",
    },
  },
});

const jurassicTheme = createTheme({
  palette: {
    primary: {
      main: "#a53922",
      contrastText: "#fff",
    },
  },
});

const afrahTheme = createTheme({
  palette: {
    primary: {
      main: "#102b56",
      contrastText: "#fff",
    },
  },
});

const zawadiTheme = createTheme({
  palette: {
    primary: {
      main: "#267353",
      contrastText: "#fff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

// Companies. Jurassic is our dev environment
const companies = {
  zawadi: {
    theme: zawadiTheme,
    logo: zawadiLogo,
  },
};

export { companies, afrahTheme, chadezTheme, jurassicTheme, zawadiTheme };
