import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { CheckCircle, PanTool } from "@material-ui/icons";
import { DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSB } from "../../../contexts/SupabaseContext";

export default function DeleteRequest({ open, close, reqId }) {
  const { updateRow } = useSB();
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleClose = () => {
    setState({
      loading: false,
      error: null,
    });
    close();
  };

  const handleConfirm = async () => {
    setState({
      loading: true,
      error: null,
    });
    try {
      await updateRow("dealers", reqId, {
        status: 4,
      });
      handleClose();
      history.push("/approvals");
    } catch (err) {
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Are You Sure?</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this request? This can not be undone.
      </DialogContent>
      <DialogActions>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <Button
          color="secondary"
          variant="contained"
          startIcon={<PanTool />}
          onClick={handleClose}
          disabled={state.loading}
        >
          No
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<CheckCircle />}
          onClick={handleConfirm}
          disabled={state.loading}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
