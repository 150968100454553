/* eslint-disable react/no-array-index-key */
// #useAuthUpdated
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { ArrowBack, ContactMail, Edit, Face } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useCompany } from "../../../contexts/CompanyContext";
import ChangeSubDealerParent from "../../dealercomps/subdealers/ChangeSubDealerParent";
import { subtitleStyles } from "../../../mui/styles";
import CreateDealerForm from "../CreateDealerForm";
import { useSB } from "../../../contexts/SupabaseContext";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import transformRank from "../functions/transformRank";
import ChangeDealerLevel from "../ChangeDealerLevel";
import ChangeDealerPassword from "./ChangeDealerPassword";

export default function DealerDetail() {
  const history = useHistory();
  const { dealerId } = useParams();
  const { GetTableWhere, updateRow } = useSB();
  const [dealer, setDealer] = useState();
  const [editDealerOpen, setEditDealerOpen] = useState(false);
  const { productTypes, suppliers } = useCompany();
  const [growthLeader, setGrowthLeader] = useState(false);
  const [changeParentOpen, setChangeParentOpen] = useState(false);
  const [parent, setParent] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletingDealer, setDeletingDealer] = useState(false);
  const [changeLevelOpen, setChangeLevelOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [upStream, setUpstream] = useState([]);

  async function getSubDealerDetails(subId) {
    // Get the dealer document by its ID
    const dealerData = await GetTableWhere("dealers", ["id", "eq", subId]);

    // If the dealer document does not exist, throw an error
    if (!dealerData) {
      throw new Error(`Dealer with ID ${subId} not found`);
    }

    // Get the parent dealer document by its ID
    const parentData = await GetTableWhere("dealers", [
      "dealer_code",
      "eq",
      dealerData[0].parent_dealer,
    ]);

    // If the parent dealer document does not exist, throw an error
    if (!parentData) {
      throw new Error(
        `Parent dealer with ID ${dealerData[0].parent_dealer} not found`
      );
    }

    // Get the parent dealer document by its ID
    const ParentDealers = await GetTableWhere("commission_org_structure", [
      "dealer_code",
      "eq",
      dealerData[0].parent_dealer,
    ]);
    setUpstream(await ParentDealers);

    // Return the dealer and parent dealer documents
    return {
      dealer: dealerData[0],
      parent: parentData[0],
    };
  }

  // Function to refresh the displayed details of the dealer
  const refreshDisplayedDetails = async () => {
    setDealer(null);
    const d = await getSubDealerDetails(dealerId);
    setParent(d.parent);
    setDealer(d.dealer);

    // setGrowthLeader(d.dealer.growth_leader);
  };

  useEffect(async () => {
    const d = await getSubDealerDetails(dealerId);
    setParent(await d.parent);
    setDealer(await d.dealer);
    // setGrowthLeader(d.dealer.growth_leader);
  }, []);

  // Function to parse the selected products from the user
  const parseSelectedProducts = productsFromUser => {
    if (!productsFromUser) {
      return "No Products Selected";
    }

    const selectedProducts = productTypes.filter(product =>
      productsFromUser.includes(product.id)
    );

    return selectedProducts?.map(product => product.name).join(", ") || [];
  };

  // Function to parse the selected suppliers from the user
  const parseSelectedSuppliers = suppliersFromUser => {
    if (!suppliersFromUser) {
      return "No Suppliers Selected";
    }

    const selectedSuppliers = suppliers.filter(supplier =>
      suppliersFromUser.includes(supplier.id)
    );

    return (
      selectedSuppliers?.map(supplier => supplier.supplier).join(", ") || []
    );
  };

  // Function to handle the change of the growth leader status of the dealer
  const handleGrowthLeaderChange = event => {
    setGrowthLeader(event.target.checked);

    updateRow("dealers", dealerId, {
      growth_leader: event.target.checked,
      growth_leader_time: event.target.checked ? new Date() : null,
    }).then(result => {
      if (result) {
        refreshDisplayedDetails();
      }
    });
  };

  const buttonStyles = {
    margin: ".5rem .5rem 0 0",
    textTransform: "none",
  };

  // Function to change the status of a dealer to 'leaver' or 'active'
  const changeLeaver = async status => {
    // Check if the status is true
    if (status) {
      try {
        // Update the row in the 'dealers' table, setting 'inactive' to true and 'status' to 'leaver'
        await updateRow("dealers", dealerId, {
          status: 3,
        });
        // Update the local state of the dealer
        setDealer({
          ...dealer,
          status: 3,
        });
      } catch (error) {
        // Log any errors that occur during the update
        console.error(error);
      }
    } else {
      try {
        // Update the row in the 'dealers' table, setting 'inactive' to false and 'status' to 'active'
        await updateRow("dealers", dealerId, {
          status: 2,
        });
        // Update the local state of the dealer
        setDealer({
          ...dealer,
          status: 2,
        });
      } catch (error) {
        // Log any errors that occur during the update
        console.error(error);
      }
    }
  };

  // Function to handle the deletion of a dealer
  const handleDeleteDealer = async () => {
    setDeletingDealer(true);
    try {
      // Update the row in the 'dealers' table, setting 'deleted' to true
      await updateRow("dealers", dealerId, {
        status: 4,
      });
      setDeletingDealer(false);
      setShowDeleteConfirmation(false);
      // Redirect the user to the previous page
      history.goBack();
    } catch (error) {
      // Log any errors that occur during the update
      console.error(error);
    }
  };

  return !dealer ? (
    <CircularProgress />
  ) : (
    <>
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      {dealer.phone ? (
        <div>
          <h1 style={subtitleStyles}>
            {dealer.contact_name} {dealer.contact_surname}
          </h1>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography>User Code: {dealer.dealer_code}</Typography>
                  <Typography>
                    Date Created: {sqlToStandardDate(dealer.created_at)}
                  </Typography>
                  <br />
                  <Typography>ID Number: {dealer.contact_id_no}</Typography>
                  <Typography>Phone Number: {dealer.phone}</Typography>
                  <Typography>Email: {dealer.email}</Typography>
                  <br />
                  <Typography>Street Address: {dealer.street}</Typography>
                  <Typography>Suburb: {dealer.suburb}</Typography>
                  <Typography>City: {dealer.city}</Typography>
                  <Typography>Province: {dealer.province}</Typography>
                  <Typography>Postal Code: {dealer.postal_code}</Typography>
                  <br />
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Product Type:{" "}
                    {parseSelectedProducts(dealer.selected_product_types)}
                  </Typography>
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Suppliers:{" "}
                    {parseSelectedSuppliers(dealer.selected_suppliers)}
                  </Typography>
                  <br />
                  <Typography>Payment Type: {dealer.payment_type}</Typography>
                  {dealer.payment_type === "eWallet" ? (
                    <>
                      <Typography>
                        eWallet Number: {dealer.alt_phone}
                      </Typography>
                      <Typography>
                        eWallet Link: {dealer.e_wallet_link}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography>Bank: {dealer.bank}</Typography>
                      <Typography>
                        Account Type: {dealer.bank_account_type}
                      </Typography>
                      <Typography>Branch Code: {dealer.branch_code}</Typography>
                      <Typography>
                        Account Number: {dealer.bank_account_number}
                      </Typography>
                    </>
                  )}
                  <br />
                  <Button
                    disableElevation
                    startIcon={<Edit />}
                    variant="contained"
                    color="primary"
                    style={buttonStyles}
                    onClick={() => setEditDealerOpen(true)}
                  >
                    Edit
                  </Button>
                  {dealer.status === 3 ? (
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={buttonStyles}
                      onClick={() => changeLeaver(false)}
                    >
                      Reactivate
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={buttonStyles}
                      onClick={() => changeLeaver(true)}
                    >
                      Mark As Inactive
                    </Button>
                  )}
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    style={buttonStyles}
                    onClick={() => setShowDeleteConfirmation(true)}
                  >
                    Delete Dealer
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    style={buttonStyles}
                    onClick={() => setChangePasswordOpen(true)}
                  >
                    Change Dealer Password
                  </Button>{" "}
                  <Button
                    startIcon={<ContactMail />}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    style={buttonStyles}
                    href={dealer.id_photo}
                  >
                    View ID Photo
                  </Button>
                  <Button
                    startIcon={<Face />}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    style={buttonStyles}
                    href={dealer.person_photo}
                  >
                    View Headshot
                  </Button>
                  <div style={{ marginTop: 16 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={growthLeader}
                          onChange={handleGrowthLeaderChange}
                          name="customSwitch"
                          color="primary"
                        />
                      }
                      label="Growth Leader Status"
                    />
                    <Typography variant="body1" style={{ marginTop: "16px" }}>
                      Dealer is{" "}
                      {growthLeader && dealer.growth_leader_time
                        ? `Growth Leader since ${dealer.growth_leader_time}`
                        : "not a Growth Leader"}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <br />
              <Card>
                <CardContent>
                  {parent ? (
                    <>
                      <Typography>Currently Reports to:</Typography>

                      {upStream[0] && dealer.rank >= 8 ? (
                        <Typography>
                          Assistant Team Leader:{" "}
                          {upStream[0].assistant_team_leader}
                        </Typography>
                      ) : null}

                      {upStream[0] && dealer.rank >= 7 ? (
                        <Typography>
                          {" "}
                          Tier 1 Team Leader: {upStream[0].tier_1_team_leader}
                        </Typography>
                      ) : null}

                      {upStream[0] && dealer.rank >= 6 ? (
                        <Typography>
                          Tier 2 Team Leader: {upStream[0].tier_2_team_leader}
                        </Typography>
                      ) : null}

                      {upStream[0] && dealer.rank >= 5 ? (
                        <Typography>
                          Sales manager: {upStream[0].sales_manager}
                        </Typography>
                      ) : null}
                      {upStream[0] && dealer.rank >= 4 ? (
                        <Typography>Dealer: {upStream[0].dealer}</Typography>
                      ) : null}
                      {upStream[0] && dealer.rank >= 3 ? (
                        <Typography>
                          Super Dealer: {upStream[0].super_dealer}
                        </Typography>
                      ) : null}
                      {dealer.rank <= 2 ? (
                        <Typography>Zawadi management</Typography>
                      ) : null}
                    </>
                  ) : (
                    <Typography>No Parent Found</Typography>
                  )}
                  <br />
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    style={buttonStyles}
                    onClick={() => setChangeParentOpen(true)}
                  >
                    Change Manager
                  </Button>
                </CardContent>
              </Card>
              <br />
              <Card>
                <CardContent>
                  <Typography>
                    Current Level: {transformRank(dealer.rank)}
                  </Typography>
                  <br />
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    style={buttonStyles}
                    onClick={() => {
                      setChangeLevelOpen(true);
                    }}
                  >
                    Change Level
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* ============================================================ */}
          <ChangeDealerLevel
            dealer={dealer}
            parentLevel={parent?.rank || 1}
            open={changeLevelOpen}
            close={() => {
              setChangeLevelOpen(false);
              refreshDisplayedDetails();
            }}
          />{" "}
          <ChangeSubDealerParent
            open={changeParentOpen}
            close={() => setChangeParentOpen(false)}
            dealer={dealer}
          />
          <CreateDealerForm
            dialogIsOpen={editDealerOpen}
            closeDialog={() => {
              setEditDealerOpen(false);
              refreshDisplayedDetails();
            }}
            dealerProp={dealer}
            editing
          />
          <ChangeDealerPassword
            dealerId={dealer.auth_id}
            open={changePasswordOpen}
            close={() => setChangePasswordOpen(false)}
          />
          {/* <RemoveDealer
            dealerId={dealer.id}
            open={removeDealerOpen}
            uid={dealer.uid}
            rank={dealer.rank}
            close={() => setRemoveDealerOpen(false)}
          /> */}
        </div>
      ) : (
        <Typography>User does not exist.</Typography>
      )}
      <ConfirmationDialog
        open={showDeleteConfirmation}
        message="Are you sure you want to delete this dealer?"
        onConfirm={handleDeleteDealer}
        onClose={() => setShowDeleteConfirmation(false)}
        loading={deletingDealer}
      />
    </>
  );
}
