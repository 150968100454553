import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Avatar, Button, Card, CardHeader } from "@material-ui/core";
import { ArrowBack, Info } from "@material-ui/icons";
import moment from "moment";
import { useDb } from "../../../contexts/DatabaseContext";

export default function DealerChangeLog() {
  const { GetDealerChangeLog } = useDb();
  const { dealerId } = useParams();
  const events = GetDealerChangeLog(dealerId);
  const history = useHistory();

  return (
    <div>
      <h1>User Edits History</h1>
      <div className="back-link">
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
          color="primary"
        >
          Back
        </Button>
      </div>
      {events &&
        events.map(e => (
          <Card key={e.id} style={{ marginBottom: 10 }}>
            <CardHeader
              title={e.description}
              avatar={
                <Avatar>
                  <Info />
                </Avatar>
              }
              subheader={moment(e.timestamp.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            />
          </Card>
        ))}
    </div>
  );
}
