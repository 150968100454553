/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from "react";
import "firebase/database";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Select from "react-select";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useHistory } from "react-router-dom";
import { useCompany } from "../../contexts/CompanyContext";
import { useSB } from "../../contexts/SupabaseContext";

const agentLevelOptions = [
  { label: "Admin (1)", value: 1 },
  { label: "Super Dealer (2)", value: 2 },
  { label: "Dealer (3)", value: 3 },
  { label: "Sales Manager (4)", value: 4 },
  { label: "Tier 2 Team Leader (5)", value: 5 },
  { label: "Tier 1 Team Leader (6)", value: 6 },
  { label: "Assistant Team Leader (7)", value: 7 },
  { label: "Agent (8)", value: 8 },
];

export default function TrainingModules() {
  // Default state moduleState
  const defModuleState = {
    active: true,
    marks: 0,
    module_category: "",
    module_description: "",
    module_name: "",
    pass_threshold: 80,
    product_types: [],
    selected_levels: [],
    suppliers: [],
  };

  const history = useHistory();
  const [modules, setModules] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [moduleState, setModuleState] = useState(defModuleState);
  const [creatingModule, setCreatingModule] = useState(false);
  // Filter states
  const [active, setActive] = useState(true);
  const [agentLevel, setAgentLevel] = useState(null);
  const [productType, setProductType] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const { productTypes, activeSuppliers } = useCompany();
  const [formattedProductTypes, setFormattedProductTypes] = useState([]);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [editingModule, setEditingModule] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modulesDisplay, setModulesDisplay] = useState([]);
  const { deleteRow, GetWholeTable, insertRow, updateRow } = useSB();
  const sortedArr = modulesDisplay.sort((a, b) => {
    if (a.module_category && b.module_category) {
      return a.module_category.localeCompare(b.module_category);
    }
    if (a.module_category) {
      return -1; // Place items with a category before those without
    }
    if (b.module_category) {
      return 1; // Place items with a category after those without
    }
    return 0; // Leave the order unchanged if both items lack a category
  });

  useEffect(() => {
    // Set the loading state to true and update modules display with modules data
    setLoadingPage(true);
    setModulesDisplay(modules);
    setLoadingPage(false);
  }, [modules]);

  useEffect(() => {
    // Format the product types array for active product types and update the state
    const formattedProductTypesArray = [];
    productTypes.forEach(type => {
      if (type.is_active === true) {
        formattedProductTypesArray.push({
          label: type.name,
          value: type.id,
        });
      }
    });

    setFormattedProductTypes(formattedProductTypesArray);
  }, [productTypes]);

  useEffect(() => {
    // Map active suppliers into correct format
    const supplierMap = activeSuppliers.map(sup => ({
      label: sup.supplier,
      value: sup.id,
    }));

    setAvailableSuppliers(supplierMap);
  }, [activeSuppliers]);

  useEffect(() => {
    // Fetch all training modules and update the state
    fetchModules();
  }, []);

  useEffect(() => {
    // Filter modules based on various criteria and update the display
    let tempModules = modules;

    const filters = [
      { field: "active", value: true },
      {
        field: "product_types",
        value: productType?.value,
      },
      {
        field: "suppliers",
        value: supplier?.value,
      },
      {
        field: "selected_levels",
        value: parseInt(agentLevel?.value),
      },
    ];

    filters.forEach(filter => {
      if (filter.value && filter.value !== "All") {
        tempModules = tempModules.filter(obj => {
          if (filter.field === "active") {
            return obj.active === active;
          }

          return (
            obj[filter.field] === undefined ||
            obj[filter.field].includes(filter.value) ||
            obj[filter.field].length === 0
          );
        });
        setModulesDisplay(tempModules);
      }
    });
  }, [active, agentLevel, productType, supplier, creatingModule, modules]);

  // Fetch moodules from Supabase

  const fetchModules = () => {
    setLoadingPage(true);

    setModules([]);

    GetWholeTable("training_modules", "module_name")
      .then(data => {
        setLoadingPage(false);
        setModules(data);
        setModulesDisplay(data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // Handle changes in selected levels for a module
  const handleLevelChange = event => {
    const level = parseInt(event.target.name, 10);
    const levelArray = moduleState?.selected_levels || [];

    if (event.target.checked) {
      levelArray.push(level);
      setModuleState(prevState => ({
        ...prevState,
        selected_levels: levelArray,
      }));
    } else {
      setModuleState(prevState => ({
        ...prevState,
        selected_levels: levelArray.filter(
          selectedLevel => selectedLevel !== level
        ),
      }));
    }
  };

  // Handle changes in selected product types for a module
  const handleProductTypeChange = event => {
    const type = event.target.name;
    const array = moduleState?.product_types || [];

    if (event.target.checked) {
      array.push(type);
      setModuleState(prevState => ({
        ...prevState,
        product_types: array,
      }));
    } else {
      setModuleState(prevState => ({
        ...prevState,
        product_types: array.filter(selectedType => selectedType !== type),
      }));
    }
  };

  // Handle changes in selected suppliers for a module
  const handleSupplierChange = event => {
    const supplierInstance = event.target.name;
    const array = moduleState?.suppliers || [];

    if (event.target.checked) {
      array.push(supplierInstance);
      setModuleState(prevState => ({
        ...prevState,
        suppliers: array,
      }));
    } else {
      setModuleState(prevState => ({
        ...prevState,
        suppliers: array.filter(
          selectedSupplier => selectedSupplier !== supplierInstance
        ),
      }));
    }
  };

  // Open the dialog for editing or creating a module
  const handleDialogOpen = module => {
    if (module) {
      setModuleState(module);
      setEditingModule(true);
    } else {
      setEditingModule(false);
    }
    setOpenDialog(true);
  };

  // Close the dialog for editing or creating a module
  const handleDialogClose = () => {
    setModuleState(defModuleState);
    setCreatingModule(false);
    setOpenDialog(false);
    fetchModules();
  };

  // Handle the creation of a new module
  const handleCreateModule = async () => {
    setCreatingModule(true);

    insertRow("training_modules", moduleState).then(() => {
      handleDialogClose();
    });
  };

  // Handle the update of an existing module
  const handleUpdateModule = async () => {
    setCreatingModule(true);

    updateRow("training_modules", moduleState.id, moduleState).then(() => {
      setSnackbarOpen(true);
      handleDialogClose();
    });
  };

  // Handle changes in the input fields for module details
  const handleChange = event => {
    const { name, value } = event.target;
    setModuleState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle the deletion of a module
  const handleDeleteModule = async () => {
    deleteRow("training_modules", moduleState.id).then(() => {
      setShowConfirmationDialog(false);
      handleDialogClose();
    });
  };

  // Transform the level value to the corresponding level name
  const transformLevel = level => {
    const levelName = agentLevelOptions.find(
      option => option.value === level
    )?.label;
    return levelName;
  };

  return (
    <div>
      <h1>Training Modules</h1>
      {loadingPage && <CircularProgress />}
      {/* Filters */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h3>Filters</h3>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            placeholder="Status"
            name="active"
            options={[
              { label: "Active", value: true },
              { label: "Inactive", value: false },
            ]}
            defaultValue={{ label: "Active", value: true }}
            onChange={e => {
              setActive(e.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            placeholder="Product Type"
            name="productType"
            options={[
              {
                label: "All",
                value: "All",
              },
              ...formattedProductTypes,
            ]}
            value={productType}
            onChange={e => {
              setProductType(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            placeholder="Supplier"
            name="supplier"
            options={[
              {
                label: "All",
                value: "All",
              },
              ...availableSuppliers,
            ]}
            value={supplier}
            onChange={e => {
              setSupplier(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            placeholder="Agent Level"
            name="agentLevel"
            options={[
              {
                label: "All",
                value: "All",
              },
              ...agentLevelOptions,
            ]}
            value={agentLevel}
            onChange={e => {
              setAgentLevel(e);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Button
            onClick={() => {
              handleDialogOpen();
            }}
            variant="outlined"
            color="primary"
          >
            Create New Module
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h3>Module List</h3>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {sortedArr.map(module => (
          <Grid item xs={12} sm={7} key={module.id}>
            <div>
              <List>
                <ListItem
                  style={{
                    marginBottom: 10,
                    borderBottom: "solid 1px #d2d2d2",
                  }}
                  key={module.id}
                >
                  <ListItemText
                    primary={module.module_name}
                    secondary={
                      module.module_category ? (
                        <Typography variant="overline">
                          {module.module_category}
                        </Typography>
                      ) : (
                        "No Category"
                      )
                    }
                  />
                  <Button
                    onClick={() => {
                      history.push(
                        `/training-content/${module.id}/${module.module_name}`
                      );
                    }}
                  >
                    See Content
                  </Button>
                  <Button onClick={() => handleDialogOpen(module)}>Edit</Button>
                  <Button
                    onClick={() => {
                      history.push(
                        `/training-marks/${module.id}/${module.module_name}`
                      );
                    }}
                  >
                    View Marks
                  </Button>
                </ListItem>
              </List>
            </div>
          </Grid>
        ))}
        {sortedArr.length === 0 && loadingPage === false && (
          <Grid item xs={12} sm={6}>
            <Typography>No Modules Found.</Typography>
          </Grid>
        )}
      </Grid>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          {editingModule ? "Edit Module" : "Create New Module"}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            variant="outlined"
            label="Module Name"
            name="module_name"
            value={moduleState?.module_name}
            onChange={handleChange}
          />
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            variant="outlined"
            label="Module Description"
            name="module_description"
            value={moduleState?.module_description}
            onChange={handleChange}
          />
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            variant="outlined"
            label="Module Category"
            name="module_category"
            value={moduleState?.module_category}
            onChange={handleChange}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Toggle Status</FormLabel>
            <RadioGroup
              aria-label="status"
              name="active"
              value={moduleState?.active}
              onChange={e => {
                // eslint-disable-next-line no-unneeded-ternary
                setModuleState(prevState => ({
                  ...prevState,
                  active: e.target.value,
                }));
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ marginTop: 20 }}>
            <Typography style={{ marginBottom: 20 }}>
              Applicable Levels (Optional):
            </Typography>
            <FormGroup>
              {[1, 2, 3, 4, 5, 6, 7, 8].map(level => (
                <FormControlLabel
                  key={level}
                  control={
                    <Checkbox
                      checked={moduleState?.selected_levels?.includes(level)}
                      onChange={handleLevelChange}
                      name={level.toString()}
                    />
                  }
                  label={`Agent Level ${transformLevel(level)}`}
                />
              ))}
            </FormGroup>
          </div>
          <div style={{ marginTop: 20 }}>
            <Typography style={{ marginBottom: 20 }}>
              Applicable Product Types (Optional):
            </Typography>
            <FormGroup>
              {formattedProductTypes.map(type => (
                <FormControlLabel
                  key={type.value}
                  control={
                    <Checkbox
                      checked={moduleState?.product_types?.includes(type.value)}
                      onChange={handleProductTypeChange}
                      name={type.value}
                    />
                  }
                  label={type.label}
                />
              ))}
            </FormGroup>
          </div>
          <div style={{ marginTop: 20 }}>
            <Typography style={{ marginBottom: 20 }}>
              Applicable Suppliers (Optional):
            </Typography>
            <FormGroup>
              {availableSuppliers.map(supplierLocal => (
                <FormControlLabel
                  key={supplierLocal.value}
                  control={
                    <Checkbox
                      checked={moduleState?.suppliers?.includes(
                        supplierLocal.value
                      )}
                      onChange={handleSupplierChange}
                      name={supplierLocal.value}
                    />
                  }
                  label={supplierLocal.label}
                />
              ))}
            </FormGroup>
          </div>
          <TextField
            style={{ marginBottom: 10, marginTop: 20 }}
            fullWidth
            variant="outlined"
            label="Marks"
            name="marks"
            value={moduleState?.marks}
            onChange={handleChange}
          />
          <div style={{ width: "60%", marginTop: 20, marginBottom: 20 }}>
            <Typography>
              % To Pass: {moduleState?.pass_threshold || 80}%
            </Typography>
            <Slider
              value={moduleState?.pass_threshold || 80}
              min={0}
              max={100}
              step={1}
              onChange={handleChange}
              name="pass_threshold"
              valueLabelDisplay="auto"
              valueLabelSuffix=" %"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={creatingModule}
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          {editingModule && (
            <Button
              disabled={creatingModule}
              onClick={() => setShowConfirmationDialog(true)}
              color="primary"
            >
              Delete
            </Button>
          )}
          {!editingModule ? (
            <Button
              disabled={creatingModule}
              onClick={handleCreateModule}
              color="primary"
            >
              {creatingModule ? "Loading..." : "Create"}
            </Button>
          ) : (
            <Button
              disabled={creatingModule}
              onClick={handleUpdateModule}
              color="primary"
            >
              {creatingModule ? "Loading..." : "Update"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <DialogTitle>Delete Module</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this module?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmationDialog(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteModule()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
      >
        <Alert severity="success">Module Updated</Alert>
      </Snackbar>
    </div>
  );
}
