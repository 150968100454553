import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// import moment from "moment";
import { ArrowBack } from "@material-ui/icons";
import { useSB } from "../../../contexts/SupabaseContext";

// import ChangeSubDealerLevel from "./ChangeSubDealerLevel";
// import ChangeSubDealerParent from "./ChangeSubDealerParent";

export default function SubDealerDetail() {
  const { dealerId } = useParams();
  const history = useHistory();
  const { GetTableWhere } = useSB();
  const [d, setD] = useState(null);
  const [dealerLoaded, setDealerLoaded] = useState(false);
  // const [changeLevelOpen, setChangeLevelOpen] = useState(false);
  // const [changeParentOpen, setChangeParentOpen] = useState(false);

  async function getSubDealerDetails(subId) {
    // Get the dealer document by its ID
    const dealerData = await GetTableWhere("dealers", ["id", "eq", subId]);

    if (!dealerData) {
      throw new Error(`Dealer with ID ${subId} not found`);
    }

    // Get the parent dealer document by its ID
    const parentData = await GetTableWhere("dealers", [
      "dealer_code",
      "eq",
      dealerData[0].parent_dealer,
    ]);

    if (!parentData) {
      throw new Error(
        `Parent dealer with ID ${dealerData[0].parent_dealer} not found`
      );
    }

    return {
      dealer: dealerData[0],
      parent: parentData[0],
    };
  }

  if (!dealerLoaded) {
    getSubDealerDetails(dealerId)
      .then(data => {
        setD(data);
        setDealerLoaded(true);
      })
      .catch(error => {
        console.error(error);
        return <CircularProgress />;
      });
  }

  return (
    <div>
      <h1>
        {d?.dealer.contact_name} {d?.dealer.contact_surname}
      </h1>
      <div className="back-link">
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <Typography>Level: {d?.dealer.rank}</Typography>
              <Typography>Phone Number: {d?.dealer.phone}</Typography>
              <Typography>Email: {d?.dealer.email}</Typography>
              <Typography>Street: {d?.dealer.street}</Typography>
              <Typography>City: {d?.dealer.city}</Typography>
              <Typography>Province: {d?.dealer.province}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardContent>
                <Typography>
                  Currently Reports to:{" "}
                  <b>
                    {d.parent.contactName} {d.parent.contactSurname}
                  </b>
                </Typography>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setChangeParentOpen(true)}
                >
                  Change Manager
                </Button>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <Typography>
                  Current Level:{" "}
                  <b>
                    {(d.dealer.selectedLevel &&
                      d.dealer.selectedLevel.levelName) ||
                      d.dealer.rank}
                  </b>
                </Typography>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setChangeLevelOpen(true)}
                >
                  Change Level
                </Button>
              </CardContent>
            </Card>
          </Grid> */}
      </Grid>
      {/* 
      <ChangeSubDealerLevel
        dealer={d.dealer}
        parentLevel={d.parent.rank}
        open={changeLevelOpen}
        close={() => setChangeLevelOpen(false)}
      />

      <ChangeSubDealerParent
        open={changeParentOpen}
        close={() => setChangeParentOpen(false)}
        dealer={d.dealer}
      /> */}
    </div>
  );
}
