// React and external libraries
import React, { useEffect, useState } from "react";
import moment from "moment";

// Material-UI components
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faClipboardList,
  faEdit,
  faSync,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { supabase } from "../../../supabase";

// Context
import { useCompany } from "../../../contexts/CompanyContext";
import { useAuth } from "../../../contexts/AuthContext";

// Components
import ModalCard from "../../shared/ModalCard";
import ViewLead from "./ViewLead";
import EditLeadModal from "./EditLeadModal";
import DataGridSearchBar from "../../shared/DataGridSearchBar";
import DownloadSupportingDocsModal from "./DownloadSupportingDocsModal";
import PeriodSelector from "../../global/PeriodSelector";

// Functions
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";

// Export
export default function SalesLeads() {
  // Hooks for context and state management
  const { activeSuppliers } = useCompany();
  const { currentUser } = useAuth();
  const currentDate = new Date().toISOString();
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);

  // Filter States
  const [displayLeads, setDisplayLeads] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState("all");
  const [period, setPeriod] = useState({
    active: false,
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  // Define initial filter states
  const [filters, setFilters] = useState({
    me: {
      active: false,
      filter: {
        column: "last_updated_by_code",
        value: currentUser.dealer_code,
        type: "and",
      },
    },
    open: {
      active: true,
      filter: {
        column: "status",
        value: "Open",
        type: "or",
      },
    },
    inProgress: {
      active: true,
      filter: {
        column: "status",
        value: "In Progress",
        type: "or",
      },
    },
    closed: {
      active: false,
      filter: {
        column: "status",
        value: "Closed",
        type: "or",
      },
    },
    updatedToday: {
      active: false,
      filter: {
        column: "last_updated_at",
        value: sqlToStandardDate(currentDate),
        type: "and",
      },
    },
    notUpdatedToday: {
      active: false,
      filter: {
        column: "last_updated_at",
        value: sqlToStandardDate(currentDate),
        type: "not",
      },
    },
  });

  const [searchText, setSearchText] = useState("");

  // State Handlers

  // Handle changes to the filter checkboxes
  const handleFilters = event => {
    setFilters(prevFiltersEq => ({
      ...prevFiltersEq,
      [event.target.name]: {
        ...prevFiltersEq[event.target.name],
        active: !prevFiltersEq[event.target.name].active,
      },
    }));
  };

  // Handle supplier changes
  const handleChangeSuppliers = event => {
    setSelectedSuppliers(event.target.value);
  };

  // Handle the period selector filter
  const handlePeriod = (type, value) => {
    if (type === "active") {
      setPeriod(prevVal => ({ ...prevVal, active: !prevVal.active }));
    } else if (type === "startDate") {
      setPeriod(prevVal => ({ ...prevVal, startDate: value }));
    } else if (type === "endDate") {
      setPeriod(prevVal => ({ ...prevVal, endDate: value }));
    }
  };

  // Modal states
  const [viewLead, setViewLead] = useState({
    isOpen: false,
    id: null,
  });
  const [editLeadModal, setEditLeadModal] = useState({
    isOpen: false,
    id: null,
  });
  const [viewSupDocsModal, setViewSupDocsModal] = useState({
    isOpen: false,
    id: null,
  });

  // Modal handlers
  const handleViewLead = leadId => {
    setViewLead({ id: leadId, isOpen: true });
  };
  const closeViewLead = () => {
    setViewLead({ id: null, isOpen: false });
    fetchData();
  };

  const handleEditLeadModal = leadId => {
    setEditLeadModal({ id: leadId, isOpen: true });
  };
  const closeEditLeadModal = () => {
    setEditLeadModal({ id: null, isOpen: false });
    fetchData();
  };

  const handleViewSupDocsModal = docRef => {
    setViewSupDocsModal({ id: docRef, isOpen: true });
  };
  const closeViewSupDocsModal = () =>
    setViewSupDocsModal({ id: null, isOpen: false });

  // Functions

  // Function to filter leads based on search text.
  const requestSearch = searchValue => {
    // Set the search text state
    setSearchText(searchValue);

    // Create a regular expression to match the search value, ignoring case
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    // Filter the leads based on the search text
    const filteredRows = leads.filter(row => {
      // Check if any field in the row matches the search regex
      return Object.keys(row).some(field => {
        // Convert field value to string and test against search regex
        return searchRegex.test(row[field] && row[field].toString());
      });
    });

    // Update the displayed leads with the filtered rows
    setDisplayLeads(filteredRows);
  };

  const clearSearch = () => requestSearch("");

  // Helper function to escape special characters in a string
  const escapeRegExp = string => {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  // Function to remove unwanted characters from text
  const cleanText = (s = "") => {
    const regex = /["\n,]/g;
    const str = s;
    const cleanedStr = str.replace(regex, " ");
    return cleanedStr;
  };

  // Function to fetch leads data from Supabase based on applied filters and period.
  const fetchData = async () => {
    // Set loading state to true
    setLoading(true);

    // Initialize arrays to store filter conditions
    const andFiltersArr = [];
    const orFiltersArr = [];
    const notFiltersArr = [];

    // Loop through each filter and categorize them based on type
    Object.keys(filters).forEach(key => {
      if (filters[key].active) {
        const { column, value, type } = filters[key].filter;
        if (type === "or") {
          // If filter type is 'or', add condition to OR filters array
          orFiltersArr.push(`${column}.eq.${value}`);
        } else if (type === "and") {
          // If filter type is 'and', add condition to AND filters array
          andFiltersArr.push({ column, value });
        } else if (type === "not") {
          // If filter type is 'not', add condition to NOT filters array
          notFiltersArr.push({ column, value });
        }
      }
    });

    // Create a Supabase query object
    let query = supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .select("*")
      .order("date_submitted", { ascending: false })
      .eq("is_active", true)
      .or(orFiltersArr.join(","));

    // Apply AND filters to the query
    andFiltersArr.forEach(qFilter => {
      query = query.eq(qFilter.column, qFilter.value);
    });

    // Apply NOT filters to the query
    notFiltersArr.forEach(qFilter => {
      query = query.neq(qFilter.column, qFilter.value);
    });

    // Apply period filter to the query if active
    if (period.active) {
      query = query
        .gte("date_submitted", period.startDate)
        .lte("date_submitted", period.endDate);
    }

    // Apply supplier filter to the query if not 'all' suppliers selected
    if (selectedSuppliers !== "all") {
      query = query.eq("supplier", selectedSuppliers);
    }

    // Execute the query to fetch leads data from Supabase
    const { data: salesLeads, error } = await query;

    // Handle errors and update leads state with fetched data
    if (error) {
      console.error(error);
    } else setLeads(salesLeads);

    // Set loading state to false
    setLoading(false);
  };

  // useEffects
  // Effect to fetch leads data when dependencies change
  useEffect(() => {
    fetchData();
  }, [selectedSuppliers, filters, period]);

  // Effect to update displayed leads and perform search
  useEffect(() => {
    setDisplayLeads(leads);
    requestSearch(searchText);
  }, [leads]);

  // Components
  // Datagrid Toolbar - Function to render datagrid toolbar
  const quickSearchToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button variant="text" color="primary" size="small" onClick={fetchData}>
          <Grid container spacing={1} direction="row">
            <Grid item>
              <FontAwesomeIcon icon={faSync} size="md" />
            </Grid>
            <Grid item>
              <Typography variant="small">Refresh</Typography>
            </Grid>
          </Grid>
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // Datagrid Columns - Define columns for the datagrid
  const columns = [
    {
      field: "Edit/View/Download",

      width: 200,
      renderCell: params => {
        return (
          <Grid spacing={1} container direction="row">
            <Grid item xs={4}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleEditLeadModal(params.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleViewLead(params.id)}
              >
                <FontAwesomeIcon icon={faClipboardList} />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="text"
                color="primary"
                size="small"
                disabled={params.row.download_supporting_ref_disabled}
                onClick={() => handleViewSupDocsModal(params.row.sup_doc_ref)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
    { field: "date", headerName: "Date", width: 150 },
    { field: "agent", headerName: "Agent", width: 150 },
    {
      field: "last_updated_by_name",
      headerName: "Last Updated By",
      width: 150,
    },
    { field: "last_updated_at", headerName: "Last Updated At", width: 150 },
    { field: "supplier", headerName: "Supplier", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "sales_lead_in_progress_status",
      headerName: "In Prgress Status",
      width: 150,
      hide: true,
    },
    {
      field: "sales_lead_closed_status",
      headerName: "Closed Status",
      width: 150,
      hide: true,
    },
    { field: "ref_number", headerName: "Ref Number", width: 180 },
    { field: "customer_name", headerName: "Customer Name", width: 200 },
    { field: "address", headerName: "Address", width: 220 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 180,
    },
    {
      field: "alternative_number",
      headerName: "WhatsApp Number",
      width: 200,
      hide: true,
    },
    {
      field: "email_address",
      headerName: "Email Address",
      width: 200,
      hide: true,
    },
    {
      field: "id_number",
      headerName: "ID Number",
      width: 200,
      hide: true,
    },
    {
      field: "agreed_to_terms",
      headerName: "Agreed To Terms",
      width: 200,
      hide: true,
    },
    { field: "platform", headerName: "Platform", width: 200 },
    { field: "agent_note", headerName: "Agent Note", width: 200 },

    { field: "note", headerName: "Feedback", width: 200 },
    {
      field: "gps_location",
      headerName: "GPS Location",
      with: 220,
      hide: true,
    },
  ];

  // Row Map for datagrid - Map leads data for display
  const rows = displayLeads?.map(row => {
    const refNumber = row.ref_number === "" ? "null" : row.ref_number;

    const downloadSupportingRefDisabled = !row.supporting_document_ref;

    const date = sqlToStandardDate(row.date_submitted);

    const supplierObj = activeSuppliers.filter(
      sup => row.supplier === sup.id
    )[0];

    if (supplierObj.supplier) {
      const { supplier } = supplierObj;

      return {
        id: row.id,
        date,
        agent: row.dealer_code,
        last_updated_by_name: row.last_updated_by_name,
        last_updated_at: sqlToStandardDate(row.last_updated_at),
        address: row.address,
        alternative_number: row.alternative_number,
        phone_number: row.phone_number,
        customer_name: row.customer_name,
        email_address: row.email_address,
        id_number: row.id_number,
        ref_number: refNumber,
        agreed_to_terms: row.agreed_to_terms,
        supplier,
        platform: row.platform !== "App" ? "ussd" : row.platform,
        note: row.note && cleanText(row.note),
        // note: row.note,
        status: row.status,
        category: row.category,
        gps_location: row.gps_location,
        sales_lead_closed_status: row.sales_lead_closed_status,
        sales_lead_in_progress_status: row.sales_lead_in_progress_status,
        sup_doc_ref: row.supporting_document_ref,
        download_supporting_ref_disabled: downloadSupportingRefDisabled,
        agent_note: row.agent_note,
      };
    }
    return {};
  });

  // MAIN RETURN - Render main components and modals
  return (
    <Grid spacing={3} container direction="column">
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          style={{ margin: 10 }}
          size="medium"
          fullWidth
        >
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Select a Supplier
          </InputLabel>
          <Select
            label="Select a Supplier"
            value={selectedSuppliers}
            onChange={handleChangeSuppliers}
          >
            <MenuItem value="all">All Suppliers</MenuItem>
            {activeSuppliers.map(supplier => {
              return (
                <MenuItem value={supplier.id} key={supplier.id}>
                  {supplier.supplier}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          rows
          spacing={1}
          justifyContent="space-between"
          alignItems="bottom"
        >
          <Grid
            item
            xs={12}
            md={3}
            alignContent="center"
            justifyContent="center"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.open.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="open"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Open
                  </Typography>
                }
                size="small"
                color="primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.inProgress.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="inProgress"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    In Progress
                  </Typography>
                }
                size="small"
                color="primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.closed.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="closed"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Closed
                  </Typography>
                }
                size="small"
                color="primary"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            alignContent="center"
            justifyContent="center"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.updatedToday.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="updatedToday"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Updated Today
                  </Typography>
                }
                size="small"
                color="primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.notUpdatedToday.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="notUpdatedToday"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Not Updated Today
                  </Typography>
                }
                size="small"
                color="primary"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            alignContent="center"
            justifyContent="center"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.me.active}
                    color="primary"
                    size="small"
                    onChange={handleFilters}
                    name="me"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Me Mode
                  </Typography>
                }
                size="small"
                color="primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={period.active}
                    color="primary"
                    size="small"
                    onChange={() => handlePeriod("active")}
                    name="date"
                  />
                }
                label={
                  <Typography color="primary" size="small">
                    Use Date
                  </Typography>
                }
                size="small"
                color="primary"
              />
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            alignContent="center"
            justifyContent="center"
          >
            <DataGridSearchBar
              requestSearch={requestSearch}
              searchText={searchText}
              clearSearch={clearSearch}
            />
          </Grid>
          {period.active && (
            <PeriodSelector
              changeStartDate={date => handlePeriod("startDate", date)}
              changeEndDate={date => handlePeriod("endDate", date)}
            />
          )}
        </Grid>
      </Grid>

      <Grid item style={{ height: 750 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          components={{
            Toolbar: quickSearchToolbar,
          }}
        />
      </Grid>
      <ModalCard
        open={viewLead.isOpen}
        onClose={closeViewLead}
        closeButtonText="Close"
      >
        <ViewLead leadId={viewLead.id} />
      </ModalCard>
      <ModalCard open={editLeadModal.isOpen} onClose={closeEditLeadModal}>
        <EditLeadModal leadId={editLeadModal.id} onClose={closeEditLeadModal} />
      </ModalCard>
      <ModalCard
        open={viewSupDocsModal.isOpen}
        onClose={closeViewSupDocsModal}
        closeButtonText="Close"
      >
        <DownloadSupportingDocsModal
          bucket="sales_assist"
          folderRef={viewSupDocsModal.id}
        />
      </ModalCard>
    </Grid>
  );
}
