// #useAuthUpdated
/* eslint-disable no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-await */
/* eslint-disable array-callback-return */
/* eslint-disable no-throw-literal */
import React, { useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import moment from "moment";
import nanoId from "nano-id";
import { db, storage, functions } from "../firebase";
import { useAuth } from "./AuthContext";

import * as API from "../api";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export default function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  const updateDealerPhoneArray = async (dealerId, newPhoneArray) =>
    await db
      .collection("dealers")
      .doc(dealerId)
      .update({
        phoneArray: newPhoneArray,
      })
      .then(() => "Phone array successfully updated")
      .catch(err => {
        throw err;
      });

  // TODO: Remember to set the security rules so that only admin custom claims owners can use this:
  function GetAllUsers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db.collection("users").onSnapshot(snapshot => {
        const userList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().contactName} ${doc.data().contactSurname} - (${
            doc.data().email
          })`,
          value: { ...doc.data() },
        }));
        setUsers(userList);
      });
      return unsubscribe;
    }, []);
    return users;
  }

  // ---------------------------------------<<<<<<<<<  SAVING DATA >>>>>>>>>>>------------------------------------------

  // Change of level functions.

  function hasChildren(uid, newLevel) {
    return new Promise(resolve => {
      db.collection("dealers")
        .where("parentDealer", "==", uid)
        .get()
        .then(docs => {
          if (docs.empty) {
            resolve({ hasChildren: false });
          } else {
            const childrenArray = [];
            docs.docs.forEach((dealerItem, index) => {
              childrenArray.push(dealerItem.data());
              if (index + 1 === docs.docs.length) {
                const hasDealerWithRank = Object.values(childrenArray).filter(
                  item => item.rank <= newLevel
                );

                if (hasDealerWithRank.length === 0) {
                  resolve({ hasChildren: true, levelConflict: false });
                } else {
                  const concatenatedNames = Object.values(hasDealerWithRank)
                    .map(
                      childItem =>
                        `${childItem.dealerCode}: ${childItem.contactName} ${childItem.contactSurname}`
                    )
                    .join(", ");

                  resolve({
                    hasChildren: true,
                    levelConflict: true,
                    agentList: concatenatedNames,
                  });
                }
              }
            });
          }
        });
    });
  }

  function executeChangeLevel(uid, newLevel, selectedLevel) {
    return new Promise(resolve => {
      db.collection("dealers")
        .doc(uid)
        .update({
          rank: newLevel,
          selectedLevel,
        })
        .then(() => {
          db.collection("users")
            .doc(uid)
            .update({
              rank: newLevel,
              selectedLevel,
            })
            .then(() => {
              resolve();
            });
        });
    });
  }

  async function changeLevel(dealer, selectedLevel) {
    const existingLevel = dealer.rank;

    const newLevel = selectedLevel.level;

    // Note:

    return new Promise(resolve => {
      if (newLevel === existingLevel) {
        resolve({ status: 200, message: "ok" });
      } else if (newLevel > existingLevel) {
        // Dealer is being demoted

        hasChildren(dealer.uid, newLevel).then(hasChildrenResult => {
          if (
            hasChildrenResult.hasChildren &&
            hasChildrenResult.levelConflict
          ) {
            // IF the agent is currently parent to any agents on the same or lower level
            // than the level you’re trying to change to, provide the following message:
            resolve({
              status: 404,
              // eslint-disable-next-line max-len
              message: `Error: You are trying to move an agent to an equivalent or lower position in the Org Structure compared to one or more of the child agents.\n\nPlease correct the children accounts first by manually linking them to someone in a higher position or demoting them to a position lower than the future position of their parent.\n\nThe agents that are influenced by this are the following:\n\n${hasChildrenResult.agentList}`,
            });
          } else {
            // IF the agent has no child agents linked THEN demote the agent level as requested.
            executeChangeLevel(dealer.uid, newLevel, selectedLevel).then(() => {
              resolve({ status: 200, message: "ok" });
            });
          }
        });
      } else if (newLevel < existingLevel) {
        // Dealer is being upgraded.
        executeChangeLevel(dealer.uid, newLevel, selectedLevel).then(() => {
          resolve({ status: 200, message: "ok" });
        });
      }
    });
  }

  // -------------------- RICA ---------------------

  async function getDealerName(uid) {
    return await db
      .collection("dealers")
      .doc(uid)
      .get()
      .then(doc => doc.data().dealerName);
  }

  async function changeParent(dealerId, newParent, oldParent) {
    const dealer = await db
      .collection("dealers")
      .doc(dealerId)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));

    // Update allocation Array
    const allocArray = dealer.allocationArray;
    const index = allocArray.indexOf(oldParent);
    if (index > -1) {
      allocArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    allocArray.push(newParent);

    // Update allocationKey
    const newAllocationKey = allocArray.join("-");

    await db.collection("dealer_changes").add({
      timestamp: new Date(),
      dealer: dealerId,
      description: `Manager has been changed by ${
        currentUser.contact_name || currentUser.first_name
      } ${currentUser.contact_surname || currentUser.last_name}`,
      user: currentUser.id,
    });

    // Update the dealer and user docs
    await db.collection("dealers").doc(dealerId).update({
      parentDealer: newParent,
      allocationArray: allocArray,
      allocationKey: newAllocationKey,
    });
    await db.collection("users").doc(dealer.id).update({
      parentDealer: newParent,
      allocationArray: allocArray,
      allocationKey: newAllocationKey,
    });
  }

  function GetDealerChangeLog(dealerId) {
    const [events, setEvents] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("dealer_changes")
        .where("dealer", "==", dealerId)
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvents(data);
        });
      return unsubscribe;
    }, [dealerId]);
    return events;
  }

  async function removeDealer(dealerId, dealerCode, uid, rank) {
    return new Promise((resolve, reject) => {
      const removeDealerCall = functions.httpsCallable("removeDealerCall");
      db.collection("dealers")
        .where("parentDealer", "==", uid)
        .get()
        .then(docs => {
          // No children.
          if (docs.empty) {
            if (dealerCode === "") {
              removeDealerCall({
                dealerId,
                dealerCode,
                removedBy: currentUser.id,
              })
                .then(result => {
                  if (result.data === "Success") {
                    resolve("Success");
                  } else {
                    resolve(result.data);
                  }
                })
                .catch(err => {
                  reject(err.message);
                });
            } else {
              db.collection("dealers")
                .where("dealerCode", "==", dealerCode)
                .get()
                .then(docs2 => {
                  if (docs2.empty) {
                    reject(
                      "The dealer that you are trying to merge into does not exist."
                    );
                  } else {
                    const rankOfDealerBeingMergedInto =
                      docs2.docs[0].data().rank;

                    if (rankOfDealerBeingMergedInto > rank) {
                      reject(
                        // eslint-disable-next-line max-len
                        "The dealer that you are trying to merge into has a rank that is lower than the current dealer.\n\nPlease pick another dealer or adjust the level of the dealer you are trying to merge in to."
                      );
                    } else {
                      removeDealerCall({
                        dealerId,
                        dealerCode,
                        removedBy: currentUser.id,
                      })
                        .then(result => {
                          if (result.data === "Success") {
                            resolve("Success");
                          } else {
                            resolve(result.data);
                          }
                        })
                        .catch(err => {
                          reject(err.message);
                        });
                    }
                  }
                });
            }
          } else {
            // Has children
            const childrenArray = [];
            docs.docs.forEach((dealerItem, index) => {
              childrenArray.push(dealerItem.data());

              if (index + 1 === docs.docs.length) {
                const concatenatedNames = Object.values(childrenArray)
                  .map(
                    childItem =>
                      `${childItem.dealerCode}: ${childItem.contactName} ${childItem.contactSurname}`
                  )
                  .join(", ");

                reject(
                  // eslint-disable-next-line max-len
                  `You are attempting to delete an agent that is a parent agent to one or more child agents.\n\nPlease correct the children accounts first by linking them to someone in a higher position than their own level before completing the deletion process.\n\nThe agents that are influenced by this are the following:\n\n${concatenatedNames}`
                );
              }
            });
          }
        });
    });
  }

  async function getRecentConsignments(uid) {
    return await db
      .collection("consignments")
      .where("dealerUID", "==", uid)
      .orderBy("time", "desc")
      .limit(10)
      .get()
      .then(doc => {
        const docs = [];
        doc.docs.forEach(item => {
          docs.push(item.data());
        });
        return docs;
      });
  }

  function updateOperatorID(dealerUID, operatorID) {
    return new Promise((resolve, reject) => {
      db.collection("dealers")
        .doc(dealerUID)
        .update({
          operatorId: operatorID,
        })
        .then(() => {
          resolve("Done");
        })
        .catch(err => {
          reject(err.message);
        });
    });
  }

  async function getAllAlerts(uid) {
    return await db
      .collection("globalAlerts")
      .where("createdBy", "==", uid)
      .get()
      .then(doc => {
        const alertArray = [];

        doc.docs.forEach(alertItem => {
          alertArray.push(alertItem.data());
        });

        // get unique list items based on alertId
        // Create a Map to store unique items based on alertId
        const uniqueItemsMap = new Map();

        // Iterate through the jsonData array
        alertArray.forEach(item => {
          // Use the alertId as the key in the Map to store only one item per alertId
          uniqueItemsMap.set(item.newAlertId, item);
        });

        // Convert the Map values back to an array
        const uniqueItemsArray = Array.from(uniqueItemsMap.values());

        return uniqueItemsArray;
      });
  }

  function getSingleAlert(id) {
    return new Promise((res, rej) => {
      db.collection("globalAlerts")
        .where("newAlertId", "==", id)
        .limit(1)
        .get()
        .then(doc => {
          res(doc.docs[0].data());
        })
        .catch(err => {
          rej(err.message);
        });
    });
  }

  function getAlerts() {
    return new Promise((resolve, reject) => {
      db.collection("globalAlerts")
        .where("active", "==", true)
        .get()
        .then(doc => {
          if (doc.empty) {
            resolve([]);
          } else {
            const alert = doc.docs[0].data();
            alert.id = doc.docs[0].id;
            resolve(alert);
          }
        })
        .catch(err => {
          reject(err.message);
        });
    });
  }

  function getActiveAlerts(uid) {
    return new Promise((resolve, reject) => {
      db.collection("globalAlerts")
        .where("active", "==", true)
        .where("uid", "==", uid)
        .get()
        .then(doc => {
          if (doc.empty) {
            resolve([]);
          } else {
            const alertArray = doc.docs.map(item => {
              const data = item.data();
              data.id = item.id;
              return data;
            });
            resolve(alertArray);
          }
        })
        .catch(err => {
          reject(err.message);
        });
    });
  }

  async function createGlobalAlert(alert, uid, alertId) {
    return new Promise(async resolve => {
      const suppliersArray = [];

      alert.supplier.forEach(supplier => {
        suppliersArray.push(supplier.value);
      });

      const productTypeArray = [];

      alert.productType.forEach(productType => {
        productTypeArray.push(productType.value);
      });

      const levelArray = [];

      alert.agentLevel.forEach(level => {
        levelArray.push(level.value);
      });

      if (alertId) {
        const alertsCollection = db.collection("globalAlerts");
        alertsCollection
          .where("newAlertId", "==", alertId)
          .get()
          .then(doc => {
            if (doc.empty) {
              resolve({ success: false, message: "No alert found to edit." });
            } else {
              doc.docs.forEach((item, index) => {
                alertsCollection
                  .doc(item.id)
                  .update({
                    active: alert.active,
                    images: alert.images,
                    title: alert.title,
                    description: alert.description,
                    read: false,
                    updatedTimestamp: new Date(),
                    updatedBy: uid,
                  })
                  .then(() => {
                    if (index + 1 === doc.docs.length) {
                      resolve({ success: true, message: "Alert Updated" });
                    }
                  });
              });
            }
          });
      } else {
        const newAlertId = nanoId();

        API.getDownstreamWithParams({
          uid,
          selectedSuppliers: suppliersArray,
          selectedProductTypes: productTypeArray,
          levels: levelArray,
        })
          .then(result => {
            if (result.data.length === 0) {
              resolve({ success: false, message: "No dealers found." });
            } else {
              result.data.forEach((doc, index) => {
                db.collection("globalAlerts")
                  .add({
                    ...alert,
                    uid: doc.dealer_uid,
                    read: false,
                    createdBy: uid,
                    timestamp: new Date(),
                    newAlertId,
                  })
                  .then(() => {
                    if (index + 1 === result.data.length) {
                      resolve({ success: true, message: "Alert added" });
                    }
                  })
                  .catch(() => {
                    resolve({
                      success: false,
                      message: "Error. Please contact support.",
                    });
                  });
              });
            }
          })
          .catch(() => {
            resolve({
              success: false,
              message: "Error. Please contact support.",
            });
          });
      }
    });
  }

  // acknowledgeAlert

  function acknowledgeAlert(id) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      db.collection("globalAlerts")
        .doc(id)
        .update({
          read: true,
          seenTime: new Date(),
        })
        .then(() => {
          resolve("Done");
        });
    });
  }

  function deleteVideo(videoId) {
    return db.collection("training_videos").doc(videoId).delete();
  }

  function getAvailableServices() {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      db.collection("availableServices")
        .get()
        .then(docs => {
          const serviceArray = [];

          if (docs.empty) {
            resolve([]);
          } else {
            docs.docs.forEach((item, index) => {
              const service = item.data();

              service.id = item.id;

              serviceArray.push(service);
              if (index + 1 === docs.docs.length) {
                resolve(serviceArray);
              }
            });
          }
        });
    });
  }

  function removeService(service) {
    return new Promise((resolve, reject) => {
      db.collection("availableServices")
        .doc(service)
        .delete()
        .then(() => {
          resolve("Done");
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  function addService(service) {
    let serviceValue = service.replace(/\s/g, "_");

    serviceValue = serviceValue.toLowerCase();

    return new Promise((resolve, reject) => {
      db.collection("availableServices")
        .add({
          value: serviceValue,
          label: service,
        })
        .then(() => {
          resolve("Done");
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async function bulkUpdateDealers(dealerArray, data) {
    const batch = db.batch();

    dealerArray.map(d => {
      const dbRef = db.collection("dealers").doc(d.id);
      batch.update(dbRef, {
        ...data,
      });
    });

    return await batch.commit();
  }

  function GetLevels() {
    const [levels, setLevels] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("dealer_levels")
        .orderBy("level")
        .onSnapshot(snapshot => {
          const levelList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().levelName,
            value: doc.data().level,
          }));
          setLevels(levelList);
        });
      return unsubscribe;
    }, []);
    return levels;
  }

  async function getLeaders(month, supplier) {
    let query = db
      .collection("leaderboard")
      .where("leaderboardDate", "==", month);

    if (supplier === "all") {
      query = query.where("supplier", "in", [
        "sadv",
        "herotel",
        "netninenine",
        "kuuh",
        "metro_fibre",
      ]);
    } else {
      query = query.where("supplier", "==", supplier);
    }

    const snap = await query.get();
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
  }
  async function getLeaderboardIndex(leaderboardDate, selectedOption) {
    return await db
      .collection("leaderboard_index")
      .where("leaderboardDate", "==", leaderboardDate)
      .where(
        "supplier",
        "==",
        selectedOption === "all" ? "overall" : selectedOption
      )
      .get()
      .then(snap =>
        snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
  }

  async function uploadGenericFibreCsv(records) {
    // eslint-disable-next-line no-async-promise-executor, no-unused-vars
    return new Promise(async (res, rej) => {
      const csv = Papa.unparse(records, { download: true });
      const file = new Blob([csv], { type: "text/csv" });
      const randomString = Math.random().toString(36).slice(-8);
      const fileName = `${randomString}.csv`;
      const storageRef = storage
        .ref("generic_fibre_csv_imports")
        .child(fileName);
      const uploadTask = storageRef.put(file);
      await uploadTask.on(
        "state_changed",
        snapshot => {
          // eslint-disable-next-line no-unused-vars
          const upProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.error(error, "ERROR");
          // eslint-disable-next-line prefer-promise-reject-errors
          rej({
            message: `Error uploading file to cloud storage: ${error}`,
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async url => {
            res(url);
          });
        }
      );
    });
  }

  async function uploadSadvAgentsCsv(records) {
    // eslint-disable-next-line no-async-promise-executor, no-unused-vars
    return new Promise(async (res, rej) => {
      const csv = Papa.unparse(records, { download: true });
      const file = new Blob([csv], { type: "text/csv" });
      const randomString = Math.random().toString(36).slice(-8);
      const fileName = `${randomString}.csv`;
      const storageRef = storage.ref("sadv_agent_imports").child(fileName);
      const uploadTask = storageRef.put(file);
      await uploadTask.on(
        "state_changed",
        snapshot => {
          // eslint-disable-next-line no-unused-vars
          const upProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.error(error, "ERROR");
          // eslint-disable-next-line prefer-promise-reject-errors
          rej({
            message: `Error uploading file to cloud storage: ${error}`,
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async url => {
            res(url);
          });
        }
      );
    });
  }

  function addSupplier(data, id) {
    return db
      .collection("suppliers")
      .doc(id)
      .set({
        ...data,
      });
  }

  function getProducts() {
    const [Products, setProducts] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("products").onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(data);
      });
      return unsubscribe;
    }, []);
    return Products;
  }

  function getProductsBySupplier(supplierId) {
    const [Products, setProducts] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("products")
        .where("SupplierNumber", "==", supplierId)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProducts(data);
        });
      return unsubscribe;
    }, []);
    return Products;
  }

  function getSupplier(id) {
    const [suppliers, setSuppliers] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("suppliers")
        .where("supplierId", "==", id)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSuppliers(data);
        });
      return unsubscribe;
    }, []);
    return suppliers;
  }

  function getProduct(id) {
    const [product, setProduct] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("products")
        .where("ProductNumber", "==", id)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProduct(data);
        });
      return unsubscribe;
    }, []);
    return product;
  }
  const getAgentsByService = service => {
    return new Promise((resolve, reject) => {
      db.collection("dealers")
        .where("selectedSuppliers", "array-contains", service)
        .get()
        .then(docs => {
          const dealerArray = [];

          if (docs.empty) {
            resolve([]);
          } else {
            docs.docs.forEach((item, index) => {
              dealerArray.push({
                label: `${item.data().dealerCode}: ${item.data().contactName} ${
                  item.data().contactSurname
                }`,
                value: item.data().dealerCode,
              });

              if (index + 1 === docs.docs.length) {
                resolve(dealerArray);
              }
            });
          }
        })
        .catch(err => {
          reject(err.message);
        });
    });
  };

  const toggleGrowthLeader = (uid, state) => {
    return new Promise((resolve, reject) => {
      const time = moment().format("X");
      if (state) {
        db.collection("dealers")
          .doc(uid)
          .update({
            growthLeader: true,
            growthLeaderTime: time,
          })
          .then(() => {
            resolve(time);
          })
          .catch(err => {
            reject(err.message);
          });
      } else {
        db.collection("dealers")
          .doc(uid)
          .update({
            growthLeader: false,
            growthLeaderTime: null,
          })
          .then(() => {
            resolve(null);
          });
      }
    });
  };

  async function GetSalesLeads(
    supplier,
    startDate,
    endDate,
    dealerCode,
    options = undefined
  ) {
    let query = db.collection("ussdSessions");

    query = query.where("flow", "==", "logSale");

    if (dealerCode !== undefined) {
      query = query.where("dealerCode", "==", dealerCode);
    }

    if (supplier !== "all") {
      query = query.where("supplier", "==", supplier);
    }

    if (options && options.me) {
      query = query.where("lastUpdatedByCode", "==", currentUser.dealerCode);
    }

    if (options && options.open) {
      query = query.where("status", "==", "Open");
    }

    if (options && options.inProgress) {
      query = query.where("status", "==", "In Progress");
    }
    if (options && options.closed) {
      query = query.where("status", "==", "Closed");
    }

    if (options && options.updatedToday) {
      query = query.where("lastUpdatedAt", "==", moment().format("YYYY-MM-DD"));
    }

    // if (options && options.notUpdatedToday) {
    //   query = query.where("lastUpdatedAt", "!=", moment().format("YYYY/MM/DD"));
    // }

    if (options && options.ussdOnly) {
      query = query
        .where("flow", "==", "logSale")
        .where("complete", "==", true);
    }

    const startDate1 = new Date(startDate);
    const endDate1 = new Date(endDate);

    const startDateTimestamp = parseInt(moment(startDate1).format("X"));
    const endDateTimestamp = parseInt(moment(endDate1).format("X")) + 86399;

    const snapshot = await query
      .where("dateSubmitted", ">=", startDateTimestamp)
      .where("dateSubmitted", "<=", endDateTimestamp)
      .get();

    const leadsArray = snapshot.docs.map(doc => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });

    return leadsArray;
  }

  function addSalesLead(data) {
    if (!data.date) {
      throw new Error("Date property is missing in the data object");
    }

    // Convert the date string to a JavaScript Date object
    const dateObject = new Date(data.date);

    return db
      .collection("ussdSessions")
      .doc()
      .set({
        ...data,
        date: dateObject,
        flow: "logSale",
      });
  }

  function EditSalesLead(data, id) {
    return db
      .collection("ussdSessions")
      .doc(id)
      .set({
        ...data,
      });
  }

  async function GetSalesLead(id) {
    return await db
      .collection("ussdSessions")
      .doc(id)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
  }
  function removeLead(id) {
    return new Promise((resolve, reject) => {
      db.collection("ussdSessions")
        .doc(id)
        .delete()
        .then(() => {
          resolve("Done");
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    updateDealerPhoneArray,
    GetAllUsers,
    changeLevel,
    getDealerName,
    changeParent,
    GetDealerChangeLog,
    removeDealer,
    getRecentConsignments,
    updateOperatorID,
    getAllAlerts,
    getAlerts,
    createGlobalAlert,
    acknowledgeAlert,
    deleteVideo,
    getSingleAlert,
    getAvailableServices,
    removeService,
    addService,
    bulkUpdateDealers,
    GetLevels,
    getLeaders,
    uploadSadvAgentsCsv,
    uploadGenericFibreCsv,
    addSupplier,
    getSupplier,
    getProducts,
    getProductsBySupplier,
    getProduct,
    getAgentsByService,
    toggleGrowthLeader,
    getLeaderboardIndex,
    GetSalesLeads,
    addSalesLead,
    EditSalesLead,
    GetSalesLead,
    removeLead,
    getActiveAlerts,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
