import React, { useEffect, useState } from "react";

import { DataGrid } from "@material-ui/data-grid";
import {
  Grid,
  // IconButton,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

import { supabase } from "../../../../../supabase";

import { useAuth } from "../../../../../contexts/AuthContext";

import ModalCard from "../../../../shared/ModalCard";
import ViewLead from "../../../../admin-section/admin-sales-leads/ViewLead";
// import AddLeadModal from "./AddLeadModal";

import sqlToStandardDate from "../../../../shared/functions/sqlToStandardDate";

export default function SalesAssist({ Supplier }) {
  // Get the current authenticated user
  const { currentUser } = useAuth();

  // State to manage loading status
  const [loading, setLoading] = useState(false);

  // State to store the list of leads
  const [leads, setLeads] = useState([]);

  // State to manage filter options for displaying leads
  const [displayFilters, setDisplayFilters] = useState({
    open: true,
    inProgress: true,
    closed: false,
  });

  // State to manage the modal for viewing a lead
  const [viewLead, setViewLead] = useState({
    isOpen: false,
    id: null,
  });

  // Function to handle viewing a lead
  const handleViewLead = leadId => {
    setViewLead({ id: leadId, isOpen: true });
  };

  // Function to close the view lead modal
  const closeViewLead = () => setViewLead({ id: null, isOpen: false });

  // Function to fetch leads data from the database
  const getData = async () => {
    setLoading(true); // Set loading to true before fetching data

    const { data: salesLeads, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .select("*")
      .eq("dealer_code", currentUser.dealer_code) // Filter by the current user's dealer code
      .eq("supplier", Supplier) // Filter by the supplier
      .order("date_submitted", { ascending: false }); // Order by date submitted, newest first

    if (error) {
      console.error(error); // Log any errors
    } else setLeads(salesLeads); // Update leads state with the fetched data
    setLoading(false); // Set loading to false after fetching data
  };

  // Function to handle changes to the filter options
  const handleClick = event => {
    const { name } = event.target;
    setDisplayFilters(old => ({
      ...old,
      [name]: !old[name],
    }));
  };

  // Filter leads based on the selected filter options
  const openFilter = displayFilters.open ? "Open" : undefined;
  const inProgressFilter = displayFilters.inProgress
    ? "In Progress"
    : undefined;
  const closedFilter = displayFilters.closed ? "Closed" : undefined;

  const displayLeads = leads?.filter(
    lead =>
      lead.status === openFilter ||
      lead.status === inProgressFilter ||
      lead.status === closedFilter ||
      lead.status === null
  );

  // Columns configuration for the DataGrid
  const columns = [
    {
      field: "View/Upload",
      width: 120,
      renderCell: params => (
        <Grid spacing={1} container direction="row">
          <Grid item xs={6}>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => handleViewLead(params.id)}
            >
              <FontAwesomeIcon icon={faClipboardList} />
            </Button>
          </Grid>
        </Grid>
      ),
    },
    { field: "date", headerName: "Date", width: 110 },
    { field: "ref_number", headerName: "Ref Number", width: 170 },
    { field: "category", headerName: "Category", width: 170 },
    { field: "customer_name", headerName: "Customer Name", width: 180 },
    { field: "address", headerName: "Address", width: 220 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 180,
    },
    { field: "alternative_number", headerName: "WhatsApp Number", width: 200 },
    { field: "platform", headerName: "Platform", width: 140 },
    { field: "status", headerName: "Status", width: 180 },
    { field: "status_feedback", headerName: "Status Feedback", width: 250 },
    { field: "note", headerName: "Feedback", width: 250 },
  ];

  // Transform the leads data into rows for the DataGrid
  const rows = displayLeads?.map(row => {
    const statusFeedback =
      row.status === "In Progress"
        ? row.salesLeadInProgressStatus
        : row.salesLeadClosedStatus;

    const date = sqlToStandardDate(row.date_submitted); // Convert SQL date to standard date format

    return {
      id: row.id,
      date,
      agent: row.dealer_code,
      address: row.address,
      alternative_number: row.alternative_number,
      phone_number: row.phone_number,
      customer_name: row.customer_name,
      ref_number: row.ref_number,
      agreed_to_terms: row.agreed_to_terms,
      supplier: row.supplier,
      platform: row.platform !== "App" ? "ussd" : row.platform,
      note: row.note,
      category: row.category,
      status: row.status,
      status_feedback: statusFeedback,
      supporting_document_ref: row.supporting_document_ref,
    };
  });

  // Fetch leads data when the component mounts or when the Supplier prop changes
  useEffect(() => {
    getData();
  }, [Supplier]);

  return (
    <Grid spacing={3} container direction="column">
      {/* Alert to notify users about ticket creation feature being down */}
      <Alert severity="error">
        The ticket creation feature on sales assist is currently down. You will
        not be able to add new tickets on sales assist. You can still access and
        view your existing tickets and feedback.{" "}
      </Alert>
      <Grid item container row>
        <Grid item md={6}>
          {/* Checkbox filters for displaying leads based on their status */}
          <FormGroup row>
            <FormControlLabel
              label="Open"
              control={
                <Checkbox
                  color="primary"
                  checked={openFilter}
                  onClick={handleClick}
                  name="open"
                />
              }
            />
            <FormControlLabel
              label="In Progress"
              control={
                <Checkbox
                  color="primary"
                  checked={inProgressFilter}
                  onClick={handleClick}
                  name="inProgress"
                />
              }
            />
            <FormControlLabel
              label="Closed"
              control={
                <Checkbox
                  color="primary"
                  checked={closedFilter}
                  onClick={handleClick}
                  name="closed"
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item container justifyContent="flex-end" md={6}>
          {/* Placeholder for a button to add a new lead */}
          {/* <IconButton
            color="primary"
            style={{ marginTop: -10 }}
            onClick={toggleAddLeadModal}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton> */}
        </Grid>
      </Grid>
      <Grid item style={{ height: 500 }}>
        {/* DataGrid to display the leads */}
        <DataGrid rows={rows} columns={columns} loading={loading} />
      </Grid>
      {/* Modal for viewing a lead */}
      <ModalCard
        open={viewLead.isOpen}
        onClose={closeViewLead}
        closeButtonText="Close"
      >
        <ViewLead leadId={viewLead.id} />
      </ModalCard>
      {/* Placeholder for a modal to add a new lead */}
      {/* <ModalCard
        open={addLeadModal}
        onClose={toggleAddLeadModal}
        closeButtonText="Close"
      >
        <AddLeadModal supplier={Supplier} onClose={toggleAddLeadModal} />
      </ModalCard> */}
    </Grid>
  );
}
