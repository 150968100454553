import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { useCompany } from "../../../contexts/CompanyContext";

export default function AdminSuppliersList() {
  const { suppliers } = useCompany();

  return (
    <div>
      <Grid container xs={12} spacing={1}>
        {suppliers.length === 0 && <p>There are no suppliers to show.</p>}
        {suppliers.map(supplier => (
          <Grid item xs={12} md={6} key={supplier.id}>
            <Link to={`/admin/Supplier/${supplier.id}`}>
              <Card>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: 120,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: 100 }}>
                        <Typography align="center">
                          <img
                            src={supplier.logo}
                            alt={supplier.logo}
                            width={supplier.logo_size}
                          />
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {supplier.supplier}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color={
                          supplier.supplier_status === "Active"
                            ? "primary"
                            : "error"
                        }
                      >
                        {supplier.supplier_status}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
