import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D3D3D3",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3D7155",
  },
}));

export default function DesktopUserCard({
  rank,
  outlined,
  name,
  percentage,
  fiber,
}) {
  return (
    <Card
      style={{
        outlineStyle: outlined,
        outlineColor: "#267353",
        outlineWidth: "3px",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid container xs={11} direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h3" align="left">
                {rank}
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid>
                <Typography
                  style={{ textTransform: "capitalize" }}
                  variant="h6"
                >
                  {name}
                </Typography>
                <BorderLinearProgress
                  variant="determinate"
                  value={percentage}
                />
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item xs={1} style={{ marginTop: "15px" }}>
            <Grid container direction="row" justify="center">
              <Typography align="right" variant="h4">
                <Public fontSize="inherit" color="primary" />
              </Typography>

              <Typography variant="h6" align="left">
                :&nbsp;{fiber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
