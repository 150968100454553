/* eslint-disable prefer-template */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Chip } from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import DealersDataGrid from "./data-grid/DealersDataGrid";
import { useSB } from "../../contexts/SupabaseContext";
import { useCompany } from "../../contexts/CompanyContext";
import sqlToStandardDate from "../shared/functions/sqlToStandardDate";
import getStatusName from "../shared/functions/getStatusName";

export default function DealersTable({ rank }) {
  const [dealers, setDealers] = useState([]);
  const { GetTableWhere, updateRow } = useSB();
  const { lifecycleStatuses } = useCompany();

  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetTableWhere(
          "dealers",
          ["rank", "eq", rank],
          ["status", "in", [1, 2, 3]]
        );
        setDealers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [rank, state]);

  async function bulkUpdateDealers(dealerArray, data) {
    try {
      await Promise.all(
        dealerArray.map(async d => {
          await updateRow("dealers", d.id, data);
        })
      );
    } catch (error) {
      console.error("Error updating dealers:", error);
    }
  }

  const getParentDealer = parentId => {
    const parent = dealers.find(item => item.id === parentId);
    const parentName = parent
      ? `${parent.contactName} ${parent.contactSurname}`
      : "";
    return parentName;
  };

  const rows =
    dealers &&
    dealers.map((dealer, i) => ({
      ...dealer,
      id: dealer.id,
      name: `${dealer.contact_name} ${dealer.contact_surname}`,
      phone: "0" + dealer.phone.slice(3),
      parentDealer: getParentDealer(dealer.parent_dealer),
      // eslint-disable-next-line no-unneeded-ternary
      active: i === 3 ? false : true, // TODO: Make this real
    }));

  const columns = [
    { field: "dealer_code", headerName: "Code", width: 100 },
    { field: "name", headerName: "Name", width: 210 },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 140,
      renderCell: params => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ textTransform: "capitalize" }}
          >
            <Chip
              color={params.row.status === 2 ? "primary" : "secondary"}
              label={getStatusName(params.row.status, lifecycleStatuses)}
              size="small"
            />
          </div>
        );
      },
    },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "email", headerName: "Email", width: 210 },
    { field: "parent_dealer", headerName: "Parent", width: 180 },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      renderCell: params => (
        <div className="d-flex justify-content-between align-items-center">
          <p>{sqlToStandardDate(params.row.created_at)}</p>
        </div>
      ),
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      width: 120,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/dealer-detail/${params.row.id}`}>
            <Button startIcon={<Person style={{ opacity: 0.5 }} />}>
              Details
            </Button>
          </Link>
        </div>
      ),
    },
    {
      field: "downstream",
      headerName: "Downstream",
      sortable: false,
      width: 140,
      // eslint-disable-next-line no-confusing-arrow
      renderCell: params =>
        params.row.rank > 7 ? null : (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/child-dealer-downstream/${params.row.id}`}>
              <Button startIcon={<People style={{ opacity: 0.5 }} />}>
                Downstream
              </Button>
            </Link>
          </div>
        ),
    },
  ];

  const [selectedRows, setSelectedRows] = useState();

  const handleSelect = selection => {
    if (selection?.length >= 500) {
      // eslint-disable-next-line nonblock-statement-body-position, no-alert
      window.alert("Maximum of 500 rows selectable.");
    } else setSelectedRows(selection);
  };

  const handleBulkUpdate = async data => {
    setState({
      loading: true,
      error: null,
    });
    try {
      await bulkUpdateDealers(selectedRows, data);
      setState({
        ...state,
        loading: false,
      });
    } catch (err) {
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <DealersDataGrid
      rowsData={rows}
      columns={columns}
      loading={dealers.length === 0}
      handleSelect={handleSelect}
      handleUpdate={handleBulkUpdate}
      updateRunning={state.loading}
    />
  );
}
