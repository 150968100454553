import { IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";
import { useSB } from "../../../contexts/SupabaseContext";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";

export default function Approvals(props) {
  const { GetTableWhere } = useSB();
  const [dealers, setDealers] = useState([]);
  const { type } = props;

  useEffect(() => {
    GetTableWhere("dealers", ["status", "eq", type]).then(result => {
      setDealers(result);
    });
  }, []);

  const rows =
    dealers &&
    dealers.map(r => ({
      id: dealers.id,
      ...r,
      date: sqlToStandardDate(r.created_at),
      submitted_by_name: r.submitted_by.name,
    }));

  const columns = [
    {
      field: "action",
      headerName: "Detail",
      width: 80,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/approval-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        </div>
      ),
    },
    { field: "date", headerName: "Date", width: 200 },
    { field: "contact_name", headerName: "Name", width: 200 },
    { field: "contact_surname", headerName: "Surname", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "submitted_by_name", headerName: "Submitted By", width: 200 },
  ];

  return <SimDataGrid data={rows} columns={columns} />;
}
