// #useAuthUpdated

/* eslint-disable no-unreachable */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Checkbox,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useAuth } from "../../contexts/AuthContext";
import { useCompany } from "../../contexts/CompanyContext";
import {
  provinces,
  countryCodes,
  dealerIdTypes,
  banks,
  bankAccountTypes,
} from "../shared/constants";
import FileUploader from "../shared/FileUploader";
import holdIdImg from "../../img/holdId.png";
import idcloseup from "../../img/holdid2.png";
import SelectSuppliers from "./SelectSuppliers";
import { useSB } from "../../contexts/SupabaseContext";
import checkIdNumberIsUsed from "../shared/functions/checkIdNumberIsUsed";
import checkPhoneNumberIsUsed from "../shared/functions/checkPhoneNumberIsUsed";
import { useStructure } from "../../contexts/StructureContext";
import createDealerFunction from "./functions/createDealerFunction";
import snakeToCamel from "../shared/functions/snakeToCamel";

export default function CreateDealerForm(props) {
  const {
    dialogIsOpen,
    closeDialog,
    editing,
    openSnackbar,
    dealerProp,
    editingApproval,
  } = props;
  const { GetTableWhere } = useSB();
  const { productTypes, suppliers, products } = useCompany();
  const { currentUser } = useAuth();
  const [levels, setLevels] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [idNumberHelperText, setIdNumberHelperText] = useState("");
  const [idNumberInputIsInvalid, setIdNumberInputIsInvalid] = useState(false);
  const [phoneInputIsInvalid, setPhoneInputIsInvalid] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");
  const [altPhoneInputIsInvalid, setAltPhoneInputIsInvalid] = useState(false);
  const [altPhoneHelperText, setAltPhoneHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [bankAccountNumberHelperText, setBankAccountNumberHelperText] =
    useState("");
  const [bankAccountNumberIsInvalid, setBankAccountNumberIsInvalid] =
    useState(false);

  // The selected product types and suppliers for this user.
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  // Whole Dealer State
  const [dealerState, setDealerState] = useState([]);
  const { downstream } = useStructure();

  useEffect(() => {
    if (editing) {
      const transformedDealerProp = snakeToCamel(dealerProp);

      transformedDealerProp.TandCs = true;
      transformedDealerProp.dateOfBirth = moment(
        transformedDealerProp.dateOfBirth
      ).format("X");
      transformedDealerProp.passportExpiryDate = moment(
        transformedDealerProp.passportExpiryDate
      ).format("X");
      transformedDealerProp.passportIssueDate = moment(
        transformedDealerProp.passportIssueDate
      ).format("X");

      setDealerState(transformedDealerProp);

      setSelectedProductTypes(transformedDealerProp.selectedProductTypes);
      setSelectedSuppliers(transformedDealerProp.selectedSuppliers);
    } else {
      setDealerState({ ...dealerState, gender: "M", paymentType: "bank" });
    }
  }, []);

  useEffect(() => {
    // Get Levels Below
    GetTableWhere("dealer_levels", ["level", "gt", currentUser.rank]).then(
      data => {
        setLevels(
          data.map(item => ({
            label: item.level_name,
            value: item,
          }))
        );
      }
    );
  }, []);

  function parseDateString(dateString) {
    // Get current year
    const currentYear = new Date().getFullYear();
    const currentYearPrefix = Math.floor(currentYear / 100) * 100;

    // Extract year, month, and day from the string
    const yearPart = dateString.substring(0, 2);
    const monthPart = dateString.substring(2, 4);
    const dayPart = dateString.substring(4, 6);

    // Determine the correct year prefix
    let yearPrefix;

    const year = parseInt(yearPart, 10);

    if (year > currentYear % 100) {
      yearPrefix = currentYearPrefix - 100; // Use 19xx prefix
    } else {
      yearPrefix = currentYearPrefix; // Use 20xx prefix
    }

    // Construct the full year
    const fullYear = yearPrefix + year;

    // Create a Date value
    // Add 6 hours to account for time zone differences.
    const date = moment(
      `${fullYear}/${parseInt(monthPart, 10)}/${parseInt(dayPart, 10)}`
    )
      .add(6, "hours")
      .format("X");

    return date;
  }

  const checkIdNumberVsDOB = async (idNumber, dateOfBirth, idType) => {
    if (idType === "RSAID") {
      // Unix timestamp of the DOB.
      let idNumberDate = parseDateString(idNumber);
      // Add 6 hours to account for time zone differences.
      let birthDate = moment.unix(dateOfBirth).add(6, "hours").format("X");

      idNumberDate = moment.unix(idNumberDate);
      birthDate = moment.unix(birthDate);

      const isSameDay = idNumberDate.isSame(birthDate, "day");

      return isSameDay;
    }
    // Ignore if not RSA ID.
    return true;
  };

  const handleSubmit = async () => {
    setError("");
    setLoading(true);

    const idNumberValidationResult = await handleContactIdNumberBlur(
      dealerState.contactIdNo
    );

    if (!idNumberValidationResult) {
      setError("ID Number is invalid.");
      setLoading(false);
      return;
    }

    const idNumberMatchesDOB = await checkIdNumberVsDOB(
      dealerState.contactIdNo,
      dealerState.dateOfBirth,
      dealerState.idType
    );

    if (!idNumberMatchesDOB) {
      setError("ID Number Does Not Match Date of Birth.");
      setLoading(false);
      return;
    }

    if (bankAccountNumberIsInvalid) {
      setError(bankAccountNumberHelperText);
      setLoading(false);
      return;
    }

    if (!selectedProductTypes.length) {
      setError("Please select product types for the user.");
      setLoading(false);
      return;
    }

    if (!selectedSuppliers.length) {
      setError("Please select at least one supplier for the user.");
      setLoading(false);
      return;
    }

    // Transforms:
    const dealerObjectToSubmit = {
      city: dealerState.city,
      contactIdNo: dealerState.contactIdNo,
      contactName: dealerState.contactName,
      contactSurname: dealerState.contactSurname,
      country: dealerState.country,
      dealerName: !dealerState.dealerName?.length
        ? `${dealerState.contactName} ${dealerState.contactSurname}`
        : dealerState.dealerName,
      dealerType: dealerState.dealerType,
      email: dealerState.email || null,
      gender: dealerState.gender,
      eWalletLink: dealerState.eWalletLink,
      paymentType: dealerState.paymentType,
      bankAccountType: dealerState.bankAccountType || null,
      branchCode: dealerState.branchCode || null,
      bankAccountNumber: dealerState.bankAccountNumber
        ? String(dealerState.bankAccountNumber)
        : null,
      bank: dealerState.bank || null,
      idPhoto: dealerState.idPhoto,
      idType: dealerState.idType,
      nationality: dealerState.nationality,
      operatorId: dealerState.operatorId || null,
      selectedSuppliers,
      selectedProductTypes,
      dateOfBirth: moment.unix(dealerState.dateOfBirth).isValid()
        ? parseInt(dealerState.dateOfBirth)
        : null,
      parentDealer: dealerState.parentDealer || currentUser.dealer_code,
      passportExpiryDate:
        dealerState.passportExpiryDate &&
        moment.unix(dealerState.passportExpiryDate).isValid()
          ? parseInt(dealerState.passportExpiryDate)
          : null,
      passportIssueDate:
        dealerState.passportIssueDate &&
        moment.unix(dealerState.passportIssueDate).isValid()
          ? parseInt(dealerState.passportIssueDate)
          : null,
      personPhoto: dealerState.personPhoto,
      phone: dealerState.phone,
      altPhone: dealerState.altPhone?.length ? dealerState.altPhone : null,
      postalCode: dealerState.postalCode,
      province: dealerState.province,
      rank: dealerState.rank,
      street: dealerState.street,
      suburb: dealerState.suburb,
      submittedBy: {
        uid: currentUser.id,
        name: `${currentUser.contact_name} ${currentUser.contact_surname}`,
      },
      submitterId: currentUser.id,
      TandCs: dealerState.TandCs,
      id: dealerState.id,
    };

    if (dealerObjectToSubmit.paymentType === "bank") {
      if (!dealerObjectToSubmit.bank?.length) {
        setError("Please select a bank");
        setLoading(false);
        return;
      }
      if (!dealerObjectToSubmit.bankAccountNumber?.length) {
        setError("Please add a bank account number");
        setLoading(false);
        return;
      }
      if (!dealerObjectToSubmit.bankAccountType) {
        setError("Please select a bank account type");
        setLoading(false);
        return;
      }
    }

    if (!dealerState.TandCs) {
      setError("Please check RICA And POPIA Training");
      setLoading(false);
      return;
    }

    if (!editing) {
      if (!dealerState.phone || dealerState.phone[0] !== "0") {
        setError("Phone number does not start with 0");
        setLoading(false);
        // eslint-disable-next-line no-useless-return
        return;
      }

      if (dealerState.altPhone && dealerState.altPhone[0] !== "0") {
        setError("Alternate number does not start with 0");
        setLoading(false);
        // eslint-disable-next-line no-useless-return
        return;
      }
    }

    createDealerFunction(dealerObjectToSubmit, editing)
      .then(() => {
        console.log("Dealer created successfully");
        if (!editing) {
          openSnackbar();
        }
        setTimeout(() => {
          setLoading(false);
          setDealerState([]);
          closeDialog();
        }, 500);
      })
      .catch(err2 => {
        setError(err2.message);
        setLoading(false);
      });
  };

  // Form Helpers
  const handleDealerTypeChange = async val => {
    setDealerState({
      ...dealerState,
      dealerType: val.value.level_name,
      rank: val.value.level,
    });

    let dealers = [];

    if (currentUser.rank === 1) {
      GetTableWhere("dealers", ["rank", "lt", val.value.level]).then(data => {
        dealers = data.map(item => ({
          id: item.dealer_code,
          label: `${item.dealer_code}: ${item.contact_name} ${item.contact_surname}`,
          value: item,
        }));
        setDealerList(dealers);
      });
    } else {
      // Get the downstream dealers for the current dealer, but above the selected rank
      //  val.value.level

      downstream
        .filter(agent => agent.rank < val.value.level)
        .forEach(agent => {
          dealers.push({
            id: agent.dealer_code,
            label: `${agent.dealer_code}: ${agent.contact_name} ${agent.contact_surname}`,
            value: agent,
          });
        });

      setDealerList(dealers);
    }
  };

  // Validation Functions

  const handleContactIdNumberBlur = async idNumber => {
    // Check if the idNumber is valid
    if (!idNumber || idNumber.length <= 3) {
      setIdNumberInputIsInvalid(true);
      return false;
    }

    // If editingApproval or editing is true, skip the check
    if (editingApproval || editing) {
      return true;
    }

    try {
      // Check if the idNumber is already used
      const isIdNumberUsed = await checkIdNumberIsUsed(idNumber);
      if (isIdNumberUsed) {
        setIdNumberHelperText("This ID number has already been used");
        setIdNumberInputIsInvalid(true);
        return false;
      }

      // ID number is valid and not used
      setIdNumberInputIsInvalid(false);
      return true;
    } catch (error2) {
      // Handle errors from checkIdNumberIsUsed function
      console.error("Error checking ID number:", error2);
      setIdNumberHelperText("Unable to verify ID number");
      setIdNumberInputIsInvalid(true);
      return false;
    }
  };

  const handleSetPhone = async numberLocal => {
    setDealerState({ ...dealerState, phone: numberLocal });

    setPhoneHelperText("");
    setPhoneInputIsInvalid(false);

    if (numberLocal.length === 10) {
      if (numberLocal.length > 0 && numberLocal[0] !== "0") {
        setPhoneHelperText("Phone number does not start with 0");
        setPhoneInputIsInvalid(true);
      } else {
        const formattedNumber = `+27${numberLocal.slice(1, 10)}`;
        checkPhoneNumberIsUsed(formattedNumber).then(phoneIsUsed => {
          if (phoneIsUsed) {
            setPhoneHelperText("Phone number has already been used");
            setPhoneInputIsInvalid(true);
          }
        });
      }
    }
  };

  const handlePhoneBlur = numberLocal => {
    if (numberLocal.length < 10) {
      setPhoneInputIsInvalid(true);
      if (numberLocal.length > 0) {
        setPhoneHelperText("Phone number is incomplete");
        setPhoneInputIsInvalid(true);
      }
    }
  };

  const handleSetAltPhone = async numberLocal => {
    setDealerState({ ...dealerState, altPhone: numberLocal });

    setAltPhoneHelperText("");
    setAltPhoneInputIsInvalid(false);
    if (!editingApproval || !editing) {
      if (numberLocal.length === 10) {
        if (numberLocal.length > 0 && numberLocal[0] !== "0") {
          setAltPhoneHelperText("Phone number does not start with 0");
          setAltPhoneInputIsInvalid(true);
        } else {
          const formattedNumber = `+27${numberLocal.slice(1, 10)}`;

          checkPhoneNumberIsUsed(formattedNumber).then(phoneIsUsed => {
            if (phoneIsUsed) {
              setAltPhoneHelperText("Phone number has already been used");
              setAltPhoneInputIsInvalid(true);
            }
          });
        }
      }
    }
  };

  const handleAltPhoneBlur = numberLocal => {
    if (numberLocal.length < 10 && numberLocal.length > 0) {
      setAltPhoneInputIsInvalid(true);
      if (numberLocal.length > 0) {
        setAltPhoneHelperText("Phone number is incomplete");
        setAltPhoneInputIsInvalid(true);
      }
    }
  };

  // Handles bank account numbers
  const handleSetBankAccountNumber = async numberLocal => {
    setDealerState({ ...dealerState, bankAccountNumber: numberLocal });

    const numberLocalString = numberLocal.toString();
    console.log(numberLocal, numberLocalString.length > 6);
    if (numberLocalString.length < 6) {
      setBankAccountNumberHelperText("Account number is incomplete");
      setBankAccountNumberIsInvalid(true);
    } else {
      setBankAccountNumberHelperText("");
      setBankAccountNumberIsInvalid(false);
    }
  };

  const handleTrained = () => {
    setDealerState({
      ...dealerState,
      TandCs: !dealerState.TandCs,
    });
  };

  const getIdPhoto = f => {
    setDealerState({
      ...dealerState,
      idPhoto: f.url,
    });
  };

  const getPersonPhoto = f => {
    setDealerState({
      ...dealerState,
      personPhoto: f.url,
    });
  };

  return (
    <div>
      <Dialog
        open={dialogIsOpen}
        onClose={() => {
          setDealerState([]);
          closeDialog();
        }}
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          style={{ flex: 1, backgroundColor: "#267353", color: "#FFFFFF" }}
        >
          {editing ? "Edit Team Member" : "Create Team Member"}
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={handleSubmit}>
              {!editing && (
                <div style={{ marginTop: 20 }}>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder="Level ..."
                    options={levels}
                    onChange={handleDealerTypeChange}
                    defaultValue={
                      dealerState.dealerType
                        ? {
                            label: dealerState.dealerType,
                            value: dealerState.dealerType,
                          }
                        : ""
                    }
                  />
                </div>
              )}
              {!editing && (
                <div style={styles.inputContainer}>
                  <Select
                    disabled={dealerList.length === 0}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder="Adding directly under you. Click dropdown to change parent..."
                    options={dealerList}
                    onChange={val => {
                      setDealerState({
                        ...dealerState,
                        parentDealer: val,
                      });
                    }}
                    defaultValue={
                      dealerState.parentDealer
                        ? {
                            label: dealerState.parentDealer,
                            value: dealerState.parentDealer,
                          }
                        : ""
                    }
                  />
                </div>
              )}
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  value={dealerState.contactName}
                  label="Contact name"
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      contactName: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  value={dealerState.contactSurname}
                  label="Contact surname"
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      contactSurname: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <KeyboardDatePicker
                  autoOk
                  label="Date of birth"
                  defaultValue="2010/01/01"
                  value={
                    dealerState.dateOfBirth
                      ? moment(dealerState.dateOfBirth, "X").format(
                          "YYYY/MM/DD"
                        )
                      : "2010/01/01"
                  }
                  inputVariant="outlined"
                  onChange={dateLocal => {
                    setDealerState({
                      ...dealerState,
                      dateOfBirth: parseInt(moment(dateLocal).format("X")),
                    });
                  }}
                  format="yyyy/MM/dd"
                  variant="inline"
                  required
                  fullWidth
                />
              </div>
              <div style={styles.inputContainer}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend" style={{ fontSize: ".85rem" }}>
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    required
                    aria-label="gender"
                    name="gender"
                    value={dealerState.gender}
                    onChange={e => {
                      setDealerState({
                        ...dealerState,
                        gender: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={styles.inputContainer}>
                <Select
                  xs={12}
                  required
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  options={dealerIdTypes}
                  defaultValue={dealerIdTypes.find(
                    item => item.value === dealerState.idType
                  )}
                  onChange={e => {
                    if (e.value === "id") {
                      if (
                        dealerState.passportIssueDate &&
                        dealerState.passportIssueDate.toString() ===
                          "Invalid Date"
                      ) {
                        setDealerState({
                          ...dealerState,
                          passportIssueDate: null,
                        });
                      }
                      if (
                        dealerState.passportExpiryDate &&
                        dealerState.passportExpiryDate.toString() ===
                          "Invalid Date"
                      ) {
                        setDealerState({
                          ...dealerState,
                          passportIssueDate: null,
                        });
                      }
                    }
                    setDealerState({
                      ...dealerState,
                      idType: e.value,
                    });
                  }}
                  placeholder="Select ID / Passport type *"
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  value={dealerState.contactIdNo}
                  required
                  fullWidth
                  variant="outlined"
                  label="ID / Passport number"
                  error={idNumberInputIsInvalid}
                  helperText={idNumberHelperText}
                  onBlur={e => handleContactIdNumberBlur(e.target.value)}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      contactIdNo: e.target.value,
                    });
                    setIdNumberHelperText("");
                    setIdNumberInputIsInvalid(false);
                  }}
                />
              </div>
              {dealerState.idType === "PASSPT" && (
                <>
                  <div style={styles.inputContainer}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      required
                      variant="inline"
                      format="yyyy/MM/dd"
                      defaultValue="15/04/2022"
                      inputVariant="outlined"
                      value={moment(dealerState.passportIssueDate, "X").format(
                        "YYYY/MM/DD"
                      )}
                      label="Passport issue date"
                      disabled={dealerState.idType !== "PASSPT"}
                      onChange={dateLocal => {
                        setDealerState({
                          ...dealerState,
                          passportIssueDate: parseInt(
                            moment(dateLocal).format("X")
                          ),
                        });
                      }}
                    />
                  </div>
                  <div style={styles.inputContainer}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      format="yyyy/MM/dd"
                      required
                      defaultValue="15/04/2022"
                      inputVariant="outlined"
                      value={moment(dealerState.passportExpiryDate, "X").format(
                        "YYYY/MM/DD"
                      )}
                      label="Passport expiry date"
                      onChange={dateLocal => {
                        setDealerState({
                          ...dealerState,
                          passportExpiryDate: parseInt(
                            moment(dateLocal).format("X")
                          ),
                        });
                      }}
                    />
                  </div>
                </>
              )}
              <div style={styles.inputContainer}>
                <Select
                  options={countryCodes}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  defaultValue={countryCodes.find(
                    item => item.value === dealerState.nationality
                  )}
                  onChange={v => {
                    setDealerState({
                      ...dealerState,
                      nationality: v.value,
                    });
                  }}
                  placeholder="Nationality"
                />
              </div>
              <SelectSuppliers
                products={products}
                suppliers={suppliers}
                productTypes={productTypes}
                dealerState={dealerState}
                editing={editing}
                currentUser={currentUser}
                onChangeProductTypes={val => {
                  setSelectedProductTypes(val);
                }}
                onChangeSuppliers={val => {
                  setSelectedSuppliers(val);
                }}
              />
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="RICA Online Username"
                  variant="outlined"
                  fullWidth
                  value={dealerState.operatorId}
                  onChange={e => {
                    if (e.target.value.length < 6) {
                      setDealerState({
                        ...dealerState,
                        operatorId: e.target.value.toUpperCase(),
                      });
                    }
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Trading / Business name (optional)"
                  variant="outlined"
                  fullWidth
                  value={dealerState.dealerName}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      dealerName: e.target.value,
                    });
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="email"
                  value={dealerState.email}
                  label="E-mail address"
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Phone number"
                  variant="outlined"
                  placeholder="eg: 0821234567"
                  fullWidth
                  error={phoneInputIsInvalid}
                  helperText={phoneHelperText}
                  value={dealerState.phone}
                  onChange={e => handleSetPhone(e.target.value)}
                  onBlur={e => handlePhoneBlur(e.target.value)}
                  required
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Alternate phone number"
                  variant="outlined"
                  placeholder="eg: 0821234567"
                  fullWidth
                  error={altPhoneInputIsInvalid}
                  helperText={altPhoneHelperText}
                  value={dealerState.altPhone}
                  onChange={e => handleSetAltPhone(e.target.value)}
                  onBlur={e => handleAltPhoneBlur(e.target.value)}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <Select
                  xs={12}
                  required
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={provinces}
                  defaultValue={provinces.find(
                    item => item.value === dealerState.province
                  )}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      province: e.value,
                    });
                  }}
                  placeholder="Select Province"
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Street address"
                  variant="outlined"
                  fullWidth
                  value={dealerState.street}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      street: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Suburb"
                  variant="outlined"
                  fullWidth
                  value={dealerState.suburb}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      suburb: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Postal Code"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={dealerState.postalCode}
                  onChange={e => {
                    if (e.target.value.length < 5) {
                      setDealerState({
                        ...dealerState,
                        postalCode: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={dealerState.city}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      city: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={countryCodes}
                  defaultValue={countryCodes.find(
                    item => item.value === dealerState.country
                  )}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      country: e.value,
                    });
                  }}
                  placeholder="Country of Residence"
                />
              </div>
              <div style={styles.inputContainer}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend" style={{ fontSize: ".85rem" }}>
                    Payment Type
                  </FormLabel>
                  <RadioGroup
                    row
                    required
                    aria-label="payment-type"
                    name="payment-type"
                    value={dealerState.paymentType}
                    onChange={e => {
                      setDealerState({
                        ...dealerState,
                        paymentType: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="bank"
                      control={<Radio />}
                      label="Bank"
                    />
                    <FormControlLabel
                      value="eWallet"
                      control={<Radio />}
                      label="eWallet"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {dealerState.paymentType === "bank" && (
                <>
                  <div style={styles.inputContainer}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={banks}
                      onChange={e => {
                        setDealerState({
                          ...dealerState,
                          bank: e.value,
                          branchCode: banks.filter(
                            item => item.value === e.value
                          )[0].branchCode,
                        });
                      }}
                      defaultValue={
                        dealerState.bank
                          ? {
                              label: dealerState.bank,
                              value: dealerState.bank,
                            }
                          : null
                      }
                      placeholder="Bank"
                    />
                  </div>
                  <div style={styles.inputContainer}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={bankAccountTypes}
                      onChange={e => {
                        setDealerState({
                          ...dealerState,
                          bankAccountType: e.value,
                        });
                      }}
                      defaultValue={
                        dealerState.bankAccountType
                          ? {
                              label: bankAccountTypes.filter(
                                item =>
                                  item.value ===
                                  parseInt(dealerState.bankAccountType)
                              )[0].label,
                              value: dealerState.bankAccountType,
                            }
                          : null
                      }
                      placeholder="Account Type"
                    />
                  </div>
                  <div style={styles.inputContainer}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      variant="outlined"
                      label="Account Number"
                      placeholder="Account Number"
                      helperText={bankAccountNumberHelperText}
                      error={bankAccountNumberIsInvalid}
                      value={dealerState.bankAccountNumber}
                      onChange={e => handleSetBankAccountNumber(e.target.value)}
                      //  onBlur={e => handleBankAccountNumberBlur(e.target.value)}
                      fullWidth
                      required
                      inputProps={{
                        minLength: 6,
                        maxLength: 20,
                      }}
                    />
                  </div>
                </>
              )}
              {dealerState.paymentType === "eWallet" && (
                <div style={styles.inputContainer}>
                  <Typography>
                    We will use <b>{dealerState.phone}</b> for eWallet payments.
                  </Typography>
                </div>
              )}
              {dealerState.paymentType === "eWallet" && editing && (
                <div style={styles.inputContainer}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    value={dealerState.eWalletLink}
                    label="eWallet Link"
                    variant="outlined"
                    fullWidth
                    onChange={e => {
                      setDealerState({
                        ...dealerState,
                        eWalletLink: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
              {!editing && (
                <Grid item style={{ paddingTop: 30 }}>
                  <FormControl
                    component="fieldset"
                    required
                    disabled={editing && dealerState.TandCs}
                  >
                    <FormLabel
                      component="legend"
                      style={{ fontSize: "0.85rem" }}
                    >
                      I confirm that I have trained the agent on the
                      responsibilities of a agent and the responsibilities as
                      explained in the act.
                    </FormLabel>{" "}
                    <Grid direction="row">
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/zawadi-dev.appspot.com/o/legal_documents%2FRICA_Terms_and_Conditions.pdf?alt=media&token=931b1199-bfe9-4f34-8155-403b07b81536"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        RICA
                      </a>
                      {" , "}
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/zawadi-dev.appspot.com/o/legal_documents%2FPOPIA_Zawadi_mrg.pdf?alt=media&token=cf3d8ce8-0be4-4243-aeaf-da0ca95578ab"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        POPIA
                      </a>
                    </Grid>
                    <FormControlLabel
                      control={<Checkbox required />}
                      label="I Agree"
                      name="TandCs"
                      checked={
                        dealerState.TandCs || dealerState.agreedToRicaTerms
                      }
                      onChange={() => handleTrained()}
                    />
                  </FormControl>
                </Grid>
              )}
              <div style={{ paddingTop: 20, paddingBottom: 15 }}>
                <FormLabel component="legend" style={{ fontSize: "0.85rem" }}>
                  Document Uploads:
                </FormLabel>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Card style={{ width: "100%", height: 300 }}>
                    {dealerState.idPhoto && (
                      <CardMedia
                        style={{ height: 100 }}
                        image={dealerState.idPhoto}
                      />
                    )}
                    <CardContent>
                      <FileUploader
                        sendFile={getIdPhoto}
                        label="Upload Clear Photo of ID/Passport"
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card style={{ width: "100%", height: 300 }}>
                    <CardContent>Example:</CardContent>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={idcloseup}
                        width="auto"
                        height={200}
                        alt="close up id"
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Card style={{ width: "100%", height: 300 }}>
                    {dealerState.personPhoto && (
                      <CardMedia
                        style={{ height: 100 }}
                        image={dealerState.personPhoto}
                      />
                    )}
                    <CardContent>
                      <FileUploader
                        sendFile={getPersonPhoto}
                        label="Upload Photo holding ID/Passport"
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card style={{ width: "100%", height: 300 }}>
                    <CardContent>Example:</CardContent>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={holdIdImg}
                        width="auto"
                        height={200}
                        alt="close up id"
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
        </DialogContent>

        {error && (
          <Alert
            style={{ width: "100%", marginBottom: "1rem" }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setDealerState([]);
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  inputContainer: {
    marginTop: 30,
  },
};
