import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

function SupplierTile({ supplierObject, isActive }) {
  const {
    id,
    custom_page: CustomPage,
    custom_page: CustomPageUrl,
    logo,
    logo_size: logoSize,
    supplier_status: supplierStatus,
    supplier,
  } = supplierObject;

  // Determine if link is active and if link needs to be redirected to the custom URL
  const linkTo = isActive
    ? CustomPage
      ? CustomPageUrl
      : `/Supplier/${id}`
    : "/";

  return (
    <Grid item xs={12} md={6}>
      <Link to={linkTo}>
        <Card key={id}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 120,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: 100 }}>
                  <img src={logo} alt={logo} width={logoSize} />
                </div>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                item
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {supplier}
                  </Typography>
                  <Typography
                    variant="subtitle"
                    color={isActive ? "primary" : "error"}
                  >
                    {isActive ? supplierStatus : "Not Regisetred"}
                  </Typography>
                  {/* <Typography variant="subtitle">
              Updated Until:{" "}
              {supplier.lastUpdatedUntil || "No Date"}
            </Typography> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

export default SupplierTile;
