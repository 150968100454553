import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Typography,
  Select,
  Slider,
  Snackbar,
} from "@mui/material";
import { Alert } from "@material-ui/lab";

import { Button, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  Add as AddIcon,
  Edit as EditIcon,
  ArrowBack,
} from "@material-ui/icons";
import { useCompany } from "../../../contexts/CompanyContext";

function ProductTypes() {
  const defFormValues = {
    name: "",
    is_active: true,
    max_ogr: 0,
    agent_split_t1: 0,
    agent_split_t2: 0,
    agent_split_t3: 0,
    agent_split_t4: 0,
    dealer_split_tl_t1: 0,
    dealer_split_tl_t2: 0,
    dealer_split_sm: 0,
    dealer_split_d: 0,
    dealer_split_sd: 0,
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState({});
  const [saving, setSaving] = useState(false);
  const [formValues, setFormValues] = useState(defFormValues);
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const history = useHistory();
  const { productTypes, upsertProductType } = useCompany();
  const utilisation =
    formValues.dealer_split_tl_t1 +
    formValues.dealer_split_tl_t2 +
    formValues.dealer_split_sm +
    formValues.dealer_split_d +
    formValues.dealer_split_sd;

  const handleDialogOpen = productType => {
    setSelectedProductType(productType);
    if (productType) {
      setFormValues(prevFormValues => ({ ...prevFormValues, ...productType }));
    }
    setDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };

  const handleFormValues = event => {
    const { name, value } = event.target;
    if (name === "is_active") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        is_active: !prevFormValues.is_active,
      }));
    } else {
      setFormValues(prevFormValues => ({ ...prevFormValues, [name]: value }));
    }
  };

  // Slider handler

  const handleSliders = event => {
    const { name, value } = event.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value / 100,
    }));
  };

  const sliderMarks = [
    { value: 0, label: "0%" },
    { value: 100, label: "100%" },
  ];

  const handleDialogClose = () => {
    setSelectedProductType({});
    setFormValues(defFormValues);
    setDialogOpen(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (utilisation <= 1) {
      setSaving(true);

      upsertProductType({ ...formValues });

      setSaving(false);

      handleDialogClose();
    } else setSnackBarOpen(true);
  };

  return (
    <div>
      <div>
        <Button
          startIcon={<ArrowBack />}
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Product Types
        </Typography>
        <List>
          <Grid container spacing={2} xs={12} md={8}>
            {productTypes.map(productType => (
              <Grid
                style={{ marginLeft: 20 }}
                item
                xs={12}
                md={8}
                key={productType.id}
              >
                <ListItem>
                  <div>
                    <ListItemText primary={productType.name} />
                    <ListItemText
                      style={{
                        color: productType.is_active ? "green" : "red",
                      }}
                      primary={productType.is_active ? "Active" : "Inactive"}
                    />
                  </div>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDialogOpen(productType)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </List>
        <div style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleDialogOpen()}
          >
            Add Product Type
          </Button>
        </div>

        <Dialog
          maxWidth="sm"
          fullWidth
          open={dialogOpen}
          onClose={handleDialogClose}
        >
          <DialogTitle>
            {selectedProductType ? "Edit Product Type" : "Add Product Type"}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    style={{ marginTop: 20 }}
                    label="Name"
                    name="name"
                    // variant="contained"
                    // color="primary"
                    fullWidth
                    value={formValues.name}
                    onChange={handleFormValues}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    fullWidth
                    labelId="toggle-label"
                    id="toggle-select"
                    name="is_active"
                    value={formValues.is_active ? "active" : "inactive"}
                    onChange={handleFormValues}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Variables</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography id="continuous-slider" gutterBottom>
                    Maximum OGR %
                  </Typography>
                  <Slider
                    name="max_ogr"
                    value={formValues.max_ogr * 100}
                    aria-label="max_ogr"
                    onChange={handleSliders}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    step={0.5}
                    marks={sliderMarks}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="continuous-slider" gutterBottom>
                    Weighting Impact
                  </Typography>
                  <Slider
                    name="weight_impact"
                    value={formValues.weight_impact * 100}
                    aria-label="weight_impact"
                    onChange={handleSliders}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    step={1}
                    marks={sliderMarks}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography id="continuous-slider" gutterBottom>
                    Dealer Portion
                  </Typography>
                  <Slider
                    name="dealer_cut"
                    value={formValues.dealer_cut * 100}
                    aria-label="dealer_cut"
                    onChange={handleSliders}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    step={1}
                    marks={sliderMarks}
                  />
                </Grid>
                <Grid item container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Agent Cut</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="continuous-slider" gutterBottom>
                      Agent Tier 1 Split
                    </Typography>
                    <Slider
                      name="agent_split_t1"
                      value={formValues.agent_split_t1 * 100}
                      aria-label="agent_split_t1"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="continuous-slider" gutterBottom>
                      Agent Tier 2 Split
                    </Typography>
                    <Slider
                      name="agent_split_t2"
                      value={formValues.agent_split_t2 * 100}
                      aria-label="agent_split_t2"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="continuous-slider" gutterBottom>
                      Agent Tier 3 Split
                    </Typography>
                    <Slider
                      name="agent_split_t3"
                      value={formValues.agent_split_t3 * 100}
                      aria-label="agent_split_t3"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography id="continuous-slider" gutterBottom>
                      Agent Tier 4 Split
                    </Typography>
                    <Slider
                      name="agent_split_t4"
                      value={formValues.agent_split_t4 * 100}
                      aria-label="agent_split_t4"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Dealer Split</Typography>
                    <Typography variant="subtitle2">
                      {`Utilisation - ${Math.round(utilisation * 100)}%`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography id="continuous-slider" gutterBottom>
                      Team Leader Tier 1
                    </Typography>
                    <Slider
                      name="dealer_split_tl_t1"
                      value={formValues.dealer_split_tl_t1 * 100}
                      aria-label="dealer_split_tl_t1"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography id="continuous-slider" gutterBottom>
                      Team Leader Tier 2
                    </Typography>
                    <Slider
                      name="dealer_split_tl_t2"
                      value={formValues.dealer_split_tl_t2 * 100}
                      aria-label="dealer_split_tl_t2"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography id="continuous-slider" gutterBottom>
                      Sales Manager
                    </Typography>
                    <Slider
                      name="dealer_split_sm"
                      value={formValues.dealer_split_sm * 100}
                      aria-label="dealer_split_sm"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography id="continuous-slider" gutterBottom>
                      Dealer
                    </Typography>
                    <Slider
                      name="dealer_split_d"
                      value={formValues.dealer_split_d * 100}
                      aria-label="dealer_split_d"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography id="continuous-slider" gutterBottom>
                      Super Dealer
                    </Typography>
                    <Slider
                      name="dealer_split_sd"
                      value={formValues.dealer_split_sd * 100}
                      aria-label="dealer_split_sd"
                      onChange={handleSliders}
                      aria-labelledby="continuous-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      marks={sliderMarks}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Button
                style={{ marginTop: 20 }}
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mt: 2 }}
              >
                {saving ? "Loading..." : "Save"}
              </Button>
            </form>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert onClose={handleSnackbarClose} severity="error">
                Dealer Split Utilisation is more than 100%
              </Alert>
            </Snackbar>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default ProductTypes;
