import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { useAuth } from "../../../../contexts/AuthContext";
import PeriodSelector from "../../../global/PeriodSelector";
import AgentReport from "./shared/agent-report.shared";
import { supabase } from "../../../../supabase";

export default function FibrePeriodicOrderReport({
  supplierPrettyName,
  impersonation,
  impersonationName,
}) {
  // Retrieve the current user from the authentication context
  const { currentUser } = useAuth();

  // Get supplierId from params
  const { id: supplierId } = useParams();

  // Set the initial dealer code to the current user's dealer code
  const [dealerCode, setDealerCode] = useState(currentUser.dealer_code);

  // Set the initial parameters, loading state, data, and customer modal state
  const [showLoading, setShowloading] = useState(true);
  const [data, setData] = useState([]);

  // Set the initial start and end dates to the current month
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  /**
   * Fetches the data from the server.
   * @returns {Promise<void>} - A promise that resolves when the data is fetched.
   */
  const getData = async () => {
    setShowloading(true);
    // Call the server API to get the agent report

    const { data: orderData } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("fibre_order_master")
      .select(
        // eslint-disable-next-line max-len
        "order_number_1, order_paid_date_1, order_installation_date_1, order_activation_date_2, zawadi_agent_code_2, customer_onboarding_status_0, customer_current_status_0, customer_details_account_number_1, customer_details_name_0, customer_details_phone_0, customer_details_email_0, customer_details_address_0, order_created_date_1"
      )
      .eq("zawadi_agent_code_2", dealerCode)
      .eq("supplier_1", supplierId)
      .gte("order_created_date_1", startDate)
      .lte("order_created_date_1", endDate)
      .is("order_paid_date_1", null)
      .order("order_created_date_1", { ascending: true });
    // Set the data and hide the loading indicator
    setData(orderData);
    setShowloading(false);
  };

  // Fetch data when the dealer code, start date, or end date changes
  useEffect(() => {
    if (dealerCode && startDate && endDate && supplierId) {
      getData();
    }
  }, [dealerCode, startDate, endDate, supplierId]);

  // Update the dealer code when the impersonation changes or when the component mounts
  useEffect(() => {
    if (impersonation) {
      setDealerCode(impersonation);
    } else {
      setDealerCode(currentUser.dealer_code);
    }
  }, [impersonation]);

  return (
    <div>
      {/* Display the dealer code and impersonation name */}
      <Typography variant="h6">
        Unpaid Order Report for{" "}
        {impersonationName ? `: ${impersonationName}` : `: ${dealerCode} (You)`}
      </Typography>

      {/* Display the supplier name */}
      <Typography variant="h6">Service: {supplierPrettyName}</Typography>

      <div>
        {/* Display the current period */}
        <p>
          Current Period:{" "}
          <b>
            {startDate} - {endDate}
          </b>
        </p>
      </div>

      {/* Render the period selector */}
      <PeriodSelector
        changeStartDate={date => setStartDate(date)}
        changeEndDate={date => setEndDate(date)}
      />
      <AgentReport data={data} loading={showLoading} />
    </div>
  );
}
