import { Grid, Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import nanoId from "nano-id";

import { supabase } from "../../../supabase";
import { useCompany } from "../../../contexts/CompanyContext";

import SBFileUploader from "../../shared/SBFileUploader";
import ModalCard from "../../shared/ModalCard";

import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";

function ViewLead({ leadId }) {
  const { suppliers } = useCompany();

  const [lead, setLead] = useState({});
  const [supDocs, setSupDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadId, setUploadId] = useState(nanoId());

  const [uploadFilesModal, setUploadFilesModal] = useState(false);

  const toggleUploadFilesModal = async () => {
    if (!lead.supportingDocumentRef && uploadFilesModal) {
      await fetchLead();
    }
    setUploadFilesModal(old => !old);
  };

  const uploadBucket = "sales_assist";

  const supplier = suppliers?.filter(sup => sup.id === lead.supplier)[0];

  const fetchLead = async () => {
    setLoading(true);

    const { data: salesAssistData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .select("*")
      .eq("id", leadId);

    if (error) console.error(error.message);

    setLead(await salesAssistData[0]);

    setLoading(false);
  };

  const fetchUploadedFiles = async () => {
    if (lead.supporting_document_ref) {
      const { data: files, error } = await supabase.storage
        .from(uploadBucket)
        .list(lead.supporting_document_ref);

      if (error) {
        throw error;
      }

      if (!files || !files.length) {
        setSupDocs([]);
      } else {
        setSupDocs(files);
      }
    }
  };

  const withUploadSubmit = async () => {
    if (!lead.supporting_document_ref) {
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("sales_assist")
        .update({ supporting_document_ref: uploadId })
        .eq("id", leadId)
        .select();

      if (error) console.error(error.message);
    }
    fetchUploadedFiles();
  };

  const viewFiles = supDocs?.map(file => {
    return (
      <Grid item xs={12} key={file.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{file.name}</code>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  useEffect(() => {
    fetchLead();
  }, []);

  useEffect(() => {
    if (lead.supporting_document_ref) {
      setUploadId(lead.supporting_document_ref);
      fetchUploadedFiles();
    }
  }, [lead]);

  return (
    <Grid container spacing={4} direction="row">
      {loading ? (
        <Grid item>
          <Typography>Loading...</Typography>
        </Grid>
      ) : (
        <>
          <Grid
            container
            item
            direction="column"
            md={lead.history ? 4 : 8}
            xs={12}
            spacing={1}
          >
            {lead.date_submitted && (
              <Grid item>
                <Typography variant="subtitle2">Date Submitted:</Typography>
                <Typography variant="body2">
                  {sqlToStandardDate(lead.date_submitted)}
                </Typography>
              </Grid>
            )}
            {lead.dealer_code && (
              <Grid item>
                <Typography variant="subtitle2">Dealer Code:</Typography>
                <Typography variant="body2">{lead.dealer_code}</Typography>
              </Grid>
            )}
            {lead.ref_number && (
              <Grid item>
                <Typography variant="subtitle2">Reference Number: </Typography>
                <Typography variant="body2">{lead.ref_number}</Typography>
              </Grid>
            )}
            {lead.customer_name && (
              <Grid item>
                <Typography variant="subtitle2">Customer: </Typography>
                <Typography variant="body2">{lead.customer_name}</Typography>
              </Grid>
            )}
            {lead.phone_number && (
              <Grid item>
                <Typography variant="subtitle2">Phone:</Typography>
                <Typography variant="body2">{lead.phone_number}</Typography>
              </Grid>
            )}
            {lead.alternative_number && (
              <Grid item>
                <Typography variant="subtitle2">Alternative Number:</Typography>
                <Typography variant="body2">
                  {lead.alternative_number}
                </Typography>
              </Grid>
            )}
            {lead.address && (
              <Grid item>
                <Typography variant="subtitle2">Address:</Typography>
                <Typography variant="body2">{lead.address}</Typography>
              </Grid>
            )}
            {lead.email_address && (
              <Grid item>
                <Typography variant="subtitle2">Email:</Typography>
                <Typography variant="body2">{lead.email_address}</Typography>
              </Grid>
            )}
            {lead.id_number && (
              <Grid item>
                <Typography variant="subtitle2">ID Number:</Typography>
                <Typography variant="body2">{lead.id_number}</Typography>
              </Grid>
            )}
            {lead.note && (
              <Grid item>
                <Typography variant="subtitle2">Feedback:</Typography>
                <Typography variant="body2" paragraph>
                  {lead.note}
                </Typography>
              </Grid>
            )}
            {lead.category && (
              <Grid item>
                <Typography variant="subtitle2">Category:</Typography>
                <Typography variant="body2">{lead.category}</Typography>
              </Grid>
            )}
            {lead.status && (
              <Grid item>
                <Typography variant="subtitle2">Lead Status:</Typography>
                <Typography variant="body2">{lead.status}</Typography>
              </Grid>
            )}
            {lead.payment_type && (
              <Grid item>
                <Typography variant="subtitle2">Lead Payment Type:</Typography>
                <Typography variant="body2">{lead.payment_type}</Typography>
              </Grid>
            )}
            {lead.supplier && (
              <Grid item>
                <Typography variant="subtitle2">Supplier:</Typography>
                <Typography variant="body2">{supplier.supplier}</Typography>
              </Grid>
            )}
            {lead.platform && (
              <Grid item>
                <Typography variant="subtitle2">Platform:</Typography>
                <Typography variant="body2">{lead.platform}</Typography>
              </Grid>
            )}
            {lead.agent_note && (
              <Grid item>
                <Typography variant="subtitle2">Agent Note:</Typography>
                <Typography variant="body2">{lead.agent_note}</Typography>
              </Grid>
            )}
          </Grid>
          {lead.history && (
            <Grid container item direction="column" md={4} xs={12} spacing={2}>
              <Grid container item>
                <Typography variant="subtitle1" gutterBottom>
                  History:
                </Typography>
                <Grid container item>
                  {lead.history.map(entry => (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          {`${entry.updated_at}: ${entry.updated_by_code_name}`}
                        </Typography>
                      </Grid>
                      {entry.update_address && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {`Address: ${entry.update_address}`}
                          </Typography>
                        </Grid>
                      )}
                      {entry.update_status && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {`Status: ${entry.update_status}`}
                          </Typography>
                        </Grid>
                      )}
                      {entry.update_in_progress_status && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {`In Progress Status: ${entry.update_in_progress_status}`}
                          </Typography>
                        </Grid>
                      )}
                      {entry.update_closed_status && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {`Closed Status: ${entry.update_closed_status}`}
                          </Typography>
                        </Grid>
                      )}
                      {entry.update_feedback && (
                        <Grid item xs={12}>
                          <Typography paragraph variant="body2">
                            {`Feedback: ${entry.update_feedback}`}
                          </Typography>
                        </Grid>
                      )}
                      <Typography gutterBottom variant="body2">
                        ---
                      </Typography>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container item direction="column" md={4} xs={12} spacing={2}>
            <Grid container item>
              <Typography variant="subtitle1" gutterBottom>
                Supporting Documents:
              </Typography>
              <Grid container item>
                {lead.supporting_document_ref ? (
                  <Grid item container direction="rows">
                    {viewFiles}
                  </Grid>
                ) : (
                  <Grid>
                    <Typography variant="caption">No Files Uploaded</Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={toggleUploadFilesModal}
                >
                  Add Supporting Documents
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ModalCard open={uploadFilesModal} onClose={toggleUploadFilesModal}>
            <SBFileUploader
              bucketName={uploadBucket}
              folderName={uploadId}
              onClose={toggleUploadFilesModal}
              withSubmit={withUploadSubmit}
            />
          </ModalCard>
        </>
      )}
    </Grid>
  );
}

export default ViewLead;
