import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import DesktopUserCard from "./DesktopUserCard";
import Top3UserCard from "./Top3UserCard";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#F0BD44",
  },
  card1: {
    backgroundColor: "#267353",
    color: "#FFFFFF",
  },

  buttonSize: {
    width: 120,
  },
  buttonSize2: {
    width: 120,
  },
});

export default function StructureDesk({ Leaders }) {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [bigCardSize, setBigCardSize] = useState(12);
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (Leaders === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
      setData(Leaders.sort((a, b) => b.sales - a.sales));
    }
    if (Leaders.length === 1) {
      setBigCardSize(12);
    } else if (Leaders.length === 2) {
      setBigCardSize(6);
    } else if (Leaders.length > 2) {
      setBigCardSize(4);
    }
  }, [Leaders]);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {loading ? (
        <h1>loading...</h1>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.card1}>
              <CardContent>
                {data[0] ? (
                  <Grid container spacing={3}>
                    <Grid item xs={bigCardSize}>
                      <Top3UserCard
                        name={`${
                          `${data[0]?.dealers?.contact_name} ${data[0]?.dealers?.contact_surname}` ||
                          `${!loading ? "No Data" : "Loading..."}`
                        }`}
                        avatar="1"
                        percentage={`${data[0]?.p || "0"}`}
                        fiber={`${data[0]?.sales || "0"}`}
                        rank="https://www.svgrepo.com/show/404692/1st-place-medal.svg"
                        colored="#FFD700"
                      />
                    </Grid>
                    {data[1] ? (
                      <Grid item xs={bigCardSize}>
                        <Top3UserCard
                          name={`${
                            `${data[1]?.dealers?.contact_name} ${data[1]?.dealers?.contact_surname}` ||
                            `${!loading ? "No Data" : "Loading..."}`
                          }`}
                          avatar="1"
                          percentage={`${data[1]?.p || "0"}`}
                          fiber={`${data[1]?.sales || "0"}`}
                          rank="https://www.svgrepo.com/show/404693/2nd-place-medal.svg"
                          colored="#C0C0C0"
                        />
                      </Grid>
                    ) : null}{" "}
                    {data[2] ? (
                      <Grid item xs={bigCardSize}>
                        <Top3UserCard
                          name={`${
                            `${data[2]?.dealers?.contact_name} ${data[2]?.dealers?.contact_surname}` ||
                            `${!loading ? "No Data" : "Loading..."}`
                          }`}
                          avatar="1"
                          percentage={`${data[2]?.p || "0"}`}
                          fiber={`${data[2]?.sales || "0"}`}
                          rank="https://www.svgrepo.com/show/404695/3rd-place-medal.svg"
                          colored="#CD7F32"
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : (
                  <Typography align="center" variant="h5">
                    {" "}
                    "Kindly revisit later as there are currently no sales
                    recorded in your structure at the moment."
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {data.slice(3, 10).map((Leader, index) => {
                    return (
                      <Grid item xs={12}>
                        <DesktopUserCard
                          rank={index + 3}
                          avatar="https://mui.com//static/images/avatar/4.jpg"
                          name={`${Leader.dealers.contact_name} ${Leader.dealers.contact_surname}`}
                          percentage={Leader.p}
                          fiber={Leader.sales}
                        />
                      </Grid>
                    );
                  })}{" "}
                  {data.length > 10 ? (
                    <Button fullWidth onClick={() => handleExpand()}>
                      <Grid item xs={12}>
                        <Card style={{ padding: 15 }}>
                          <Typography
                            align="center"
                            variant="h3"
                            color="primary"
                          >
                            Expand
                            {!expanded ? (
                              <KeyboardArrowDown
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            ) : (
                              <KeyboardArrowUp
                                fontSize="inherit"
                                style={{ marginBottom: -7.5 }}
                              />
                            )}
                          </Typography>
                        </Card>
                      </Grid>
                    </Button>
                  ) : (
                    ""
                  )}
                  {expanded
                    ? data.slice(10, 100).map((Leader, index) => {
                        return (
                          <Grid item xs={12}>
                            <DesktopUserCard
                              rank={index + 10}
                              avatar="https://mui.com//static/images/avatar/4.jpg"
                              name={`${data[1]?.dealers?.contact_name} ${data[1]?.dealers?.contact_surname}`}
                              percentage={Leader.p}
                              fiber={Leader.sales}
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
