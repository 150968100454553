/* eslint-disable import/order */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import { Switch, Link, useHistory } from "react-router-dom";
import { makeStyles, useTheme, darken } from "@material-ui/core/styles";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Menu,
  Card,
  DialogContentText,
} from "@material-ui/core/";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faIdCard,
  faSignOutAlt,
  faEnvelope,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, Divider, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DealerNotifications from "./dealercomps/notifications/DealerNotifications";
import topBg from "../img/zawadi-top2.jpg";
import sideBg from "../img/zawadi-sidebar.jpg";
import logo from "../img/newZims3.png";
import { useSB } from "../contexts/SupabaseContext";
import DealerRoutes from "./routes/DealerRoutes";
import { useAuth } from "../contexts/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  userDetails: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    padding: "1rem 1.25rem",
    backgroundColor: theme.palette.primary.main,
  },
  detailsAvatar: {
    display: "flex",
    width: "2rem",
    height: "2rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    backgroundColor: darken(theme.palette.primary.main, 0.2),
  },
  icons: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: "#fff",
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": { borderWidth: 0 },
  },
  drawerPaper: {
    width: drawerWidth,
    background: `url(${sideBg})`,
    backgroundSize: "cover",
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  list: {
    backgroundColor: "rgba(39, 115, 82, .9)",
  },
  hoverColor: {
    "&:hover": {
      background: "rgba(255, 255, 255, .05)",
    },
  },
}));

export default function DealerMenu() {
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { logout, currentUser } = useAuth();
  const history = useHistory();
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState(false);
  const [acknowledgingAlert, setAcknowledgingAlert] = useState(false);
  const [unseenAlerts, setUnseenAlerts] = useState([]);
  const [allAlerts, setAllAlerts] = useState([]);
  const [showActiveAlert, setShowActiveAlert] = useState(false);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const { GetTableWhere, updateRow } = useSB();
  const [currentUserTitle, setCurrentUserTitle] = useState("");
  const [currentUserRank, setCurrentUserRank] = useState("");
  const [showTrainingAlert, setShowTrainingAlert] = useState(false);

  async function GetAllAlerts() {
    return await GetTableWhere(
      "global_alerts",
      ["uid", "eq", currentUser.id],
      ["active", "eq", true]
    )
      .then(doc => {
        // const alertArray = [];

        // get unique list items based on alertId
        // Create a Map to store unique items based on alertId
        const uniqueItemsMap = new Map();

        // Iterate through the jsonData array
        doc.forEach(item => {
          // Use the alertId as the key in the Map to store only one item per alertId
          uniqueItemsMap.set(item.new_alert_id, item);
        });

        // Convert the Map values back to an array
        const uniqueItemsArray = Array.from(uniqueItemsMap.values());

        return uniqueItemsArray;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err, "getAllAlerts", {});
      });
  }

  useEffect(() => {
    const rankNames = [
      "Admin",
      "Super Dealer",
      "Dealer",
      "Sales Manager",
      "Tier 2 Team Leader",
      "Tier 1 Team Leader",
      "Assistant Team Leader",
      "Agent",
    ];
    if (currentUser) {
      const userRank = currentUser.rank;
      setCurrentUserTitle(
        `${currentUser.contact_name} ${currentUser.contact_surname}`
      );
      setCurrentUserRank(
        userRank && rankNames[userRank - 1] ? rankNames[userRank - 1] : ""
      );
    }
  }, [currentUser]);

  const drawerItems = [
    { title: "Dashboard", path: "/", icon: faTachometerAlt },
    { title: "Your Team", path: "/manage-dealers", icon: faIdCard },
    { title: "Zawadi University", path: "/training", icon: faGraduationCap },
    { title: "Alerts", path: "/alerts", icon: faEnvelope },
  ];

  const agentDrawerItems = [
    { title: "Dashboard", path: "/", icon: faTachometerAlt },
    { title: "Zawadi University", path: "/training", icon: faGraduationCap },
  ];

  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogOut = async () => {
    await logout();
    history.push("/login");
  };

  /*
   * Function to determine if a training module is relevant based on the user's rank,
   * selected product types, and selected suppliers.
   */
  const isModuleRelevant = (
    { selected_levels, product_types, suppliers },
    rank,
    selected_product_types,
    selected_suppliers
  ) => {
    const isLevelRelevant =
      !selected_levels ||
      selected_levels.length === 0 ||
      selected_levels.includes(rank);

    const isProductTypeRelevant =
      !product_types ||
      product_types.length === 0 ||
      selected_product_types.some(type => product_types.includes(type));

    const isSupplierRelevant =
      !suppliers ||
      suppliers.length === 0 ||
      selected_suppliers.some(supplier => suppliers.includes(supplier));

    return isLevelRelevant && isProductTypeRelevant && isSupplierRelevant;
  };

  /**
   * Checks if the current user needs training by evaluating relevant training modules
   * and their passed attempts.
   */
  const checkForTraining = async () => {
    const { rank, selected_product_types, selected_suppliers } = currentUser;

    const selectedProductTypes = selected_product_types;
    const selectedSuppliers = selected_suppliers;

    // const modules = await GetWholeTable("training_modules", "id");

    const modules = await GetTableWhere("training_modules", [
      "active",
      "eq",
      true,
    ]);

    const moduleArray = [];

    modules?.forEach(module => {
      if (
        isModuleRelevant(module, rank, selectedProductTypes, selectedSuppliers)
      ) {
        moduleArray.push(module);
      }
    });

    /**
     * Checks if the user has passed the given module.
     */

    const checkPassedModules = async module => {
      const attemptList = await GetTableWhere(
        "training_attempts",
        ["module_id", "eq", module.id],
        ["dealer_id", "eq", currentUser.id]
      );

      const passed = attemptList.filter(
        attempt => attempt.pass === true
      ).length;
      return { ...module, passed, attemptList };
    };

    const modulesWithAttempts = await Promise.all(
      moduleArray.map(checkPassedModules)
    );

    const needsTraining = modulesWithAttempts.some(module => {
      return module.passed < 1 && module.active;
    });

    return { needsTraining };
  };

  /**
   * Effect hook to check if the user needs training and update the training alert state.
   */
  useEffect(() => {
    checkForTraining().then(result => {
      if (result.needsTraining) {
        setShowTrainingAlert(true);
      } else {
        setShowTrainingAlert(false);
      }
    });
  }, []);

  // Training Module Check

  const getAlertsFunction = () => {
    setLoadingAlerts(true);
    GetAllAlerts(currentUser.id)
      .then(res => {
        const unseenAlertArray = [];
        const allAlertArray = [];

        if (res.length === 0) {
          setShowAlertsModal(false);
          setLoadingAlerts(false);
        }

        res.forEach((item, index) => {
          allAlertArray.push(item);
          if (item.read === false) {
            unseenAlertArray.push(item);
          }
          if (index === res.length - 1) {
            setUnseenAlerts(unseenAlertArray);
            if (unseenAlertArray.length > 0) {
              setShowAlertsModal(true);
            } else {
              setShowAlertsModal(false);
            }
            setLoadingAlerts(false);
            setAllAlerts(allAlertArray);
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAlertsFunction();
  }, []);

  const showAlertFunction = alert => {
    setActiveAlert(alert);
    setShowActiveAlert(true);
  };

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showTrainingAlert}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not completed all the training modules. Please complete all
            the training modules before you can continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowTrainingAlert(false);
              history.push("/training");
            }}
            color="primary"
          >
            Do training
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showAlertsModal}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Your Alerts</DialogTitle>
        <DialogContent>
          {loadingAlerts ||
            (unseenAlerts.length === 0 && (
              <div>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Loading...
                </Typography>
              </div>
            ))}
          {unseenAlerts.map(alert => {
            const maxLength = 100; // Maximum number of characters for the description
            const truncatedDescription =
              alert.description.length > maxLength
                ? `${alert.description.substring(0, maxLength)}...`
                : alert.description;

            return (
              <Card
                key={alert.id}
                style={{
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{ padding: 15, marginLeft: 10, fontWeight: "bold" }}
                >
                  {alert.title}
                </div>
                <div
                  style={{ padding: 15, marginLeft: 10 }}
                  dangerouslySetInnerHTML={{ __html: truncatedDescription }}
                />
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{ margin: 15 }}
                    onClick={() => {
                      showAlertFunction(alert);
                    }}
                    color="primary"
                  >
                    View
                  </Button>
                </div>
              </Card>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAlertsModal(false);
            }}
            color="primary"
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showActiveAlert}
        onClose={() => {
          // No close
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{activeAlert.title}</DialogTitle>
        {activeAlert.images && activeAlert.images.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            {activeAlert.images.map(image => (
              <img
                src={JSON.parse(image).url}
                alt={JSON.parse(image).name}
                style={{ width: "auto", maxHeight: 250 }}
              />
            ))}
          </div>
        )}
        <DialogContent>
          <div
            style={{ padding: 15, marginLeft: 10 }}
            dangerouslySetInnerHTML={{ __html: activeAlert.description }}
          />
        </DialogContent>
        <Divider />
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setShowActiveAlert(false);
              setAcknowledgingAlert(true);
              updateRow("global_alerts", activeAlert.id, {
                read: true,
                seen_time: new Date(),
              }).then(() => {
                setAcknowledgingAlert(false);
                setShowActiveAlert(false);
                getAlertsFunction();
              });
            }}
            color="primary"
          >
            {acknowledgingAlert ? "Loading..." : "Got It"}
          </Button>
        </DialogActions>
      </Dialog>
      <Helmet>
        <title>Zawadi</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={classes.appBar}
        style={{
          background: `url(${topBg})`,
          backgroundColor: "#fff",
          backgroundSize: "cover",
        }}
      >
        <Toolbar>
          <IconButton
            style={{
              color: "#fff",
            }}
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} width={130} alt="Logo" />
          <Typography
            variant="h6"
            noWrap
            style={{ flex: 1 }}
            className={classes.menuText}
          />
          <Menu
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            keepMounted
            onClose={() => setNotifAnchorEl(null)}
          >
            <DealerNotifications
              showAlertFunction={showAlertFunction}
              allAlerts={allAlerts}
              closeMenu={() => setNotifAnchorEl(null)}
            />
          </Menu>
          <div
            style={{
              padding: 5,
              marginRight: 25,
              cursor: "pointer",
              background: "#fff",
              borderRadius: 50,
              boxShadow: "0px 0px 12px black",
            }}
          >
            <IconButton onClick={e => setNotifAnchorEl(e.currentTarget)}>
              <Badge badgeContent={unseenAlerts.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ width: "100%", padding: "10px 10px 0 0" }} />
          <IconButton style={{ color: "#fff" }} onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Box className={classes.userDetails}>
          <Box className={classes.detailsAvatar}>
            {currentUserTitle && currentUserTitle[0]}
          </Box>
          <Box
            onClick={() => {
              const text = `${
                currentUser.dealer_code ? currentUser.dealer_code : null
              }: ${currentUserTitle}`;

              const input = document.createElement("textarea");
              input.innerHTML = text;
              document.body.appendChild(input);
              input.select();
              const result = document.execCommand("copy");
              document.body.removeChild(input);

              // eslint-disable-next-line no-alert
              alert("Name copied.");
              return result;
            }}
            style={{ paddingLeft: "1.25rem", cursor: "pointer" }}
          >
            <Typography variant="subtitle2">
              {currentUser.dealer_code ? `${currentUser.dealer_code}: ` : null}
              {currentUserTitle}
            </Typography>
            <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
              {currentUserRank}
            </Typography>
            <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
              Click to Copy
            </Typography>
          </Box>
        </Box>

        <List className={classes.list}>
          {currentUser.rank === 8
            ? agentDrawerItems &&
              agentDrawerItems.map(item => (
                <Link
                  to={item.path}
                  className="drawer-item"
                  key={`${item.title}`}
                  onClick={handleDrawerClose}
                >
                  <ListItem className={classes.hoverColor}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className={classes.icons}
                        icon={item.icon}
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                      {item.title}
                    </ListItemText>
                  </ListItem>
                </Link>
              ))
            : drawerItems &&
              drawerItems.map(item => (
                <Link
                  to={item.path}
                  className="drawer-item"
                  key={`${item.title}`}
                  onClick={handleDrawerClose}
                >
                  <ListItem className={classes.hoverColor}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className={classes.icons}
                        icon={item.icon}
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                      {item.title}
                    </ListItemText>
                  </ListItem>
                </Link>
              ))}

          <ListItem
            onClick={handleLogOut}
            style={{ cursor: "pointer" }}
            className={classes.hoverColor}
          >
            <ListItemIcon>
              <FontAwesomeIcon className={classes.icons} icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText className={classes.menuText}>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <DealerRoutes />
        </Switch>
      </main>
    </div>
  );
}
