// #useAuthUpdated

import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";

import { supabase } from "../../../../../supabase";

import { useAuth } from "../../../../../contexts/AuthContext";

import { SupplierLinkTile } from "./SupplierLinkTile";
import { AddSupplierLinkButton } from "./AddSupplierLinkButton";

function SupplierLinks({ supplierId }) {
  // States
  const [linksArr, setLinksArr] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get currentUser Context
  const { currentUser } = useAuth();

  // Check to see if user is an admin

  const userAdmin = currentUser.rank === 1;

  // Rest options for link - Add to restVar object when needed

  const restVar = {
    DEALER_CODE: currentUser.dealer_code,
    DEALER_NAME: currentUser.dealer_name,
    ZAWADI_DEALER_CODE: "zawadi_".concat(currentUser.dealer_code),
  };

  // SUPABASE

  // Get Supplier links from supabase

  const getsupplierLinks = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("supplier_links")
      .select("*")
      .eq("sb_supplier_id", supplierId)
      .eq("is_active", true);

    if (error) console.error(error);
    else {
      setLinksArr(data);
      setLoading(false);
    }
  };

  // Fetch supplier Links on render

  useEffect(() => {
    getsupplierLinks();
  }, [supplierId]);

  // Add Supplier Link - Send to AddSupplierLinkModal

  const addSupplierLink = async newLink => {
    setLoading(true);

    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("supplier_links")
      .upsert({
        ...newLink,
        supplier_id: supplierId,
        created_by: currentUser.dealer_code,
        sb_supplier_id: supplierId,
      });

    if (error) console.error(error);

    getsupplierLinks();
  };

  const removeSupplierLink = async linkId => {
    setLoading(true);

    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("supplier_links")
      .update({ is_active: false })
      .eq("id", linkId);

    if (error) console.error(error);

    getsupplierLinks();
  };

  // Mapping over the links received from Supabase

  const linkMap = linksArr.map(supLink => (
    <SupplierLinkTile
      supLink={supLink}
      userAdmin={userAdmin}
      restVar={restVar}
      removeSupplierLink={removeSupplierLink}
      addSupplierLink={addSupplierLink}
    />
  ));

  return (
    <Container maxWidth="xl">
      <Grid container xs={12} spacing={1}>
        {loading ? (
          <Typography variant="h5">Loading...</Typography>
        ) : (
          <>
            <Grid
              container
              item
              spacing={3}
              xs={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={11} md={11}>
                {!linkMap[0] && <Typography>No links for supplier</Typography>}
              </Grid>
              {userAdmin && (
                <AddSupplierLinkButton
                  restVar={restVar}
                  addSupplierLink={addSupplierLink}
                />
              )}
            </Grid>
            {linkMap}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default SupplierLinks;
